import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'vail-co',
    predictionPlace: {
      address: 'Vail, Colorado',
      bounds: new LatLngBounds(
        new LatLng(39.66095179, -106.33749993),
        new LatLng(39.61111627, -106.39134354)
      ),
    },
    search:
      'address=Vail%2C%20Colorado&bounds=39.66095179%2C-106.33749993%2C39.61111627%2C-106.39134354',
  },
  {
    id: 'beaver-co',
    predictionPlace: {
      address: 'Beaver Creek, Colorado',
      bounds: new LatLngBounds(
        new LatLng(39.6217415, -106.49661389),
        new LatLng(39.56722909, -106.54325733)
      ),
    },
    search:
      'address=Beaver%20Creek%2C%20Colorado&bounds=39.6217415%2C-106.49661389%2C39.56722909%2C-106.54325733',
  },
  {
    id: 'breckenridge-co',
    predictionPlace: {
      address: 'Breckenridge, Colorado',
      bounds: new LatLngBounds(
        new LatLng(39.50886427, -106.02268441),
        new LatLng(39.45975389, -106.07562859)
      ),
    },
    search:
      'address=Breckenridge%2C%20Colorado&bounds=39.50886427%2C-106.02268441%2C39.45975389%2C-106.07562859',
  },
  {
    id: 'copper-co',
    predictionPlace: {
      address: 'Copper Mountain, Colorado',
      bounds: new LatLngBounds(
        new LatLng(39.60192625, -106.07013557),
        new LatLng(39.42298634, -106.22306437)
      ),
    },
    search:
      'address=Copper%20Mountain%2C%20Colorado&bounds=39.60192625%2C-106.07013557%2C39.42298634%2C-106.22306437',
  },
  {
    id: 'afton-alps-mn',
    predictionPlace: {
      address: 'Afton Alps, Minnesota',
      bounds: new LatLngBounds(
        new LatLng(44.89775534, -92.75459181),
        new LatLng(44.81344242, -92.82367741)
      ),
    },
    search:
      'address=Afton%20Alps%2C%20Minnesota&bounds=44.89775534%2C-92.75459181%2C44.81344242%2C-92.82367741',
  },
  {
    id: 'alta',
    predictionPlace: {
      address: 'Alta, Utah',
      bounds: new LatLngBounds(
        new LatLng(40.59639222, -111.63436791),
        new LatLng(40.57970603, -111.64471367)
      ),
    },
    search:
      'address=Alta%2C%20Utah&bounds=40.59639222%2C-111.63436791%2C40.57970603%2C-111.64471367',
  },
  {
    id: 'aspen',
    predictionPlace: {
      address: 'Aspen Mountain, Colorado',
      bounds: new LatLngBounds(
        new LatLng(39.24645593, -106.78247872),
        new LatLng(39.12564684, -106.85500902)
      ),
    },
    search:
      'address=Aspen%20Mountain%2C%20Colorado&bounds=39.24645593%2C-106.78247872%2C39.12564684%2C-106.85500902',
  },
  {
    id: 'Big-Sky',
    predictionPlace: {
      address: 'Big Sky, Montana',
      bounds: new LatLngBounds(
        new LatLng(45.59693593, -111.22935685),
        new LatLng(45.03840047, -111.59898817)
      ),
    },
    search:
      'address=Big%20Sky%2C%20Montana&bounds=45.59693593%2C-111.22935685%2C45.03840047%2C-111.59898817',
  },
  {
    id: 'Brighton',
    predictionPlace: {
      address: 'Brighton, Utah',
      bounds: new LatLngBounds(
        new LatLng(40.68557541, -111.53163588),
        new LatLng(40.51940791, -111.63348145)
      ),
    },
    search:
      'address=Brighton%2C%20Utah&bounds=40.68557541%2C-111.53163588%2C40.51940791%2C-111.63348145',
  },
  {
    id: 'Canyons-Resort',
    predictionPlace: {
      address: 'Canyons Resort, Utah',
      bounds: new LatLngBounds(
        new LatLng(40.70781903, -111.54410693),
        new LatLng(40.66638885, -111.56953205)
      ),
    },
    search:
      'address=Canyons%20Resort%2C%20Utah&bounds=40.70781903%2C-111.54410693%2C40.66638885%2C-111.56953205',
  },
  {
    id: 'CrestedButte',
    predictionPlace: {
      address: 'Crested Butte, Colorado',
      bounds: new LatLngBounds(
        new LatLng(38.91886557, -106.94999012),
        new LatLng(38.81722028, -107.0107411)
      ),
    },
    search:
      'address=Crested%20Butte%2C%20Colorado&bounds=38.91886557%2C-106.94999012%2C38.81722028%2C-107.0107411',
  },
  {
    id: 'Crystal-Mountain',
    predictionPlace: {
      address: 'Crystal Mountain, Washington',
      bounds: new LatLngBounds(
        new LatLng(46.9789277, -121.4467068),
        new LatLng(46.89212605, -121.50586267)
      ),
    },
    search:
      'address=Crystal%20Mountain%2C%20Washington&bounds=46.9789277%2C-121.4467068%2C46.89212605%2C-121.50586267',
  },
  {
    id: 'Deer-Valley',
    predictionPlace: {
      address: 'Deer Valley, Utah',
      bounds: new LatLngBounds(
        new LatLng(40.66946467, -111.46136524),
        new LatLng(40.60578229, -111.50041725)
      ),
    },
    search:
      'address=Deer%20Valley%2C%20Utah&bounds=40.66946467%2C-111.46136524%2C40.60578229%2C-111.50041725',
  },
  {
    id: 'Heavenly',
    predictionPlace: {
      address: 'Heavenly, California',
      bounds: new LatLngBounds(
        new LatLng(38.99560695, -119.90353778),
        new LatLng(38.85830631, -119.98566723)
      ),
    },
    search:
      'address=Heavenly%2C%20California&bounds=38.99560695%2C-119.90353778%2C38.85830631%2C-119.98566723',
  },
  {
    id: 'Jackson-Hole',
    predictionPlace: {
      address: 'Jackson-Hole, Wyoming',
      bounds: new LatLngBounds(
        new LatLng(43.57887012, -110.70069357),
        new LatLng(43.33838115, -110.89313852)
      ),
    },
    search:
      'address=Jackson-Hole%2C%20Wyoming&bounds=43.57887012%2C-110.70069357%2C39.42298634%2C-106.22306437',
  },
  {
    id: 'Keystone',
    predictionPlace: {
      address: 'Keystone, Colorado',
      bounds: new LatLngBounds(
        new LatLng(39.64636702, -105.91520922),
        new LatLng(39.57548539, -105.96865239)
      ),
    },
    search:
      'address=Keystone%2C%20Colorado&bounds=39.64636702%2C-105.91520922%2C39.57548539%2C-105.96865239',
  },
  {
    id: 'Killington',
    predictionPlace: {
      address: 'Killington, Vermont',
      bounds: new LatLngBounds(
        new LatLng(43.66149136, -72.76927728),
        new LatLng(43.58899984, -72.82744713)
      ),
    },
    search:
      'address=Killington%2C%20Vermont&bounds=43.66149136%2C-72.76927728%2C43.58899984%2C-72.82744713',
  },
  {
    id: 'Kirkwood',
    predictionPlace: {
      address: 'Kirkwood, California',
      bounds: new LatLngBounds(
        new LatLng(38.70027143, -120.05574561),
        new LatLng(38.67131432, -120.0772935)
      ),
    },
    search:
      'address=Kirkwood%2C%20California&bounds=38.70027143%2C-120.05574561%2C38.67131432%2C-120.0772935',
  },
  {
    id: 'Mammoth-Mountain',
    predictionPlace: {
      address: 'Mammoth Mountain, California',
      bounds: new LatLngBounds(
        new LatLng(37.74788929, -118.9556012),
        new LatLng(37.52763069, -119.11716352)
      ),
    },
    search:
      'address=Mammoth%20Mountain%2C%20California&bounds=37.74788929%2C-118.9556012%2C37.52763069%2C-119.11716352',
  },
  {
    id: 'Mount-Bachelor',
    predictionPlace: {
      address: 'Mount Bachelor, Oregon',
      bounds: new LatLngBounds(
        new LatLng(44.04418928, -121.62499268),
        new LatLng(43.94858404, -121.70218806)
      ),
    },
    search:
      'address=Mount%20Bachelor%2C%20Oregon&bounds=44.04418928%2C-106.07013557%2C43.94858404%2C-121.70218806',
  },
  {
    id: 'Northstar',
    predictionPlace: {
      address: 'Northstar, California',
      bounds: new LatLngBounds(
        new LatLng(39.28697748, -120.11122992),
        new LatLng(39.26178025, -120.13013648)
      ),
    },
    search:
      'address=Northstar%2C%20California&bounds=39.28697748%2C-120.11122992%2C39.26178025%2C-120.13013648',
  },
  {
    id: 'Okemo',
    predictionPlace: {
      address: 'Okemo, Vermont',
      bounds: new LatLngBounds(
        new LatLng(43.44020893, -72.69220963),
        new LatLng(43.36690309, -72.75081723)
      ),
    },
    search:
      'address=Okemo%2C%20Vermont&bounds=43.44020893%2C-72.69220963%2C43.36690309%2C-72.75081723',
  },
  {
    id: 'Park-City',
    predictionPlace: {
      address: 'Park City, Utah',
      bounds: new LatLngBounds(
        new LatLng(40.66524189, -111.49857377),
        new LatLng(40.64179032, -111.51652908)
      ),
    },
    search:
      'address=Park%20City%2C%20Utah&bounds=40.66524189%2C-111.49857377%2C40.64179032%2C-111.51652908',
  },
  {
    id: 'Purgatory',
    predictionPlace: {
      address: 'Purgatory, Colorado',
      bounds: new LatLngBounds(
        new LatLng(37.83426935, -107.68402929),
        new LatLng(37.45824215, -107.959882)
      ),
    },
    search:
      'address=Purgatory%2C%20Colorado&bounds=37.83426935%2C-107.68402929%2C37.45824215%2C-107.959882',
  },
  {
    id: 'Snowbird',
    predictionPlace: {
      address: 'Snowbird, Utah',
      bounds: new LatLngBounds(
        new LatLng(40.59214984, -111.6484126),
        new LatLng(40.57055088, -111.66493164)
      ),
    },
    search:
      'address=Copper%20Mountain%2C%20Colorado&bounds=40.59214984%2C-111.6484126%2C40.57055088%2C-111.66493164',
  },
  {
    id: 'Snowmass',
    predictionPlace: {
      address: 'Snowmass, Colorado',
      bounds: new LatLngBounds(
        new LatLng(39.22226869, -106.94345614),
        new LatLng(39.19048142, -106.96728439)
      ),
    },
    search:
      'address=Snowmass%2C%20Colorado&bounds=39.22226869%2C-106.94345614%2C39.19048142%2C-106.96728439',
  },
  {
    id: 'Snow-Summit',
    predictionPlace: {
      address: 'Snow Summit, California',
      bounds: new LatLngBounds(
        new LatLng(34.28721603, -116.86146409),
        new LatLng(34.20456633, -116.91954055)
      ),
    },
    search:
      'address=Snow%20Summit%2C%20California&bounds=34.28721603%2C-116.86146409%2C34.20456633%2C-116.91954055',
  },
  {
    id: 'Squaw-Valley',
    predictionPlace: {
      address: 'Squaw Valley, California',
      bounds: new LatLngBounds(
        new LatLng(39.22857768, -120.21313685),
        new LatLng(39.16361128, -120.26182958)
      ),
    },
    search:
      'address=Squaw%20Valley%2C%20California&bounds=39.22857768%2C-120.21313685%2C39.16361128%2C-120.26182958',
  },
  {
    id: 'Steamboat',
    predictionPlace: {
      address: 'Steamboat, Colorado',
      bounds: new LatLngBounds(
        new LatLng(40.48539148, -106.78539553),
        new LatLng(40.43204999, -106.82611692)
      ),
    },
    search:
      'address=Steamboat%2C%20Colorado&bounds=40.48539148%2C-106.78539553%2C40.43204999%2C-106.82611692',
  },
  {
    id: 'Stevens-Pass',
    predictionPlace: {
      address: 'Stevens-Pass, Washington',
      bounds: new LatLngBounds(
        new LatLng(47.77844418, -121.06339511),
        new LatLng(47.71456391, -121.11857762)
      ),
    },
    search:
      'address=Stevens-Pass%2C%20Washington&bounds=47.77844418%2C-121.06339511%2C47.71456391%2C-121.11857762',
  },
  {
    id: 'Stowe',
    predictionPlace: {
      address: 'Stowe, Vermont',
      bounds: new LatLngBounds(
        new LatLng(44.56987884, -72.75651894),
        new LatLng(44.50174164, -72.81204275)
      ),
    },
    search:
      'address=Stowe%2C%20Vermont&bounds=44.56987884%2C-72.75651894%2C44.50174164%2C-72.81204275',
  },
  {
    id: 'Stratton-Mountain',
    predictionPlace: {
      address: 'Stratton Mountain, Vermont',
      bounds: new LatLngBounds(
        new LatLng(43.15442094, -72.87828009),
        new LatLng(43.07344013, -72.94271654)
      ),
    },
    search:
      'address=Stratton%20Mountain%2C%20Vermont&bounds=43.15442094%2C-72.87828009%2C43.07344013%2C-72.94271654',
  },
  {
    id: 'Sugarbush',
    predictionPlace: {
      address: 'Sugarbush, Vermont',
      bounds: new LatLngBounds(
        new LatLng(44.18858735, -72.85638589),
        new LatLng(44.09023518, -72.93599137)
      ),
    },
    search:
      'address=Sugarbush%2C%20Vermont&bounds=44.18858735%2C-72.85638589%2C44.09023518%2C-72.93599137',
  },
  {
    id: 'Sugarloaf',
    predictionPlace: {
      address: 'Sugarloaf, Maine',
      bounds: new LatLngBounds(
        new LatLng(45.10206071, -70.27832472),
        new LatLng(45.0140185, -70.35072104)
      ),
    },
    search:
      'address=Sugarloaf%2C%20Maine&bounds=45.10206071%2C-70.27832472%2C45.0140185%2C-70.35072104',
  },
  {
    id: 'Sun-Valley',
    predictionPlace: {
      address: 'Sun Valley, Idaho',
      bounds: new LatLngBounds(
        new LatLng(43.72899549, -114.3229922),
        new LatLng(43.61642834, -114.41339165)
      ),
    },
    search:
      'address=Sun%20Valley%2C%20Idaho&bounds=43.72899549%2C-114.3229922%2C43.61642834%2C-114.41339165',
  },
  {
    id: 'Taos',
    predictionPlace: {
      address: 'Taos, New Mexico',
      bounds: new LatLngBounds(
        new LatLng(36.63915251, -105.42140565),
        new LatLng(36.55596102, -105.48159484)
      ),
    },
    search:
      'address=Taos%2C%20New%20Mexico&bounds=36.63915251%2C-105.42140565%2C36.55596102%2C-105.48159484',
  },
  {
    id: 'Telluride',
    predictionPlace: {
      address: 'Telluride, Colorado',
      bounds: new LatLngBounds(
        new LatLng(37.97303525, -107.83300056),
        new LatLng(37.91069048, -107.87891973)
      ),
    },
    search:
      'address=Telluride%2C%20Colorado&bounds=37.97303525%2C-107.83300056%2C37.91069048%2C-107.87891973',
  },
  {
    id: 'The-Summit',
    predictionPlace: {
      address: 'The Summit, Washington',
      bounds: new LatLngBounds(
        new LatLng(47.44791959, -121.3825133),
        new LatLng(47.37832977, -121.44224639)
      ),
    },
    search:
      'address=The%20Summit%2C%20Washington&bounds=47.44791959%2C-121.3825133%2C47.37832977%2C-121.44224639',
  },
  {
    id: 'Whitefish',
    predictionPlace: {
      address: 'Whitefish, Montana',
      bounds: new LatLngBounds(
        new LatLng(48.52889531, -114.31150319),
        new LatLng(48.43875899, -114.39049224)
      ),
    },
    search:
      'address=Whitefish%2C%20Montana&bounds=48.52889531%2C-114.31150319%2C48.43875899%2C-114.39049224',
  },
  {
    id: 'Winter-Park',
    predictionPlace: {
      address: 'Winter Park, Colorado',
      bounds: new LatLngBounds(
        new LatLng(39.92382014, -105.73755451),
        new LatLng(39.85262098, -105.7914536)
      ),
    },
    search:
      'address=Winter%20Park%2C%20Colorado&bounds=39.92382014%2C-105.73755451%2C39.85262098%2C-105.7914536',
  },
];
