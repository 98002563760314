import { createSlug } from './urlHelpers';

export const getUniqueNumericRefCode = () => {
  return Date.now().toString();
};

//will use below functions later
export const saveRefCodeToLocalStorage = ({ refCode, refUserId }) => {
  if (typeof window.localStorage === 'undefined') {
    console.error('localStorage is not ready');
    return;
  }

  window.localStorage.setItem('refCode', refCode);
  // window.localStorage.setItem('refUserId', refUserId);
};

export const getRefCodeFromLocalStorage = () => {
  if (typeof window.localStorage === 'undefined') {
    console.error('localStorage is not ready');
    return;
  }

  const refCode = window.localStorage.getItem('refCode');
  const refUserId = window.localStorage.getItem('refUserId');

  return {
    refCode,
    refUserId,
  };
};

export const clearRefCodeFromLocalStorage = () => {
  return window.localStorage.removeItem('refCode');
};

export const generateUniqueRefCode = async ({ currentUser, sdk, listingId }) => {
  const { firstName = ' ', lastName = ' ' } = currentUser.attributes.profile;

  const refCodeBase = (firstName + lastName[0]).toLowerCase();

  const checkResults = await sdk.listings
    .query({
      per_page: 100,
      pub_refCodeBase: refCodeBase
    });

  const duplicatesCount = checkResults.data.data && checkResults.data.data.length;

  const refCode = duplicatesCount > 0 ? refCodeBase + (duplicatesCount + 1).toString() : refCodeBase;
  const refLink = `${process.env.REACT_APP_CANONICAL_ROOT_URL}/r/${refCode}`;

  await sdk.ownListings.update({
    id: listingId,
    publicData: {
      refCode,
      refCodeBase,
      refLink,
    }
  });

  await sdk.currentUser.updateProfile({
    privateData: {
      refCode,
      refLink,
    }
  });

  return {
    refCode,
    refCodeBase,
  };
};

export const getInfoFromRefCode = async ({ refCode, sdk }) => {
  const results = await sdk.listings
    .query({
      pub_refCode: refCode,
      include: ['author'],
    });
  const expertProfileListing = results.data.data[0];

  if (!expertProfileListing) {
    return null;
  }

  const expertId = expertProfileListing.relationships.author.data.id.uuid;
  const listingId = expertProfileListing.id.uuid;
  const title = expertProfileListing.attributes.title;
  const listingLink = `/l/${createSlug(title)}/${listingId}`;

  saveRefCodeToLocalStorage({
    refCode,
    refUserId: expertId,
  });

  return ({ expertProfileListing, expertId, title, listingLink });
};
//
// // window.getInfoFromRefCode = getInfoFromRefCode;


