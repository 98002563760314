import React from 'react';
import { string } from 'prop-types';
import { Field } from 'react-final-form';
import DateController from './DateController';

const component = props => {
  const { input, controllerRef, ...rest } = props;
  return <DateController ref={controllerRef} {...input} {...rest} />;
};

const FieldDateController = props => {
  return <Field component={component} {...props} />;
};

FieldDateController.defaultProps = {
  rootClassName: null,
  className: null,
};

FieldDateController.propTypes = {
  rootClassName: string,
  className: string,
};

export default FieldDateController;
