import React, { Component, createRef, Fragment } from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { FilterPopup, FilterPlain } from '../../components';
import css from './BookingDateRangeFilter.css';
import FieldDateController from '../FieldDateController/FieldDateController';
import FieldSelect from '../FieldSelect/FieldSelect';
import config from '../../config';

const TIMES_OPTIONS = config.custom.times.filter(f => f.key >= 300);

export class BookingDateRangeFilterComponent extends Component {
  constructor(props) {
    super(props);

    this.popupControllerRef = createRef();
    this.plainControllerRef = createRef();
    this.form = null;
  }

  render() {
    const {
      className,
      rootClassName,
      showAsPopup,
      initialValues: initialValuesRaw,
      id,
      contentPlacementOffset,
      onSubmit,
      urlParam,
      intl,
      showCancelButton,
      disabledApplyWithoutTime,
      ...rest
    } = this.props;

    const isSelected = !!initialValuesRaw && !!initialValuesRaw.date;
    const initialValues = isSelected ? initialValuesRaw : { dates: null };

    const startDate = isSelected ? initialValues.dates.startDate : null;

    const format = {
      month: 'short',
      day: 'numeric',
    };

    const formattedStartDate = isSelected ? intl.formatDate(startDate, format) : null;
    const formattedEndDate = isSelected
      ? intl.formatDate(startDate, {
          hour: 'numeric',
          minute: 'numeric',
        })
      : null;

    const labelForPlain = isSelected
      ? intl.formatMessage(
          { id: 'BookingDateRangeFilter.labelSelectedPlain' },
          {
            dates: `${formattedStartDate} - ${formattedEndDate}`,
          }
        )
      : intl.formatMessage({ id: 'BookingDateRangeFilter.labelPlain' });

    const labelForPopup = isSelected
      ? intl.formatMessage(
          { id: 'BookingDateRangeFilter.labelSelectedPopup' },
          {
            dates: `${formattedStartDate} - ${formattedEndDate}`,
          }
        )
      : intl.formatMessage({ id: 'BookingDateRangeFilter.labelPopup' });

    const onClearPopupMaybe =
      this.popupControllerRef.current && this.popupControllerRef.current.onReset
        ? { onClear: () => this.popupControllerRef.current.onReset(null) }
        : {};

    const onCancelPopupMaybe =
      this.popupControllerRef && this.popupControllerRef.onReset
        ? { onCancel: () => this.popupControllerRef.onReset(startDate) }
        : {};

    const onClearPlainMaybe =
      this.form && this.plainControllerRef.current && this.plainControllerRef.current.onReset
        ? {
            onClear: () => {
              this.plainControllerRef.current.onReset();
              this.form.change('times', null);
            },
          }
        : {};

    return showAsPopup ? (
      <FilterPopup
        className={className}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        label={labelForPopup}
        isSelected={isSelected}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={onSubmit}
        {...onClearPopupMaybe}
        {...onCancelPopupMaybe}
        initialValues={initialValues}
        urlParam={urlParam}
        showCancelButton={showCancelButton}
        disabledApplyWithoutTime={disabledApplyWithoutTime}
        {...rest}
      >
        {form => {
          this.form = form;
          return (
            <div>
              <FieldDateController
                name="date"
                controllerRef={this.popupControllerRef}
                customOnChange={() => {
                  form.change('times', null);
                }}
              />
              <div className={css.selectWrapper}>
                <FieldSelect name="times" label="Time" id="time">
                  <option disabled value="">
                    Select time
                  </option>
                  {TIMES_OPTIONS.map(op => (
                    <option key={op.key} value={op.key}>
                      {op.label}
                    </option>
                  ))}
                </FieldSelect>
              </div>
            </div>
          );
        }}
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={isSelected}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={onSubmit}
        {...onClearPlainMaybe}
        initialValues={initialValues}
        urlParam={urlParam}
        showCancelButton={showCancelButton}
        disabledApplyWithoutTime={disabledApplyWithoutTime}
        {...rest}
      >
        {form => {
          this.form = form;
          return (
            <div>
              {/*<FieldDateController*/}
                {/*name="date"*/}
                {/*controllerRef={this.plainControllerRef}*/}
                {/*customOnChange={() => {*/}
                  {/*form.change('times', null);*/}
                {/*}}*/}
              {/*/>*/}
              <FieldDateController
                name="date"
                controllerRef={this.plainControllerRef}
              />
              <div className={css.selectWrapper}>
                <FieldSelect name="times" label="Time" id="time">
                  <option disabled value="">
                    Select time
                  </option>
                  {TIMES_OPTIONS.map(op => (
                    <option key={op.key} value={op.key}>
                      {op.label}
                    </option>
                  ))}
                </FieldSelect>
              </div>
            </div>
          );
        }}
      </FilterPlain>
    );
  }
}

BookingDateRangeFilterComponent.defaultProps = {
  rootClassName: null,
  className: null,
  showAsPopup: true,
  liveEdit: false,
  initialValues: null,
  contentPlacementOffset: 0,
};

BookingDateRangeFilterComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  showAsPopup: bool,
  liveEdit: bool,
  urlParam: string.isRequired,
  onSubmit: func.isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

const BookingDateRangeFilter = injectIntl(BookingDateRangeFilterComponent);

export default BookingDateRangeFilter;
