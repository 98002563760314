import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ItineraryForm } from '../../forms';
import { withRouter } from 'react-router-dom';
import css from './ItineraryPage.css';
import { Page, LayoutSingleColumn, LayoutWrapperTopbar, LayoutWrapperMain, LayoutWrapperFooter, Footer, NamedRedirect } from '../../components';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '..';
import { loadData, sendItinerary } from '../TransactionPage/TransactionPage.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ensureTransaction } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { txHasBeenSendItinerary } from '../../util/transaction';

const { UUID } = sdkTypes;
class ItineraryPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  sendItinerary = (id, value) => {
    const { onSendItinerary, history } = this.props;

    return onSendItinerary(new UUID(id), value)
      .then(() => {
        const routes = routeConfiguration();
        const url = createResourceLocatorString('SalePage', routes, { id });
        history.push(url);
      })
  }

  render() {
    const {
      intl,
      scrollingDisabled,
      params,
      userRole,
      onSendItinerary,
      fetchTransactionInProgress,
      currentUser,
      fetchTransactionError,
      transaction,
      sendItineraryInProgress,
      sendItineraryError,
      isAuthenticated,
      location
    } = this.props;
    const title = intl.formatMessage({ id: 'ItineraryPage.title' });
    const { id } = params;
    const currentTransaction = ensureTransaction(transaction);
    const isDataAvailable =
      currentUser &&
      currentTransaction.id &&
      currentTransaction.id.uuid === params.id &&
      currentTransaction.attributes.lineItems &&
      currentTransaction.customer &&
      currentTransaction.provider &&
      !fetchTransactionError && !fetchTransactionInProgress;
    const isCustomerRole = userRole === 'customer';
    const { data } = currentTransaction && currentTransaction.id ? currentTransaction.attributes.protectedData : {};
    const fetchErrorMessage = isCustomerRole
      ? 'TransactionPage.fetchOrderFailed'
      : 'TransactionPage.fetchSaleFailed';
    const loadingMessage = isCustomerRole
      ? 'TransactionPage.loadingOrderData'
      : 'TransactionPage.loadingSaleData';

    const loadingOrFailedFetching = fetchTransactionError ? (
      <p className={css.error}>
        <FormattedMessage id={`${fetchErrorMessage}`} />
      </p>
    )
      : (
        <p className={css.loading}>
          <FormattedMessage id={`${loadingMessage}`} />
        </p>
      );

    const pageTitle = <FormattedMessage id="ItineraryPage.pageTitle" />;
    const initialValues = data ? JSON.parse(data) : {};
    if (initialValues.date) {
      initialValues.date = {
        date: new Date(initialValues.date.date)
      };
    }

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.root}>
              <h1 className={css.pageTitle}>
                {pageTitle}
              </h1>
              <div className={css.heroContainer}>

              </div>
              {
                isDataAvailable ?
                  <ItineraryForm transaction={currentTransaction} initialValues={initialValues} onSubmit={value => this.sendItinerary(id, value)} isSent={txHasBeenSendItinerary(currentTransaction)} isProvider={userRole === 'provider'} inProgress={sendItineraryInProgress} />
                  : loadingOrFailedFetching
              }
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ItineraryPageComponent.defaultProps = {
};

ItineraryPageComponent.propTypes = {
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    transactionRef,
    fetchTransactionInProgress,
    fetchTransactionError,
    sendItineraryInProgress,
    sendItineraryError
  } = state.TransactionPage;
  const { currentUser } = state.user;
  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    transaction,
    fetchTransactionInProgress,
    fetchTransactionError,
    currentUser,
    sendItineraryInProgress,
    sendItineraryError,
    isAuthenticated
  };
};

const mapDispatchToProps = dispatch => ({
  onSendItinerary: (id, data) => dispatch(sendItinerary(id, data))
});

const ItineraryPage = compose(
  injectIntl,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ItineraryPageComponent);

ItineraryPage.loadData = loadData;

export default ItineraryPage;
