import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import {
  Form,
  Button,
  FieldCheckboxGroup,
} from '../../components';
import { requiredFieldArrayCheckbox } from '../../util/validators';

import css from './EditListingSpecialtiesForm.css';
import config from '../../config';
import { ensureListing } from '../../util/data';

export const EditListingSpecialtiesFormComponent = ({ listing, ...props }) => {
  const ensuredListing = ensureListing(listing);
  const { publicData } = ensuredListing.attributes;
  const { skiType, skiLevel, skiSpecialties, skiKnowledgable, runDifficulty } = publicData || {};
  return (
    <FinalForm
      {...props}
      initialValues={{ skiType, skiLevel, skiSpecialties, skiKnowledgable, runDifficulty }}
      render={fieldRenderProps => {
        const {
          className,
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          submitted,
          inProgress,
          fetchErrors,
        } = fieldRenderProps;

        const { error } = fetchErrors || {};
        const errorMessage = error ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingSpecialtiesForm.updateFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = submitted && pristine;
        const submitDisabled = invalid || disabled || inProgress;

        const skitypeLabel = intl.formatMessage({ id: 'EditListingSpecialtiesForm.skitypeLabel' });
        const skiSpecialtiesLabel = intl.formatMessage({ id: 'EditListingSpecialtiesForm.skiSpecialtiesLabel' });
        const knowledgableLabel = intl.formatMessage({ id: 'EditListingSpecialtiesForm.knowledgableLabel' });
        const skiLevelLabel = intl.formatMessage({ id: 'EditListingspecialTiesForm.skiLevelLabel' });
        const runDifficultyLabel = intl.formatMessage({ id: 'EditListingSpecialtiesForm.runDifficultyLabel' });

        const skiTypeRequiredMessage = intl.formatMessage({ id: 'EditListingSpecialtiesForm.skiTypeRequiredMessage' });

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}

            <FieldCheckboxGroup
              id='skiType'
              name='skiType'
              label={skitypeLabel}
              options={config.custom.skiType}
              twoColumns={true}
              validate={requiredFieldArrayCheckbox(skiTypeRequiredMessage)}
              className={css.field}
            />

            <FieldCheckboxGroup
              id='skiLevel'
              name='skiLevel'
              label={skiLevelLabel}
              options={config.custom.skiLevel}
              twoColumns={true}
              className={css.field}
            />

            <FieldCheckboxGroup
              id='runDifficulty'
              name='runDifficulty'
              label={runDifficultyLabel}
              options={config.custom.runDifficulty}
              twoColumns={true}
              className={css.field}
            />

            <FieldCheckboxGroup
              id='skiSpecialties'
              name='skiSpecialties'
              label={skiSpecialtiesLabel}
              options={config.custom.skiSpecialties}
              twoColumns={true}
              className={css.field}
            />

            <FieldCheckboxGroup
              id='knowledgable'
              name='skiKnowledgable'
              label={knowledgableLabel}
              options={config.custom.knowledgable}
              twoColumns={true}
              className={css.field}
            />

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={inProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
}

EditListingSpecialtiesFormComponent.defaultProps = {
  fetchErrors: null,
};

EditListingSpecialtiesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  submitted: bool.isRequired,
  inProgress: bool.isRequired,
  fetchErrors: shape({
    error: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingSpecialtiesFormComponent);
