import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import 'date-fns';
import moment from 'moment';
import React, { Component } from 'react';
import Calendar from 'react-calendar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { compose } from 'redux';
import classNames from 'classnames';

import {
  MaterialAvailabilitiesComponent,
  PrimaryButton
} from '../../components';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { acceptSale } from '../TransactionPage/TransactionPage.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  txHasFirstReview,
  txIsAccepted,
  txIsRequested,
  txIsCanceled,
  txIsCompleted,
  txIsDeclinedOrExpired,
  txIsEnquired,
  txIsReviewed,
  DEFAULT_TIME_ZONE,
} from '../../util/types';
import './AvailabilitiesContainer.css';
import css from './AvailabilitiesContainer.css';
import {
  changeSelectedDateThunk,
  loadData,
  uiAddSlot,
  uiDeleteSlot,
  uiHandleSlotChange,
  uiToggleCheckDate,
  uiToggleCollapseDate,
  uiToggleScheduleMode,
  updateCustomSlots,
  updateScheduleSlots,
  uiHideSnackbar,
  fetchBookedTransactions,
  uiAddError,
} from './AvailabilitiesContainer.duck';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';


function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 0 }}>
      {children}
    </Typography>
  );
}

export class AvailabilitiesPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    const now = new Date;
    const firstDate = new Date();
    firstDate.setDate(1);
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1)
    endDate.setDate(0);
    //this.props.fetchBookedTransactions('sales', now, now);
    //this.props.fetchBookedTransactions('sales', firstDate, endDate, true);
  }

  uiChangeSelectedDate = (value) => {
    //this.props.fetchBookedTransactions('sales', value, value);
    //this.props.uiChangeSelectedDate(value);
  }

  convertTimeToHourMinFormat = (time, timeZone = DEFAULT_TIME_ZONE) => {
    return `${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', timeZone }).format(time)}`
  }

  showStartEndTime = (txBooking) => {
    const { displayStart, displayEnd } = txBooking.attributes;

    return `${this.convertTimeToHourMinFormat(displayStart)} - ${this.convertTimeToHourMinFormat(displayEnd)}`
  }

  render() {
    const {
      scrollingDisabled,
      intl,
      scheduleSlots,
      isLoading,
      classes,
      error,
      updateDefaultScheduleError,
      updateDefaultScheduleSuccess,
      updateCustomSlotError,
      listingId,
      uiToggleCollapseDate,
      uiToggleCheckDate,
      uiAddSlot,
      uiDeleteSlot,
      uiHandleSlotChange,
      updateScheduleSlots,
      uiToggleScheduleMode,
      isScheduleMode,
      theme,
      customSlots,
      updateCustomSlots,
      showSnackbar,
      uiHideSnackbar,
      currentUser,
      currentUserHasListings,
      currentUserHasListingsInProgress,
      transactions,
      transactionsMonth,
      onAcceptSale,
      acceptInProgress,
      uiAddError,
      timezone,
      updateInProgress,
      submitButtonText
    } = this.props;

    transactions.sort((tx1, tx2) => {
      return tx1.booking.attributes.displayStart.getTime() - tx2.booking.attributes.displayStart.getTime();
    })

    let { selectedDate } = this.props;
    selectedDate = selectedDate ? new Date(selectedDate) : new Date(new Date().setHours(0, 0, 0, 0));

    const title = intl.formatMessage({ id: 'ProfileSettingsPage.title' });
    const defaultScheduleLabel = intl.formatMessage({ id: 'AvailabilitiesPage.defaultSchedule' });
    const customSchedule = intl.formatMessage({ id: 'AvailabilitiesPage.customSchedule' });
    const tabTitles = [defaultScheduleLabel, customSchedule];
    const noListingTitle = intl.formatMessage({ id: 'AvailabilitiesPage.noListing' });
    const noListingReason1 = intl.formatMessage({ id: 'AvailabilitiesPage.noListingReason1' });
    const noListingReason2 = intl.formatMessage({ id: 'AvailabilitiesPage.noListingReason2' });
    const noListingReason3 = intl.formatMessage({ id: 'AvailabilitiesPage.noListingReason3' });

    const savedAvailability = intl.formatMessage({ id: 'AvailabilitiesPage.savedAvailability' });
    const timezoneText = intl.formatMessage({ id: 'AvailabilitiesPage.timezone' }, { timezone: timezone });
    return (
      <div>
        <div>
          <TabContainer>
            <MaterialAvailabilitiesComponent
              submitButtonText={submitButtonText}
              days={scheduleSlots}
              toggleCollapse={uiToggleCollapseDate}
              toggleCheck={uiToggleCheckDate}
              addSlot={uiAddSlot}
              deleteSlot={uiDeleteSlot}
              handleSlotChange={uiHandleSlotChange}
              onClickSave={updateScheduleSlots}
              intl={intl}
              error={error}
              setError={uiAddError}
              submitInprogress={updateInProgress}
              success={updateDefaultScheduleSuccess}
            />
          </TabContainer>
        </div>
        {updateDefaultScheduleError && updateDefaultScheduleError.messageId ? (
          <div className={css.erorrContainer}>
            <h3 className={css.errorHeading}>
              <FormattedMessage id={updateDefaultScheduleError.messageId} />
            </h3>
          </div>
        ) : null}
        {error && error.messageId ? (
          <div className={css.erorrContainer}>
            <h3 className={css.errorHeading}>
              <FormattedMessage id={error.messageId} values={{ newLine: (<br />) }} />
            </h3>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserHasListings,
    currentUserHasListingsInProgress,
  } = state.user;

  const {
    scheduleSlots,
    isLoading,
    error,
    updateDefaultScheduleError,
    updateDefaultScheduleSuccess,
    updateCustomSlotError,
    listingId,
    selectedDate,
    isScheduleMode,
    customSlots,
    showSnackbar,
    transactionRefs,
    transactionMonthRefs,
    timezone
  } = state.AvailabilitiesContainer;

  const { updateInProgress } = state.EditListingPage;

  const {
    acceptInProgress,
  } = state.TransactionPage;

  return {
    currentUser,
    currentUserHasListings,
    currentUserHasListingsInProgress,
    scrollingDisabled: isScrollingDisabled(state),
    scheduleSlots,
    isLoading,
    listingId,
    error,
    timezone,
    updateDefaultScheduleError,
    updateDefaultScheduleSuccess,
    updateCustomSlotError,
    selectedDate,
    isScheduleMode,
    customSlots,
    showSnackbar,
    transactions: getMarketplaceEntities(state, transactionRefs),
    transactionsMonth: getMarketplaceEntities(state, transactionMonthRefs),
    acceptInProgress,
    updateInProgress
  };
};

const mapDispatchToProps = dispatch => ({
  fetchAvs: () => dispatch(loadData()),
  updateScheduleSlots: () => dispatch(updateScheduleSlots()),
  //updateCustomSlots: () => dispatch(updateCustomSlots()),
  uiChangeSelectedDate: selectedDate => dispatch(changeSelectedDateThunk({ selectedDate })),
  uiToggleCollapseDate: dayIdx => () => dispatch(uiToggleCollapseDate({ dayIdx })),
  uiToggleCheckDate: dayIdx => () => dispatch(uiToggleCheckDate({ dayIdx })),
  uiAddSlot: (dayIdx, isBlocked = false) => dispatch(uiAddSlot({ dayIdx, isBlocked })),
  uiAddError: (error) => dispatch(uiAddError(error)),
  uiDeleteSlot: ({ dayIdx, slotIdx }) => () => dispatch(uiDeleteSlot({ dayIdx, slotIdx })),
  uiHandleSlotChange: ({ dayIdx, slotIdx }) => ({ isStart }) => ev =>
    dispatch(uiHandleSlotChange({ isStart, dayIdx, slotIdx, value: ev.target.value })),
  uiToggleScheduleMode: () => dispatch(uiToggleScheduleMode()),
  uiHideSnackbar: () => dispatch(uiHideSnackbar()),
  //fetchBookedTransactions: (tab, startDate, endDate, isMonth) => dispatch(fetchBookedTransactions(tab, startDate, endDate, isMonth)),
  //onAcceptSale: (id) => dispatch(acceptSale(id)),
});

const AvailabilitiesContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AvailabilitiesPageComponent);

AvailabilitiesContainer.loadData = loadData;

export default AvailabilitiesContainer;
