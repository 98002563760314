import React, { useEffect } from 'react';
import config from '../../config';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar, Page,
} from '../../components';
import css from '../ListingPage/ListingPage.css';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import TopbarContainer from '../TopbarContainer/TopbarContainer'

const DownloadItineraryPageComp = props => {
  const { params, scrollingDisabled } = props;
  const { id } = params;
  const downloadLink = `${config.apiUrl}/itinerary/${id}/download`;

  useEffect(() => window.location.href = downloadLink, [downloadLink])

  return (
    <Page title={'Download itinerary form'} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn className={css.pageRoot}>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <p style={{textAlign: 'center'}}>
            The form is downloading...
          </p>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
}

const mapStateToProps = state => ({
  scrollingDisabled: isScrollingDisabled(state)
})

const DownloadItineraryPage = connect(mapStateToProps)(DownloadItineraryPageComp);

export default DownloadItineraryPage;
