import React, { useState, useRef } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import {
  Form,
  Button,
  FieldTextInput,
  FieldDateInput,
  FieldSelect,
  FieldCheckboxGroup,
  ExternalLink
} from '../../components';
import { required, requiredFieldArrayCheckbox } from '../../util/validators';
import moment from 'moment';
import css from './ItineraryForm.css';
import config from '../../config';
import isEmpty from 'lodash/isEmpty';
import trimEnd from 'lodash/trimEnd';

const FIELDS = ['lift', 'run', 'notes'];

const convertToValue = (str, field) => {
  const words = str.split('\t');
  const returnValue = {};
  const currentFieldIndex = FIELDS.findIndex(item => item === field);
  for (let i = currentFieldIndex, k = 0; k < words.length; k++) {
    returnValue[FIELDS[i]] = words[k];
    i++;
  }

  return returnValue;
}
const convertPastedTextToFormValue = (string, field) => {
  const breaklineChar = string.includes("\r\n") ? "\r\n" : "\n";
  const rows = string.split(breaklineChar);
  const newRows = rows.map(row => row.replace(/\"/g, ''));

  newRows.forEach(row => console.log(JSON.stringify(row)))
  return newRows.reduce((prev, cur) => {
    const words = cur.split('\t');
    if (words.length === 3) {
      prev.push({
        lift: words[0],
        run: words[1],
        notes: words[2]
      });
    } else if (words.length === 2) {
      if (field === 'lift') {
        prev.push({
          lift: words[0],
          run: words[1],
        })
      }
      else if (field === 'run') {
        prev.push({
          run: words[0],
          notes: words[1],
        })
      }
    }
    else
      prev.push({ [field]: cur });
    return prev;
  }, []);
  return [];
}

export const ItineraryFormComponent = props => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const formRef = useRef(null);

  const handlePasteText = (name, field, currentIndex) => e => {
    const { values } = formRef.current.getState();
    const { steps = [] } = values;
    const currentValue = steps[currentIndex];
    const { lift, run, notes } = currentValue || {};
    const data = e.clipboardData.getData('Text');

    //current row don't have value

    if (!lift && !run && !notes) {
      const valueForForm = convertPastedTextToFormValue(data, field);
      const newSteps = [...steps.filter(step => {
        const { lift, notes, run } = step || {};
        return !!(lift || notes || run);
      }), ...valueForForm.filter(step => {
        const { lift, notes, run } = step || {};
        return !!(lift || notes || run);
      })];
      setTimeout(() => formRef.current.blur(`${name}.${field}`))
      setTimeout(() => formRef.current.change('steps', newSteps), 200);
    } else {
      const valueForForm = convertToValue(data, field);
      console.log({ valueForForm })
      setTimeout(() => formRef.current.blur(`${name}.${field}`))
      setTimeout(() => formRef.current.change(`steps[${currentIndex}]`, { ...steps[currentIndex], ...valueForForm }), 200);
    }
  }

  const handlePasteAdditionalNotes = (name, index) => e => {
    const { values } = formRef.current.getState();
    const { additionals = [] } = values;
    const data = e.clipboardData.getData('Text');
    const breaklineChar = data.includes("\r\n") ? "\r\n" : "\n";
    const rows = data.split(breaklineChar);
    const newRows = rows.map(row => row.replace(/\"/g, ''));
    const lines = newRows.reduce((prev, cur) => {
      if (cur.length)
        prev.push({ lift: cur });
      return prev;
    }, []);
    const newAdditionals = [...additionals, ...lines].filter(item => !isEmpty(item));
    console.log(newAdditionals)
    setTimeout(() => formRef.current.change('additionals', newAdditionals));
    setTimeout(() => formRef.current.blur(name), 200);
    setTimeout(() => document.getElementById('blur').focus(), 500);
  }

  return (
    <FinalForm
      mutators={{
        ...arrayMutators
      }}
      {...props}
      render={fieldRenderProps => {
        const {
          className,
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          submitted,
          inProgress,
          fetchErrors,
          isProvider,
          isSent,
          form,
          values,
          transaction
        } = fieldRenderProps;
        formRef.current = form;
        const { error } = fetchErrors || {};
        const errorMessage = error ? (
          <p className={css.error}>
            <FormattedMessage id="ItineraryForm.updateFailed" />
          </p>
        ) : null;
        const downloadLink = `${config.apiUrl}/itinerary/${transaction.id.uuid}/download`;

        const classes = classNames(css.root, className);
        const submitReady = submitted && pristine;
        const submitDisabled = invalid || disabled || inProgress;

        const nameLabel = intl.formatMessage({ id: 'ItineraryForm.nameLabel' });
        const nameRequiredMessage = intl.formatMessage({ id: 'ItineraryForm.nameRequiredMessage' });

        const dateLabel = intl.formatMessage({ id: 'ItineraryForm.dateLabel' });
        const dateRequiredMessage = intl.formatMessage({ id: 'ItineraryForm.dateRequiredMessage' });

        const mountainLabel = intl.formatMessage({ id: 'ItineraryForm.mountainLabel' });
        const mountainRequiredMessage = intl.formatMessage({ id: 'ItineraryForm.mountainRequiredMessage' });

        const startTimeLabel = intl.formatMessage({ id: 'ItineraryForm.startTimeLabel' });
        const startTimeRequiredMessage = intl.formatMessage({ id: 'ItineraryForm.startTimeRequiredMessage' });

        const startLocationLabel = intl.formatMessage({ id: 'ItineraryForm.startLocationLabel' });
        const startLocationRequiredMessage = intl.formatMessage({ id: 'ItineraryForm.startLocationRequiredMessage' });

        const endLocationLabel = intl.formatMessage({ id: 'ItineraryForm.endLocationLabel' });
        const endLocationRequiredMessage = intl.formatMessage({ id: 'ItineraryForm.endLocationRequiredMessage' });

        const skiTypeLabel = intl.formatMessage({ id: 'ItineraryForm.skiTypeLabel' });
        const skiTypeRequiredMessage = intl.formatMessage({ id: 'ItineraryForm.skiTypeRequiredMessage' });

        const numberOfSkierLabel = intl.formatMessage({ id: 'ItineraryForm.numberOfSkierLabel' });
        const numberOfSkierRequiredMessage = intl.formatMessage({ id: 'ItineraryForm.numberOfSkierRequiredMessage' });

        const goalLabel = intl.formatMessage({ id: 'ItineraryForm.goalLabel' });
        const goalRequiredMessage = intl.formatMessage({ id: 'ItineraryForm.goalRequiredMessage' });
        const readOnly = !isProvider || isSent;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}

            <div className={css.line}>
              <FieldTextInput
                className={css.inLineField}
                type='text'
                name='name'
                id='name'
                label={nameLabel}
                validate={required(nameRequiredMessage)}
                readOnly={readOnly}
              />

              <FieldDateInput
                className={css.inLineField}
                name='date'
                id='date'
                label={dateLabel}
                placeholderText={moment().format('MM/DD/YYYY').toString()}
                validate={required(dateRequiredMessage)}
                readOnly={readOnly}
                disabled={readOnly}
              />
            </div>

            <div className={css.line}>
              <FieldSelect
                className={css.inLineField}
                name='mountain'
                id='mountain'
                validate={required(mountainRequiredMessage)}
                label={mountainLabel}
                disabled={readOnly}
              >
                <option disabled value=''>{intl.formatMessage({id: 'ItineraryForm.selectMountain.placeholder'})}</option>
                {
                  config.custom.whereAreYouExpert.map(item => <option key={item.key} value={item.key}>{item.label}</option>)
                }
              </FieldSelect>

              <FieldSelect
                className={css.inLineField}
                name='startTime'
                id='startTime'
                label={startTimeLabel}
                validate={required(startTimeRequiredMessage)}
                disabled={readOnly}
              >
                <option disabled value=''>{intl.formatMessage({id: 'ItineraryForm.startTime.placeholder'})}</option>
                {
                  config.custom.times.map(item => <option key={item.key} value={item.key}>{item.label}</option>)
                }
              </FieldSelect>
            </div>
            <div className={css.line}>
              <FieldTextInput
                className={css.inLineField}
                name='startLocation'
                id='startLocation'
                type='text'
                label={startLocationLabel}
                validate={required(startLocationRequiredMessage)}
                readOnly={readOnly}
              />
              <FieldTextInput
                className={css.inLineField}
                name='endLocation'
                id='endLocation'
                type='text'
                label={endLocationLabel}
                validate={required(endLocationRequiredMessage)}
                readOnly={readOnly}
              />
            </div>
            <div className={css.line}>
              <FieldCheckboxGroup
                className={css.inLineField}
                twoColumns={true}
                name='type'
                id='type'
                label={skiTypeLabel}
                validate={requiredFieldArrayCheckbox(skiTypeRequiredMessage)}
                options={config.custom.skiType}
                readOnly={readOnly}
              />

              <FieldTextInput
                className={classNames(css.inLineField, {
                  [css.readOnly]: readOnly
                })}
                name='numberOfSkier'
                label={numberOfSkierLabel}
                id='numberOfSkier'
                type='number'
                validate={required(numberOfSkierRequiredMessage)}
                readOnly={readOnly}
              />
            </div>


            <FieldTextInput
              name='goal'
              label={goalLabel}
              id='goal'
              type='textarea'
              className={css.goal}
              validate={required(goalRequiredMessage)}
              readOnly={readOnly}
            />

            <div className={classNames(css.line, css.header)}>
              <div className={classNames(css.index, css.indexTitle)}>#</div>
              <div className={css.lift}>{intl.formatMessage({ id: 'ItineraryForm.lift' })}</div>
              <div className={css.run}>{intl.formatMessage({ id: 'ItineraryForm.run' })}</div>
              <div className={css.notes}>{intl.formatMessage({ id: 'ItineraryForm.notes' })}</div>
              {
                isProvider && !isSent && values.steps && values.steps.length > 0 && <div className={css.remove}>{intl.formatMessage({ id: 'ItineraryForm.remove' })}</div>
              }
            </div>

            <FieldArray name='steps'>
              {
                ({ fields }) => (
                  <React.Fragment>
                    {
                      fields.map((name, index) => {
                        return (
                          <div key={index} className={css.step}>
                            <div className={css.index}>{index + 1}</div>
                            <FieldTextInput
                              name={`${name}.lift`}
                              className={css.lift}
                              type='textarea'
                              readOnly={readOnly}
                              onPaste={handlePasteText(name, 'lift', index)}
                            />
                            <FieldTextInput
                              name={`${name}.run`}
                              className={css.run}
                              type='textarea'
                              readOnly={readOnly}
                              onPaste={handlePasteText(name, 'run', index)}
                            />
                            <FieldTextInput
                              name={`${name}.notes`}
                              className={css.notes}
                              type='textarea'
                              readOnly={readOnly}
                              onPaste={handlePasteText(name, 'notes', index)}
                            />
                            {
                              isProvider && !isSent && <div className={css.removeBtn} onClick={() => {
                                const stepValues = fields.value;
                                setTimeout(() => form.change('steps', stepValues.filter((_, i) => index !== i)));
                              }}>X</div>
                            }
                          </div>
                        );
                      })
                    }
                    {
                      isProvider && !isSent && (
                        <div
                          onClick={() => {
                            fields.push(undefined);
                            setCurrentIndex(currentIndex + 1);
                          }}
                          className={css.addNewLine}
                        >{intl.formatMessage({ id: 'ItineraryForm.addNewLine' })}</div>
                      )
                    }
                  </React.Fragment>
                )
              }
            </FieldArray>

            {
              isSent && (!values.additionals || (Array.isArray(values.additionals) && !values.additionals.length)) ? null : (
                <div className={classNames(css.line, css.header)}>
                  <div className={css.additionalNotes}>
                    {
                      intl.formatMessage({ id: 'ItineraryForm.additionalNotes' })
                    }
                  </div>
                  {
                    isProvider && !isSent && values.additionals && values.additionals.length > 0 && <div className={css.remove}>{intl.formatMessage({ id: 'ItineraryForm.remove' })}</div>
                  }
                </div>

              )
            }

            <FieldArray name='additionals'>
              {
                ({ fields }) => (
                  <React.Fragment>
                    {
                      fields.map((name, index) => {
                        return (
                          <div key={index} className={css.step}>
                            <div className={css.index}>{index + 1}</div>
                            <FieldTextInput
                              name={`${name}.lift`}
                              type='textarea'
                              className={css.additionalNote}
                              readOnly={readOnly}
                              onPaste={handlePasteAdditionalNotes(`${name}.lift`, index)}
                            />
                            {
                              isProvider && !isSent && <div className={css.removeBtn} onClick={() => {
                                const stepValues = fields.value;
                                setTimeout(() => form.change('additionals', stepValues.filter((_, i) => index !== i)));
                              }}>X</div>
                            }
                          </div>
                        );
                      })
                    }
                    {
                      isProvider && !isSent && (
                        <div
                          onClick={() => {
                            fields.push(undefined);
                            setCurrentIndex(currentIndex + 1);
                          }}
                          className={css.addNewLine}
                        >{intl.formatMessage({ id: 'ItineraryForm.addNewLine' })}</div>
                      )
                    }
                  </React.Fragment>
                )
              }
            </FieldArray>

            <input id='blur' style={{display: 'none'}} />

            {
              isProvider && !isSent && (
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={inProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {
                    intl.formatMessage({ id: 'ItineraryForm.sendItinerary' })
                  }
                </Button>
              )
            }
            {
              isSent ? <ExternalLink href={downloadLink} className={css.downloadBtn}>Download this form</ExternalLink> : null
            }
          </Form>
        );
      }}
    />
  );
}

ItineraryFormComponent.defaultProps = {
  fetchErrors: null,
};

ItineraryFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  selectedPlace: propTypes.place,
  submitted: bool.isRequired,
  inProgress: bool.isRequired,
  fetchErrors: shape({
    error: propTypes.error,
  }),
};

export default compose(injectIntl)(ItineraryFormComponent);
