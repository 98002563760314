import React, { useEffect } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import css from './ContactUsPage.css';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  Footer,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { StaticPage } from '..';
import { ContactUsForm } from '../../forms';
import { sendEmail, resetEmailSend } from './ContactUsPage.duck';
import { connect } from 'react-redux';

const ContactUsPageComponent = props => {
  const {
    rootClassName,
    className,
    sendContactEmail,
    sendEmailError,
    sendEmailSuccess,
    sendEmailInProgress,
    intl,
    onrResetEmailSend,
    history,
  } = props;

  useEffect(() => {
    onrResetEmailSend();
  },[]);

  useEffect(() => {
    if(sendEmailSuccess) {
      setTimeout(() => {
        history.push('/');        
      }, 1000);
    }
  }, [sendEmailSuccess]);

  const classes = classNames(rootClassName || css.root, className);
  const title = intl.formatMessage({ id: 'ContactUs.title' });

  return (
    <StaticPage title={title}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={classes}>
          <div className={css.content}>
            <h1 className={css.heading}>
              <FormattedMessage id="ContactUs.heading" />
            </h1>
            <ContactUsForm
              onSubmit={value => sendContactEmail(value)}
              sendEmailError={sendEmailError}
              sendEmailSuccess={sendEmailSuccess}
              sendEmailInProgress={sendEmailInProgress}
            />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

ContactUsPageComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

ContactUsPageComponent.propTypes = {
  rootClassName: string,
  className: string,
};

const mapStateToProps = state => {
  const { sendEmailInProgress, sendEmailSuccess, sendEmailError } = state.ContactUsPage;
  return {
    sendEmailError,
    sendEmailSuccess,
    sendEmailInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  sendContactEmail: values => dispatch(sendEmail(values)),
  onrResetEmailSend: () => dispatch(resetEmailSend()),
});

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ContactUsPageComponent);
