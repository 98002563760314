import { Button } from '../';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import MaterialTimeRangeComponent from '../MaterialTimeRange/MaterialTimeRange';
import css from './MaterialAvailabilities.css';
import { FormattedMessage } from 'react-intl';

class MaterialAvailabilitiesComponent extends Component {
  constructor(props) {
    super(props);
  }

  onAddSlot = (dayIndex, checkboxIndex, isBlocked = false) => {
    this.props.addSlot(dayIndex, isBlocked);
    const checkbox = document.querySelector(`#day-checkbox-${checkboxIndex}`);
    if (checkbox && !checkbox.checked) {
      //checkbox.click();
    }
  };

  render() {
    const {
      classes,
      toggleCheck,
      toggleCollapse,
      days,
      title,
      intl,
      handleSlotChange,
      deleteSlot,
      addSlot,
      onClickSave,
      isException,
      error,
      setError,
      submitInprogress,
      success,
      submitButtonText
    } = this.props;

    const saveAvailabilityButton = intl.formatMessage({
      id: 'AvailabilitiesPage.save',
    });

    const addNewSlotLabel = intl.formatMessage({
      id: 'AvailabilitiesPage.addNewSlot',
    });

    const addNewBlockedLabel = intl.formatMessage({
      id: 'AvailabilitiesPage.addNewBlocked',
    });


    const noteLabel = intl.formatMessage({
      id: 'AvailabilitiesPage.noteLabel',
    });

    const yourSettingLabel = intl.formatMessage({
      id: 'AvailabilitiesPage.yourSettingLabel',
    });

    const yourSettingExplanation = intl.formatMessage({
      id: 'AvailabilitiesPage.yourSettingExplanation',
    });

    const availableTimeLabel = intl.formatMessage({
      id: 'AvailabilitiesPage.availableTimeLabel',
    });

    const availableTimeExplanation = intl.formatMessage({
      id: 'AvailabilitiesPage.availableTimeExplanation',
    });

    const unavailableTimeLabel = intl.formatMessage({
      id: 'AvailabilitiesPage.unavailableTimeLabel',
    });

    const unavailableTimeExplanation = intl.formatMessage({
      id: 'AvailabilitiesPage.unavailableTimeExplanation',
    });


    return (
      <div className={css.container}>
        {error && error.messageId ? (
          <div className={css.erorrContainer}>
            <p className={css.errorHeading}>
              <FormattedMessage id={error.messageId} values={{ newLine: (<br />) }} />
            </p>
          </div>
        ) : null}
        <List className={css.root}>
          {days
            ? days.map((d, i) => (
              <div key={i}>
                <ListItem>
                  <Checkbox
                    type="checkbox"
                    checked={!isException ? d.isChecked : d.isChecked && d.slots.filter((s) => !s.isDeleted).length > 0}
                    onClick={toggleCollapse(i)}
                    tabIndex={-1}
                    disableRipple
                    id={`day-checkbox-${i}`}
                  />
                  <ListItemText primary={d.long} />

                  {/* <ListItemSecondaryAction>
                    <IconButton onClick={toggleCollapse(i)} aria-label="Expand">
                      {d.isOpen ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </ListItemSecondaryAction> */}
                </ListItem>

                <Collapse in={d.isOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {d.slots
                      .map((s, slotIdx) => ({ ...s, slotIdx }))
                      .filter(s => {
                        const isNewSlot = typeof s.type === 'undefined';
                        const isDeletedNewSlot = isNewSlot && typeof s.isDeleted !== 'undefined' && s.isDeleted === true;
                        return !isDeletedNewSlot;
                      })
                      .reverse()
                      .map(s => (
                        <MaterialTimeRangeComponent
                          isException={isException}
                          isFromSchedule={s.isFromSchedule}
                          isFromCustom={s.isFromCustom}
                          currentDay={d}
                          key={s.slotIdx}
                          dayIdx={d.dayIdx}
                          deleted={s.isDeleted}
                          start={s.start}
                          currentSlot={s}
                          end={s.end}
                          intl={intl}
                          error={error}
                          slotIdx={s.slotIdx}
                          setInvalidCustomSlotError={error => {
                            setError(error);
                          }}
                          deleteSlot={deleteSlot({ slotIdx: s.slotIdx, dayIdx: d.dayIdx })}
                          handleSlotChange={handleSlotChange({
                            dayIdx: d.dayIdx,
                            slotIdx: s.slotIdx,
                          })}
                        />
                      ))}

                    <ListItem onClick={() => {
                      setError(null);
                      this.onAddSlot(d.dayIdx, i);
                    }} button>
                      <ListItemIcon>
                        <AddIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText className={css.primary} primary={addNewSlotLabel} />
                    </ListItem>
                    {isException ? (
                      <ListItem onClick={() => {
                        setError(null);
                        this.onAddSlot(d.dayIdx, i, true);
                      }} button>
                        <ListItemIcon>
                          <AddIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText className={css.blockedTime} primary={addNewBlockedLabel} />
                      </ListItem>
                    ) : null}
                  </List>
                  {isException ? (<div>
                    <p className={css.explaination}><span>{noteLabel}</span></p>
                    <p className={css.explaination}><span>{yourSettingLabel}</span>{yourSettingExplanation}</p>
                    <p className={css.explaination}><span>{unavailableTimeLabel}</span>{unavailableTimeExplanation}</p>
                    <p className={css.explaination}><span>{availableTimeLabel}</span>{availableTimeExplanation}</p>
                  </div>) : null}
                </Collapse>
                {i < days.length - 1 ? <Divider /> : null}
              </div>
            ))
            : null}
        </List>

        <Button
          onClick={() => {
            // onClickSave();
            const availabilitySubmitBtn = document.querySelector('#availabilitySubmit');
            if (availabilitySubmitBtn) {
              availabilitySubmitBtn.click();
            }
          }}
          className={css.saveButton}
          inProgress={submitInprogress}
          ready={success}
        >
          {submitButtonText}
        </Button>

      </div>
    );
  }
}

export default MaterialAvailabilitiesComponent;
