/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape, FormattedMessage } from 'react-intl';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import {
  ensureListing,
  ensureCurrentUser,
  ensureOwnListing,
  userDisplayNameAsString,
} from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import {
  EditListingAvailabilityPanel,
  EditListingPhotosPanel,
  EditListingPanel,
} from '../../components';

import { DRAFT_LISTING_INITIAL_TITLE } from '../../util/types';

import css from './EditListingWizard.css';
import {
  EditListingAgreementForm,
  EditListingLocationForm,
  EditListingSpecialtiesForm,
  EditListingDescriptionForm,
} from '../../forms';
import ListingLink from '../ListingLink/ListingLink';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money, LatLng } = sdkTypes;

export const AGREEMENT = 'agreement';
export const LOCATION = 'location';
export const DESCRIPTION = 'description';
export const SPECIALTIES = 'specialties';
export const AVAILABILITY = 'availability';
export const PHOTOS = 'photos';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [AGREEMENT, LOCATION, DESCRIPTION, SPECIALTIES, AVAILABILITY, PHOTOS];

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (listingId, params, tab, marketplaceTabs, history) => {
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingId,
  };
  const routes = routeConfiguration();

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString('EditListingPage', routes, currentPathParams, {});
    history.replace(draftURI);
  }

  // Redirect to next tab

  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  const to = createResourceLocatorString('EditListingPage', routes, nextPathParams, {});
  history.push(to);
};

const editedResortsList = (value, list, listingId) => {
  let data = {};

  if (!list) {
    data = {
      publicData: {
        resortsList: {
          [listingId]: value,
        },
      },
    };
  } else {
    for (let i in list) {
      if (i === listingId) {
        list[i] = value;
        data = {
          publicData: {
            resortsList: list,
          },
        };
      } else {
        list[listingId] = value;
        data = {
          publicData: {
            resortsList: list,
          },
        };
      }
    }
  }

  return data;
};

const editedAdminData = (value, list, oldValue) => {
  let data = {};

  if (!list || Object.keys(list).length === 0) {
    data = {
      publicData: {
        resortsAreUsed: {
          [value]: 1,
        },
      },
    };
  } else {
    list.hasOwnProperty(value) ? (list[value] = list[value] + 1) : (list[value] = 1);
    list.hasOwnProperty(oldValue) &&
      (list[oldValue] = list[oldValue] >= 1 ? list[oldValue] - 1 : 0);
    list.hasOwnProperty(oldValue) && list[oldValue] === 0 && delete list[oldValue];

    data = {
      publicData: {
        resortsAreUsed: { ...list },
      },
    };
  }
  return data;
};

const EditListingWizardTab = props => {
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    fetchInProgress,
    newListingPublished,
    history,
    images,
    availability,
    listing,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    onChange,
    updatedTab,
    updateInProgress,
    intl,
    currentUser,
    scheduleSlots,
    timezone,
    onManageDisableScrolling,
    onAddAvailabilityException,
    fetchExceptionsInProgress,
    availabilityExceptions,
    onDeleteAvailabilityException,
    onUpdateProfile,
    onUpdateAdminProfile,
    adminData,
    onGetAdminProfile,
    onGetAllResorts,
    onFetchCurrentUser,
  } = props;
  console.log('LOG: : -------------------------------');
  console.log('LOG: : currentUser', currentUser);
  console.log('LOG: : -------------------------------');

  const [duplicateResorterror, setDuplicateResortError] = useState(false);

  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const resortsList =
    currentUser &&
    currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.resortsList
      ? currentUser.attributes.profile.publicData.resortsList
      : null;

  const currentListing = ensureOwnListing(listing);
  const imageIds = images => {
    return images ? images.map(img => img.imageId || img.id) : null;
  };

  const whereAreYouAnExpertOld =
    currentListing && currentListing.attributes.publicData.whereAreYouAnExpert
      ? currentListing.attributes.publicData.whereAreYouAnExpert
      : null;

  const onCompleteEditListingWizardTab = (tab, updateValues, whereAreYouAnExpert) => {
    // Normalize images for API call
    const { images: updatedImages, ...otherValues } = updateValues;
    const imageProperty =
      typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
    const updateValuesWithImages = { ...otherValues, ...imageProperty };

    if (isNewListingFlow) {
      const onUpsertListingDraft = isNewURI
        ? (tab, updateValues) => onCreateListingDraft(updateValues)
        : onUpdateListing;

      const upsertValues = isNewURI
        ? updateValuesWithImages
        : { ...updateValuesWithImages, id: currentListing.id };

      return onUpsertListingDraft(tab, upsertValues)
        .then(r => {
          if (tab !== AVAILABILITY && tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
            // Create listing flow: smooth scrolling polyfill to scroll to correct tab
            handleCreateFlowTabScrolling(false);

            // After successful saving of draft data, user should be redirected to next tab
            return redirectAfterDraftUpdate(
              r.data.data.id.uuid,
              params,
              tab,
              marketplaceTabs,
              history
            );
          } else {
            return handlePublishListing(currentListing.id, whereAreYouAnExpertOld);
          }
        })
        .then(response => {
          if (tab === LOCATION && whereAreYouAnExpert) {
            const data = editedResortsList(
              whereAreYouAnExpert,
              resortsList,
              currentListing.id.uuid
            );
            onUpdateProfile(data);
          }
          return response;
        })
        .catch(e => {
          // No need for extra actions
        });
    } else {
      return onUpdateListing(tab, { ...updateValuesWithImages, id: currentListing.id }).then(
        async response => {
          if (tab === LOCATION && whereAreYouAnExpert) {
            const data = editedResortsList(
              whereAreYouAnExpert,
              resortsList,
              currentListing.id.uuid
            );
            onUpdateProfile(data);

            const res = await onGetAdminProfile();
            const resortsAreUsed = res ? res.attributes.profile.publicData.resortsAreUsed : null;
            const isLatestResortData =
              res && res.attributes.profile.publicData.isLatestResortData
                ? res.attributes.profile.publicData.isLatestResortData
                : false;

            if (isLatestResortData) {
              const adminNewData = editedAdminData(
                whereAreYouAnExpert,
                resortsAreUsed,
                whereAreYouAnExpertOld
              );
              onUpdateAdminProfile(adminNewData);
            } else {
              onGetAllResorts();
            }
          }
        }
      );
    }
  };

  const panelProps = tab => {
    return {
      errors,
      listing: currentListing,
      onChange,
      submitted: updatedTab === tab,
      inProgress: updateInProgress,
      updateInProgress,
      currentUser,
      scheduleSlots,
      timezone,
    };
  };

  switch (tab) {
    case AGREEMENT: {
      const panelTitle = intl.formatMessage({ id: 'EditListingWizard.panelTitleAgreement' });
      const saveActionMsg = intl.formatMessage({ id: 'EditListingWizard.saveActionMsgAgreement' });
      return (
        <EditListingPanel className={css.panel} panelTitle={panelTitle}>
          <EditListingAgreementForm
            {...panelProps(tab)}
            saveActionMsg={saveActionMsg}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, {
                title: DRAFT_LISTING_INITIAL_TITLE,
                publicData: { ...values },
              });
            }}
          />
        </EditListingPanel>
      );
    }
    case DESCRIPTION: {
      const { displayName } = ensureCurrentUser(currentUser).attributes.profile;
      const panelTitle = isNewListingFlow ? (
        intl.formatMessage({ id: 'EditListingWizard.panelTitleDescription' })
      ) : (
        <FormattedMessage
          id="EditListingWizard.panelTitleDescriptionEdit"
          values={{ listing: <ListingLink listing={listing}>{displayName}</ListingLink> }}
        />
      );

      const submitButtonTranslationKey = isNewListingFlow
        ? intl.formatMessage({ id: 'EditListingWizard.saveNewDescription' })
        : intl.formatMessage({ id: 'EditListingWizard.saveEditDescription' });

      const saveActionMsg = intl.formatMessage({ id: submitButtonTranslationKey });
      console.log(panelProps(DESCRIPTION));
      return (
        <EditListingPanel className={css.panel} panelTitle={panelTitle}>
          <EditListingDescriptionForm
            {...panelProps(DESCRIPTION)}
            saveActionMsg={saveActionMsg}
            onSubmit={({ title, description, aboutBeingSkiGuide, aGreatSkiGuide }) => {
              onCompleteEditListingWizardTab(tab, {
                title,
                description,
                price: new Money(config.listingDefaultPrice, config.currency),
                publicData: { others: { aboutBeingSkiGuide, aGreatSkiGuide } },
              });
            }}
          />
        </EditListingPanel>
      );
    }
    case SPECIALTIES: {
      const { displayName } = ensureCurrentUser(currentUser).attributes.profile;
      const panelTitle = isNewListingFlow ? (
        intl.formatMessage({ id: 'EditListingWizard.panelTitleSpecialties' })
      ) : (
        <FormattedMessage
          id="EditListingWizard.panelTitleSpecialtiesEdit"
          values={{ listing: <ListingLink listing={listing}>{displayName}</ListingLink> }}
        />
      );
      const submitButtonTranslationKey = isNewListingFlow
        ? intl.formatMessage({ id: 'EditListingWizard.saveNewSpecialties' })
        : intl.formatMessage({ id: 'EditListingWizard.saveEditSpecialties' });
      return (
        <EditListingPanel className={css.panel} panelTitle={panelTitle}>
          <EditListingSpecialtiesForm
            {...panelProps(SPECIALTIES)}
            saveActionMsg={submitButtonTranslationKey}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, { publicData: { ...values } });
            }}
          />
        </EditListingPanel>
      );
    }
    case LOCATION: {
      const { displayName } = ensureCurrentUser(currentUser).attributes.profile;
      const panelTitle = isNewListingFlow ? (
        intl.formatMessage({ id: 'EditListingWizard.panelTitleLocation' })
      ) : (
        <FormattedMessage
          id="EditListingWizard.panelTitleLocationEdit"
          values={{ listing: <ListingLink listing={listing}>{displayName}</ListingLink> }}
        />
      );

      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewLocation'
        : 'EditListingWizard.saveEditLocation';
      return (
        <EditListingPanel className={css.panel} panelTitle={panelTitle}>
          <EditListingLocationForm
            {...panelProps(LOCATION)}
            resortsList={resortsList}
            onGetAdminProfile={onGetAdminProfile}
            duplicateResorterror={duplicateResorterror}
            onSubmit={values => {
              const { whereAreYouAnExpert } = values;
              const latLng = config.custom.defaultLocation[whereAreYouAnExpert];
              const geolocation = new LatLng(latLng.lat, latLng.lng);

              onFetchCurrentUser().then(response => {
                const resortsList = response.attributes.profile.publicData.resortsList
                  ? response.attributes.profile.publicData.resortsList
                  : {};

                const isDuplicateResort = resortsList &&
                  Object.values(resortsList).indexOf(whereAreYouAnExpert) > -1;

                const isSameValue =
                  resortsList && resortsList.hasOwnProperty(currentListing.id.uuid) &&
                  resortsList[currentListing.id.uuid] === whereAreYouAnExpert;

                if (isDuplicateResort && !isSameValue) {
                  setDuplicateResortError(true);
                } else {
                  onCompleteEditListingWizardTab(
                    tab,
                    { geolocation, publicData: { ...values } },
                    whereAreYouAnExpert
                  );
                }
              });
            }}
            saveActionMsg={intl.formatMessage({ id: submitButtonTranslationKey })}
          />
        </EditListingPanel>
      );
    }
    case AVAILABILITY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewAvailability'
        : 'EditListingWizard.saveEditAvailability';
      return (
        <EditListingAvailabilityPanel
          className={css.panel}
          {...panelProps(AVAILABILITY)}
          fetchExceptionsInProgress={fetchExceptionsInProgress}
          availabilityExceptions={availabilityExceptions}
          onManageDisableScrolling={onManageDisableScrolling}
          availability={availability}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => onCompleteEditListingWizardTab(tab, values)}
          onAddAvailabilityException={onAddAvailabilityException}
          onDeleteAvailabilityException={onDeleteAvailabilityException}
          displayName={userDisplayNameAsString(currentUser)}
          onNextTab={() =>
            redirectAfterDraftUpdate(listing.id.uuid, params, tab, marketplaceTabs, history)
          }
        />
      );
    }
    case PHOTOS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPhotos'
        : 'EditListingWizard.saveEditPhotos';

      // newListingPublished and fetchInProgress are flags for the last wizard tab
      return (
        <EditListingPhotosPanel
          className={css.panel}
          {...panelProps(PHOTOS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          newListingPublished={newListingPublished}
          fetchInProgress={fetchInProgress}
          images={images}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          onUpdateImageOrder={onUpdateImageOrder}
        />
      );
    }
    default:
      return null;
  }
};

EditListingWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
};

const { array, bool, func, object, oneOf, shape, string } = PropTypes;

EditListingWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,
  availability: object.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,

  intl: intlShape.isRequired,
};

export default EditListingWizardTab;
