import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FieldPhoneNumberInput, Form, FieldTextInput, Button } from '../../components';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import css from './ContactUsForm.css';
import { required, emailFormatValid, composeValidators } from '../../util/validators';

class ContactUsFormComponent extends Component {
  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            intl,
            values,
            sendEmailError,
            sendEmailSuccess,
            sendEmailInProgress,
            pristine,
            invalid,
            disabled,
          } = fieldRenderProps;
          const classes = classNames(rootClassName || css.root, className);

          const firstNameLabel = intl.formatMessage({ id: 'ContactUsForm.firstNameLabel' });
          const lastNameLabel = intl.formatMessage({ id: 'ContactUsForm.lastNameLabel' });
          const emailLabel = intl.formatMessage({ id: 'ContactUsForm.emailLabel' });
          const phoneLabel = intl.formatMessage({ id: 'ContactUsForm.phoneLabel' });
          const subjectLabel = intl.formatMessage({ id: 'ContactUsForm.subjectLabel' });
          const messageLabel = intl.formatMessage({ id: 'ContactUsForm.messageLabel' });

          const requiredMessage = intl.formatMessage({ id: 'ContactUsForm.requiredMessage' });
          const invalidEmail = intl.formatMessage({ id: 'ContactUsForm.invalidEmail' });

          const buttonClasses = sendEmailInProgress
            ? classNames(css.submitButton, css.sendingButton)
            : css.submitButton;

          const submitDisabled = pristine || invalid || disabled || sendEmailInProgress;

          const error = sendEmailError ? <FormattedMessage id="ContactUsForm.error" /> : null

          return (
            <Form
              className={classes}
              onSubmit={e => {
                e.preventDefault();
                this.props.onSubmit(values);
              }}
            >
              <div className={css.name}>
                <FieldTextInput
                  id="firstName"
                  name="firstName"
                  type="text"
                  label={firstNameLabel}
                  className={css.firstName}
                  validate={required(requiredMessage)}
                />
                <FieldTextInput
                  id="lastName"
                  name="lastName"
                  type="text"
                  label={lastNameLabel}
                  className={css.lastName}
                  validate={required(requiredMessage)}
                />
              </div>
              <div className={css.inputWrapper}>
                <FieldTextInput
                  id="email"
                  name="email"
                  type="email"
                  label={emailLabel}
                  validate={composeValidators(
                    required(requiredMessage),
                    emailFormatValid(invalidEmail)
                  )}
                />
              </div>
              <div className={css.inputWrapper}>
                <FieldPhoneNumberInput id="phone" name="phone" label={phoneLabel} />
              </div>
              <div className={css.inputWrapper}>
                <FieldTextInput id="subject" name="subject" type="text" label={subjectLabel} />
              </div>
              <div className={css.inputWrapper}>
                <FieldTextInput
                  id="message"
                  name="message"
                  type="textarea"
                  label={messageLabel}
                  validate={required(requiredMessage)}
                />
              </div>

              <div className={css.submit}>
                <Button
                  className={buttonClasses}
                  inProgress={sendEmailInProgress}
                  ready={sendEmailSuccess}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="ContactUsForm.submit" />
                </Button>
                {sendEmailSuccess && (
                  <div className={css.successMessage}>
                    <FormattedMessage id="ContactUsForm.success" />
                  </div>
                )}
                <div className={css.errorMessage}>{error}</div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ContactUsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string, bool } = PropTypes;

ContactUsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  sendEmailSuccess: bool,
  sendEmailInProgress: bool,
};

const ContactUsForm = compose(injectIntl)(ContactUsFormComponent);

ContactUsForm.displayName = 'ContactDetailsForm';

export default ContactUsForm;
