import React, { useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './SearchDropDown.css';
import IconHourGlass from '../LocationAutocompleteInput/IconHourGlass';
// import config from '../../config';

const KEY_CODE_ARROW_UP = 38;
const KEY_CODE_ARROW_DOWN = 40;
const KEY_CODE_ENTER = 13;
const KEY_CODE_TAB = 9;
const KEY_CODE_ESC = 27;
const DIRECTION_UP = 'up';
const DIRECTION_DOWN = 'down';
const TOUCH_TAP_RADIUS = 5;

const getTouchCoordinates = nativeEvent => {
  const touch = nativeEvent && nativeEvent.changedTouches ? nativeEvent.changedTouches[0] : null;
  return touch ? { x: touch.screenX, y: touch.screenY } : null;
};

const LocationPredictionsList = props => {
  const {
    rootClassName,
    className,
    attributionClassName,
    predictions,
    geocoder,
    highlightedIndex,
    onSelectStart,
    onSelectMove,
    onSelectEnd,
  } = props;
  if (predictions.length === 0) {
    return null;
  }

  /* eslint-disable jsx-a11y/no-static-element-interactions */
  const item = (prediction, index) => {
    const isHighlighted = index === highlightedIndex;

    return (
      <li
        className={isHighlighted ? css.highlighted : null}
        key={index}
        onTouchStart={e => {
          e.preventDefault();
          onSelectStart(getTouchCoordinates(e.nativeEvent));
        }}
        onMouseDown={e => {
          e.preventDefault();
          onSelectStart();
        }}
        onTouchMove={e => {
          e.preventDefault();
          onSelectMove(getTouchCoordinates(e.nativeEvent));
        }}
        onTouchEnd={e => {
          e.preventDefault();
          onSelectEnd(prediction);
        }}
        onMouseUp={e => {
          e.preventDefault();
          onSelectEnd(prediction);
        }}
      >
        {
          prediction.predictionPlace.address
        }
      </li>
    );
  };
  /* eslint-enable jsx-a11y/no-static-element-interactions */

  const classes = classNames(rootClassName || css.predictionsRoot, className);

  return (
    <div className={classes}>
      <ul className={css.predictions}>{predictions.map(item)}</ul>
    </div>
  );
};

const SearchDropDown = props => {
  const {
    rootClassName,
    className,
    inputClassName,
    iconClassName,
    predictionsClassName,
    predictionsAttributionClassName,
    onChange,
    input,
    placeholder,
    defaultLocation,
  } = props;
  const { name, onFocus, onBlur, value, onGetAdminProfile } = input;

  const [focus, setFocus] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [state, setState] = useState({ selectionInProgress: false, touchStartedFrom: null, isSwipe: false });
  const [keepInput, setKeepInput] = useState(null);

  const changeHighLight = direction => {
    const predictions = defaultLocation;
    const currentIndex = highlightedIndex;
    let index = currentIndex;
    if (direction === DIRECTION_UP) {
      index = currentIndex === 0 ? 0 : currentIndex - 1;
    } else if (direction === DIRECTION_DOWN) {
      index = currentIndex + 1;
    }

    if (index < 0)
      index = -1;
    else if (index >= predictions.length) {
      index = predictions.length - 1;
    }

    setHighlightedIndex(index);
  };

  const handleChange = prediction => {
    const { predictionPlace } = prediction;
    const location = {
      selectedPlace: predictionPlace,
      search: prediction.predictionPlace.address
    };
    onChange(location);
    setFocus(false);
    onBlur(prediction.predictionPlace.address);
    keepInput.blur();
    setHighlightedIndex(-1);
  };

  const handlePredictionsSelectStart = touchCoordinates => {
    setState({
      selectionInProgress: true,
      touchStartedFrom: touchCoordinates,
      isSwipe: false,
    });
  };

  const handlePredictionsSelectMove = touchCoordinates => {
    const touchStartedFrom = state.touchStartedFrom;
    const isTouchAction = !!touchStartedFrom;
    const isSwipe = isTouchAction
      ? Math.abs(touchStartedFrom.y - touchCoordinates.y) > TOUCH_TAP_RADIUS
      : false;
    setState({
      ...state,
      selectionInProgress: false, isSwipe
    });
  };

  const handlePredictionsSelectEnd = prediction => {
    setState(
      { selectionInProgress: false, touchStartedFrom: null, isSwipe: false }
    );
    handleChange(prediction);

  };

  const onKeyDown = e => {
    if (e.keyCode === KEY_CODE_ARROW_UP) {
      e.preventDefault();
      changeHighLight(DIRECTION_UP);
    }
    else if (e.keyCode === KEY_CODE_ARROW_DOWN) {
      e.preventDefault();
      changeHighLight(DIRECTION_DOWN);
    } else if (e.keyCode === KEY_CODE_ENTER) {
      const predictions = defaultLocation;
      handleChange(predictions[highlightedIndex]);
    } else if (e.keyCode === KEY_CODE_ESC)
      keepInput.blur();
  };

  const handleFocus = e => {
    onGetAdminProfile();
    setFocus(true);
    onFocus(e);
  };

  const handleBlur = () => {
    setFocus(false);
    keepInput.blur();
  };

  const classes = classNames(rootClassName || css.root, className);
  const inputClass = classNames(inputClassName || css.input);
  const iconClass = classNames(iconClassName || css.icon);

  const { value: inputValue, ...restInputProps } = input;
  const inputProps = value && value.search ? {
    ...input,
    value: value.search
  } : restInputProps;

  return (
    <div className={classes}>
      <div className={iconClass}>
        <IconHourGlass />
      </div>
      <input
        {...inputProps}
        className={inputClass}
        onFocus={handleFocus}
        onBlur={handleBlur}
        readOnly
        onKeyDown={onKeyDown}
        name={name}
        ref={node => {
          setKeepInput(node);
        }}
        placeholder={placeholder}
      />

      {focus && (
        <LocationPredictionsList
          rootClassName={predictionsClassName}
          predictions={defaultLocation}
          attributionClassName={predictionsAttributionClassName}
          highlightedIndex={highlightedIndex}
          onSelectStart={handlePredictionsSelectStart}
          onSelectMove={handlePredictionsSelectMove}
          onSelectEnd={handlePredictionsSelectEnd}
        />
      )}
    </div>
  );
};

SearchDropDown.defaultProps = {
  rootClassName: null,
  className: null
};

SearchDropDown.propTypes = {
  rootClassName: string,
  className: string
};

export default SearchDropDown;
