import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';
import {injectIntl} from 'react-intl';

import css from './AboutPage.css';
import image from '../../assets/background-1440.jpg';

const AboutPage = (props) => {
  const { siteTwitterHandle } = config;
  const { intl } = props;
  // prettier-ignore
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Saunatime',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>{intl.formatMessage({id: 'AboutPage.title'})}</h1>
          <img className={css.coverImage} src={image} alt="My first ice cream." />

          <div className={css.contentWrapper}>
            <div className={css.contentSide}>
              <p>{intl.formatMessage({id: 'AboutPage.leftSideTitle'})}</p>
            </div>

            <div className={css.contentMain}>
              <h2>
                {
                  intl.formatMessage({id: 'AboutPage.bodyTitle'})
                }
              </h2>

              <p>
                {
                  intl.formatMessage({id: 'AboutPage.firstParagraph'})
                }
              </p>

              <p>
                {
                  intl.formatMessage({id: 'AboutPage.secondParagraph'})
                }
              </p>

              <p>
                {
                  intl.formatMessage({id: 'AboutPage.thirdParagraph'})
                }
              </p>
              <p>
                {
                  intl.formatMessage({id: 'AboutPage.fourthParagraph'})
                }
              </p>
              <p>
                {
                  intl.formatMessage({id: 'AboutPage.fivethParagraph'})
                }
              </p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default injectIntl(AboutPage);
