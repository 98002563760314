import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: October 10, 2019</p>
      <p>MountainGuide is an online marketplace that connects skiers and snowboarders with local experts who create customized ski itineraries for them. Please read our Terms of Service below carefully prior to using our marketplace, as it governs your use of MountainGuide.</p>

      <p>If you do not agree to all the terms and conditions outlined below, you may not use our website, marketplace or services.</p>

      <p><strong>Services Agreement</strong></p>

      <p>This Services Agreement (&ldquo;Agreement&rdquo;) is a contract between MountainGuide (&ldquo;Altius Enterprises LLC dba MountainGuide.io&rdquo; or &ldquo;MountainGuide.io&rdquo; or &ldquo;we&rdquo;) and anyone who visits the MountainGuide website or registers an account on MountainGuide.io (&ldquo;you&rdquo; or &ldquo;user&rdquo;). This Agreement describes the terms and conditions that govern your use of the MountainGuide marketplace.</p>

      <p>Throughout this Agreement, the term &ldquo;Service&rdquo; means any part of our service which allows skiers and snowboarders to book phone calls with local experts who send them customized ski itineraries through the MountainGuide website after their phone calls (the &ldquo;Marketplace&rdquo; or &ldquo;Platform&rdquo;). The term Service does not include any services provided by third parties.&nbsp;</p>

      <p>The terms &ldquo;skier&rdquo; (or &ldquo;skiers&rdquo;) refers to anyone who initiates and pays for a booking on MountainGuide. The term &ldquo;expert&rdquo; (or &ldquo;experts&rdquo;) refers to anyone who signs-up as an expert on MountainGuide and receives payment for completed bookings.</p>

      <p>This Agreement is organized into three (3) sections:</p>

      <p><strong>Section 1: General Terms</strong></p>

      <p>This section includes general terms and conditions describing your Account, including creating a user account, making or accepting payments, and how we communicate with you.</p>

      <p><strong>Section 2: Marketplace Rules</strong></p>

      <p>This section describes how you may and may not use the MountainGuide marketplace, and also describes how we use the data collected on the MountainGuide website.</p>

      <p><strong>Section 3: Legal Terms</strong></p>

      <p>This section provides additional legal terms, including our ability to update this Agreement, legal disclaimers and limitations on our liability.</p>

      <p>If you have any questions about this Agreement, please contact us at: <a href="mailto:support@mountainguide.io">support@mountainguide.io</a>. Your use of the MountainGuide website and any other part of our marketplace constitutes your ongoing acceptance of this Agreement.</p>

      <h3><strong>Section 1: General Terms</strong></h3>

      <p className={css.underline} ><ins>Services</ins></p>

      <p>MountainGuide provides users with access to the marketplace through the MountainGuide.io website. Skiers may use the marketplace to search for, and make bookings with MountainGuide local ski experts. Experts may use the marketplace to set-up their expert listing profile and receive payment for completed bookings. Users may also use the marketplace to communicate with other users and to access and edit their account information.</p>

      <p>We are continuously improving the marketplace for all users and reserve the right to make changes at any time, with or without notice. With any change to the marketplace, we try our best to minimize disruption to all users. However, some changes may affect users differently. Users understand and agree that MountainGuide will not be legally or financially liable for any changes that adversely affect how you use the marketplace.</p>

      <p className={css.underline}><ins>Your Account</ins></p>

      <p>In order to make a booking on MountainGuide, users must create an account (&ldquo;Account&rdquo;) and provide us with certain personal information. From time to time, we may need you to update your account or provide additional or different information. This includes requiring additional personal details or payment information.</p>

      <p>To create a MountainGuide account, users must provide a valid email address and password to keep your account secure (&ldquo;Credentials&rdquo;), and you agree to keep your Credentials private and secure. Users are responsible for any actions taken on your Account using your Credentials, whether or not you have authorized those actions.</p>

      <p>You may close your account at any time by emailing us at: <a href="mailto:support@mountainguide.io">support@mountainguide.io</a>. You are responsible for all activity associated with your account made before it is closed, including any payments or fees owed to MountainGuide; or other liabilities caused by or resulting from your use of the marketplace. You understand that we may retain and continue to display and use any public content (including user reviews) provided to us prior to closing your account.</p>

      <p>MountainGuide reserves the right, at our sole discretion, to suspend or terminate your account for any reason we deem necessary.</p>

      <p className={css.underline}><ins>Payment Processing</ins></p>

      <p>MountainGuide payments and payouts (&ldquo;Payment Processing&rdquo;) are processed by Stripe. Users of the MountainGuide marketplace are subject to the Stripe Connected Account Agreement that is part of the Stripe Services Agreement (&ldquo;Stripe Agreement&rdquo;), which may be modified by Stripe from time to time. As a condition of using Payment Processing, you agree to provide accurate and complete information about you and you authorize MountainGuide to share this information with Stripe. All credit card and bank account information is sent directly to, and stored with Stripe using their security protocols. MountainGuide does not store your credit card or bank account information on our systems and therefore we do not bear responsibility for the safety or security of that information.</p>

      <p>MountainGuide reserves the right to change or add other payment processing services to the marketplace at any time, which may be subject to additional terms or conditions.</p>

      <p className={css.underline}><ins>Communication and Notices</ins></p>

      <p>We may communicate with you and provide you information or notices regarding your Account or transactions through regular email or through messaging on the marketplace. You agree to promptly respond to any communications you receive and understand that failure to do so may impact your ability to use the MountainGuide marketplace. You also agree that any email or other notice from MountainGuide is considered accepted by you one day after such email or notice was sent, and will have the same legal effect as if it were physically delivered to you.</p>

      <p>You agree to receive any communications from MountainGuide and transact with us electronically. This acceptance of emails or other electronic messages constitutes your consent and your electronic signature has the same legal effect as physically signing a document. You may withdraw this consent to transact electronically at any point by providing notice to us via email at: <a href="mailto:support@mountainguide.io">support@mountainguide.io</a>. However, given that electronic communication is integral to the marketplace, following any such notice we may elect to terminate your Account.</p>

      <p className={css.underline}><ins>Communication with Other Users</ins></p>

      <p>MountainGuide allows users to communicate with other users without disclosing sensitive personal contact information. It is your responsibility to use good judgment in the information you provide to other users. Users may not use MountainGuide to send messages that are unwanted or unrelated to the marketplace, or to harass other users in any way or attempt to market other products or services, or send spam.</p>

      <p>MountainGuide is not responsible for, and we disclaim all liability resulting from, any losses or harm to you resulting from sharing personal or sensitive information with other users, or from communicating or engaging with users through channels outside the marketplace.</p>

      <h3><strong>Section 2: Marketplace Rules</strong></h3>

      <p className={css.underline}><ins>Ownership, License, and Restrictions</ins></p>

      <p>MountainGuide owns all right, title, and interest in the website, marketplace, and all intellectual property embodied or contained within them (individually and collectively, &ldquo;IP&rdquo;). IP includes all registered or potential patents, copyrights, trademarks, trade names, trade secrets, and other proprietary rights. MountainGuide grants users a limited, temporary, revocable, non-transferable, non-exclusive license to use the website and marketplace for the purposes described in this Agreement. This license does not constitute a transfer of ownership or grant users any additional rights to use the IP. We may suspend or close your Account and pursue legal action against you if we believe or determine that your use of the website, marketplace, or IP exceeds the scope of this grant; or that you are attempting to hack or disrupt the use or normal operation of the website, marketplace, or IP.</p>

      <p className={css.underline}><ins>Content</ins></p>

      <p>(a) Posting Content. You represent and warrant that you are authorized to provide your content to the marketplace and that any content you provide does not violate the proprietary or privacy rights of a third party. You may not upload or present any content that is copyrighted by third parties without their express permission. You grant MountainGuide a worldwide, non-exclusive, perpetual license to use, copy, transmit, distribute, modify, publicly display, and sublicense any content you present on the marketplace. This grant includes our ability to use any content for both internal use (such as analysis to improve the website or marketplace) or external use (such as in marketing or online advertising). If you cannot provide MountainGuide with this grant, you may not upload or publicly present that content on the marketplace. You agree to indemnify, defend and hold MountainGuide harmless for any damages or losses based on third-party claims that your uploaded content violates any proprietary or privacy rights.</p>

      <p>(b) Restrictions on Certain Content. You may never publicly post any Content that (i) is defamatory, obscene, profane, or pornographic; (ii) is abusive, harassing, or disrespectful to other users; (iii) violates applicable Laws, including those prohibiting discrimination, false advertising, privacy, or unlawful marketing; (iv) is intended to deceive or mislead, or is false or inaccurate; (v) contains marketing or promotional content unrelated to the general scope of the marketplace; or (vi) includes sensitive personal information, including payment, contact information, or personal account details.</p>

      <p>(c) DMCA Notices. If you believe that any Content posted violates your copyright, please notify us at: <a href="mailto:support@mountainguide.io">support@mountainguide.io</a>. This notice should identify the specific Content and provide us with evidence of your ownership of the copyright or authorization to enforce the rights of the copyright owner. Any information you provide to us may be shared with other users, third parties, or law enforcement to help assess the claim of infringement or remove infringing content. We will remove any content we determine to be infringing, consistent with our obligations under the Digital Millennium Copyright Act (DMCA).</p>

      <p className={css.underline}><ins>Privacy and Data Usage</ins></p>

      <p>Our Privacy Policy describes our collection, use, storage, and sharing of personal information. This includes personal information included in Content and information collected through use of the marketplace. We may anonymize or aggregate any information, including personal information or Content, and use this information for any purpose including improvement of the website or marketplace, or creating or distributing public marketing collateral.</p>

      <h3><strong>Section 3: Legal Terms</strong></h3>

      <p className={css.underline}><ins>Right to Amend</ins></p>

      <p>We may amend or modify this Agreement at any time by posting the modified Agreement on our website, sending you a copy via email, or otherwise communicating the amendment to you through the marketplace. Your continued use of the website or marketplace after we amend or modify this Agreement constitutes your consent to the revised Agreement.</p>

      <p className={css.underline}><ins>No Assignment</ins></p>

      <p>You may not assign this Agreement or any rights granted to you, including operation or management of your Account, without our prior written consent. Any attempt to do so without our prior consent will be a violation of this Agreement. We may assign this Agreement upon notice to you as part of a sale or transfer of part or all of our business. Any permitted transfer will inure to the benefit of, and bind any successors in interest.</p>

      <p className={css.underline}><ins>Legal Disclaimers</ins></p>

      <p>MOUNTAINGUIDE PROVIDES THE WEBSITE AND MARKETPLACE TO CONNECT SKIERS AND SNOWBOARDERS WITH LOCAL EXPERTS, AND TO COMMUNICATE WITH OTHER USERS. MOUNTAINGUIDE EXPERTS ARE INDEPENDENT THIRD-PARTIES AND ARE NOT AFFILIATED, CONTROLLED BY, OR EMPLOYED BY MOUNTAINGUIDE.</p>

      <p>YOU UNDERSTAND AND AGREE TO USE THE WEBSITE AND MARKETPLACE AT YOUR OWN RISK. MOUNTAINGUIDE IS NOT RESPONSIBLE FOR PERFORMING AND DOES NOT PERFORM BACKGROUND CHECKS ON LOCAL SKI EXPERTS AND DOES NOT GUARANTEE PERFORMANCE OF ANY EXPERT, USER OR THIRD PARTY. ANY INFORMATION PROVIDED TO YOU REGARDING MOUNTAINGUIDE SKI EXPERTS IS ONLY PROVIDED TO FACILITATE YOUR USE OF THE MARKETPLACE AND IS PROVIDED &ldquo;AS IS&rdquo; AND WITHOUT ANY REPRESENTATION OR WARRANTY. MOUNTAINGUIDE DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS AND IMPLIED, THAT ANY INFORMATION PROVIDED THROUGH THE WEBSITE OR MARKETPLACE IS ACCURATE OR COMPLETE, OR THAT ANY SKI EXPERTS WILL BE PROVIDED TO YOU AS DESCRIBED. MOUNTAINGUIDE DISCLAIMS ALL RESPONSIBILITY FOR AND LIABILITY RESULTING FROM THE NEGLIGENCE, INTENTIONAL MISCONDUCT, OR CRIMINAL ACTIVITY OF ALL USERS (SKIERS AND EXPERTS) OR THIRD PARTIES, OR ANY INJURY THAT OCCURS TO YOU WHILE ACCESSING OR USING THE WEBSITE AND MARKETPLACE.</p>

      <p>THE WEBSITE AND MARKETPLACE ARE PROVIDED TO YOU ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS WITHOUT ANY GUARANTEE OR WARRANTY OF ANY KIND, EXPRESSED OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SUITABILITY FOR YOUR PARTICULAR USE, OR NONINFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE DISCLAIM THAT THE WEBSITE AND MARKETPLACE ARE FREE FROM ERRORS OR VIRUSES; THAT THEY WILL NEVER CAUSE HARM; THAT THEY MEET OR ARE SUITABLE FOR YOUR NEEDS OR REQUIREMENTS; THAT THEY ARE ALWAYS AVAILABLE; OR THAT THEY ACCURATELY REPRESENT SKIERS OR EXPERTS. WE EXPRESSLY DISCLAIM ANY OBLIGATION TO CORRECT ERRORS, EVEN THOSE THAT WE ARE AWARE OF, AND MAY CHOOSE TO DISCONTINUE OPERATION OF THE WEBSITE OR MARKETPLACE, OR FEATURES OF EITHER, AT ANY TIME.</p>

      <p className={css.underline}><ins>Limitations of Liability</ins></p>

      <p>UNDER NO CIRCUMSTANCES WILL MOUNTAINGUIDE OR ITS OWNERS, AGENTS, MEMBERS, INFORMATION PROVIDERS, ATTORNEYS, OR AFFILIATES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DAMAGES (WHETHER DIRECT, INDIRECT, GENERAL, COMPENSATORY, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE, EXEMPLARY OR OTHERWISE), INJURY, CLAIM OR LIABILITY OF ANY KIND OR CHARACTER BASED UPON OR ARISING FROM YOUR USE OF OR INABILITY TO USE THE WEBSITE AND MARKETPLACE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. IF YOU ARE DISSATISFIED WITH THE WEBSITE AND MARKETPLACE, OR ANY CONTENT CONTAINED THEREIN, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE WEBSITE AND MARKETPLACE.</p>

      <p>THE LIMITATION OF LIABILITY SET FORTH ABOVE APPLIES TO THE EXTENT PERMITTED BY LAW.</p>

      <p className={css.underline}><ins>Indemnities</ins></p>

      <p>You agree to indemnify, defend and hold MountainGuide and its owner(s), agents, members, information providers, attorneys, or affiliates (collectively, &ldquo;MountainGuide Affiliates&rdquo;) harmless against all third-party claims, liabilities, losses, damages, and related expenses (including reasonable legal expenses) (collectively, &ldquo;Claims&rdquo;) arising from or related to your use of the website and marketplace. This indemnification will survive termination of this Agreement.</p>

      <p className={css.underline}><ins>Representations and Warranties</ins></p>

      <p>By opening your Account, you represent and warrant that (a) you are authorized to use the website and marketplace and have authority to execute this Agreement; (b) that you have only one Account with us and have not had an Account terminated by us previously; (c) all information provided to us is accurate and complete; (d) you will not use the website or marketplace in violation of Law, to commit fraud, to deceive other users, or for any other improper purpose; and (e) you are authorized to provide any Content to us.</p>

      <p className={css.underline}><ins>Entire Agreement</ins></p>

      <p>This Agreement constitutes the entire agreement between you and MountainGuide governing your use of any part of the MountainGuide website and marketplace. This Agreement supersedes all prior understandings or agreements between you and MountainGuide.</p>

    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
