import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import css from './MaterialTimeRange.css';

const formatLabel = l => (l < 10 ? `0${l}` : `${l}`);

const renderChip = ({ isDeleted, isException, isFromSchedule,
  isFromCustom, isUnavailableTime, isNewTime, isNewBlockedTime },
  availableTimeLabel, customSettingLabel, deleted, unavailableTime, newTime, newBlockedTime, defaultTime) => {
  const label = isNewBlockedTime ? newBlockedTime :
    isNewTime ? newTime :
      isDeleted ? deleted :
        isUnavailableTime ? unavailableTime :
          isFromSchedule ? availableTimeLabel :
            (isException || isFromCustom) ? customSettingLabel : defaultTime;
  const chipCss = isDeleted ? css.delete :
    (isUnavailableTime || isNewBlockedTime) ? css.unavailableTime :
      isFromSchedule ? css.schedule : css.custom;
  return (
    <div>
      <span>{label}</span>
    </div>
  );
};

const checkValidAction = (daySlots, currentSlot) => {

  const isNewSlot = typeof currentSlot.type === 'undefined';
  if (isNewSlot)
    return {
      ok: true
    };
  const isException = currentSlot.type === 'availabilityException';
  if (isException)
    return {
      ok: true
    };
  const exceptionSlots = daySlots.filter(slot => {
    return slot.type === 'availabilityException' && slot.attributes.seats === 1;
  });

  for (let slot of exceptionSlots) {
    if (slot.start >= currentSlot.start || slot.end <= currentSlot.end) {
      return {
        ok: false,
        error: {
          messageId: 'MaterialTimeRange.invalidDeletionAvailableTimeInRange'
        }
      };
    }
  }
  return {
    ok: true
  };
};

const MaterialTimeRangeComponent = props => {
  // support 24h
  // const options = [];
  // for (let i = 0; i < 48; i++)
  //   options.push({
  //     value: i * 30,
  //     label: `${formatLabel(Math.floor(i / 2))}:${i % 2 === 0 ? '0' : '3'}0`,
  // });
  // support 12h AM/PM
  const options = Array.apply(null, { length: 48 }).map(Function.call, Number).map((x) => {
    let hour = parseInt(x / 2)
    let prefix = hour >= 12 ? 'pm' : 'am';
    hour = hour > 12 ? hour - 12 : hour;
    let min = x % 2 * 30;
    let label = `${(hour < 10 ? '0' : '') + hour}:${(min < 10 ? '0' : '') + min} ${prefix}`;
    return {
      value: x * 30,
      label: label,
    }
  });

  const {
    intl,
    currentSlot,
    currentDay,
    setInvalidCustomSlotError,
    error } = props;
  const availableTime = intl.formatMessage({
    id: 'AvailabilitiesPage.availableTime',
  });
  const customSetting = intl.formatMessage({
    id: 'AvailabilitiesPage.customSetting',
  });
  const deleted = intl.formatMessage({
    id: 'AvailabilitiesPage.deleted',
  });
  const unavailableTime = intl.formatMessage({
    id: 'AvailabilitiesPage.unavailableTime',
  });
  const newTime = intl.formatMessage({
    id: 'AvailabilitiesPage.newTime',
  });
  const newBlockedTime = intl.formatMessage({
    id: 'AvailabilitiesPage.newBlockedTime',
  });
  const defaultTime = intl.formatMessage({
    id: 'AvailabilitiesPage.defaultTime',
  });

  const startTimeLabel = intl.formatMessage({ id: 'AvailabilitiesPage.startTime' });

  const endTimeLabel = intl.formatMessage({ id: 'AvailabilitiesPage.endTime' });

  const isUnavailableTime = !!(currentSlot.attributes && currentSlot.attributes.seats === 0);

  const isNewTime = typeof currentSlot.type === 'undefined' ? true : false;
  const isNewBlockedTime = isNewTime && currentSlot.isBlocked === true ? true : false;

  return (
    <ListItem className={css.container}>
      <TextField
        id={`start-${props.dayIdx}-${props.slotIdx}`}
        select
        label={startTimeLabel}
        className={css.textField}
        value={props.start}
        onChange={props.handleSlotChange({
          isStart: true,
        })}
        SelectProps={{
          MenuProps: {},
        }}
        margin="none"
        disabled={props.isFromSchedule || props.isFromCustom}
      >
        {options.map(o => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        id={`end-${props.dayIdx}-${props.slotIdx}`}
        select
        label={endTimeLabel}
        className={css.textField}
        value={props.end}
        onChange={props.handleSlotChange({
          isStart: false,
        })}
        SelectProps={{
          MenuProps: {},
        }}
        margin="none"
        disabled={props.isFromSchedule || props.isFromCustom}
      >
        {options.map(o => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </TextField>

      {renderChip({
        isDeleted: props.deleted,
        isException: props.isException,
        isFromCustom: props.isFromCustom,
        isFromSchedule: props.isFromSchedule,
        isUnavailableTime: isUnavailableTime,
        isNewTime: isNewTime,
        isNewBlockedTime: isNewBlockedTime
      }, availableTime, customSetting, deleted, unavailableTime, newTime, newBlockedTime, defaultTime)}

      {!props.isFromSchedule ? (
        <ListItemSecondaryAction>
          <IconButton onClick={() => {
            const checkAction = checkValidAction(currentDay.slots, currentSlot);
            setInvalidCustomSlotError(null);
            if (!checkAction.ok) {
              setInvalidCustomSlotError(checkAction.error);
            } else {
              props.deleteSlot();
            }
          }} aria-label="Delete time">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      ) : null}

    </ListItem>
  );
};

export default MaterialTimeRangeComponent;
