import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { propTypes, DRAFT_LISTING_INITIAL_TITLE } from '../../util/types';
import classNames from 'classnames';
import { Form, Button, FieldTextInput } from '../../components';
import { required } from '../../util/validators';

import css from './EditListingDescriptionForm.css';
import { ensureListing } from '../../util/data';

const MAX_TITLE_LENGTH = 250;
const MAX_DESCRIPTION_LENGTH = 1500;
const MAX_OTHER_LENGTH = 1500;

export const EditListingDescriptionFormComponent = ({ listing, ...props }) => {
  const ensuredListing = ensureListing(listing);
  console.log(
    'LOG: : ----------------------------------------------------------------------------'
  );
  console.log('LOG: : EditListingDescriptionFormComponent -> ensuredListing', ensuredListing);
  console.log(
    'LOG: : ----------------------------------------------------------------------------'
  );
  const { title, description, publicData } = ensuredListing.attributes;
  const initialTitle = title === DRAFT_LISTING_INITIAL_TITLE ? null : title;
  return (
    <FinalForm
      {...props}
      initialValues={{
        title: initialTitle,
        description,
        aboutBeingSkiGuide: (publicData.others && publicData.others.aboutBeingSkiGuide) || null,
        aGreatSkiGuide: (publicData.others && publicData.others.aGreatSkiGuide) || null,
      }}
      render={fieldRenderProps => {
        const {
          className,
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          submitted,
          inProgress,
          fetchErrors,
        } = fieldRenderProps;

        const { error } = fetchErrors || {};
        const errorMessage = error ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = submitted && pristine;
        const submitDisabled = invalid || disabled || inProgress;

        const listingTitleLabel = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
        const listingTitlePlaceholder = intl.formatMessage({
          id: 'EditListingDescriptionForm.titlePlaceholder',
        });
        const listingTitleRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titleRequired',
        });

        const detailDescriptionLabel = intl.formatMessage({
          id: 'EditListingDescriptionForm.detailDescriptionLabel',
        });
        const detailDescriptionPlaceholder = intl.formatMessage({
          id: 'EditListingDescriptionForm.detailDescriptionPlaceholder',
        });
        const detailDescriptionRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.detailDescriptionRequiredMessage',
        });

        const aboutBeingSkiGuideLabel = intl.formatMessage({
          id: 'EditListingDescriptionForm.aboutBeingSkiGuideLabel',
        });

        const aGreatSkiGuideLabel = intl.formatMessage({
          id: 'EditListingDescriptionForm.aGreatSkiGuideLabel',
        });
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}

            <FieldTextInput
              id="title"
              name="title"
              label={listingTitleLabel}
              placeholder={listingTitlePlaceholder}
              validate={required(listingTitleRequiredMessage)}
              type="textarea"
              maxLength={MAX_TITLE_LENGTH}
              className={css.field}
            />

            <FieldTextInput
              type="textarea"
              name="description"
              id="description"
              label={detailDescriptionLabel}
              placeholder={detailDescriptionPlaceholder}
              validate={required(detailDescriptionRequiredMessage)}
              rows={3}
              maxLength={MAX_DESCRIPTION_LENGTH}
              className={css.field}
            />

            <FieldTextInput
              type="textarea"
              name="aboutBeingSkiGuide"
              id="description.aboutBeingSkiGuide"
              label={aboutBeingSkiGuideLabel}
              maxLength={MAX_OTHER_LENGTH}
              className={css.field}
            />

            <FieldTextInput
              type="textarea"
              name="aGreatSkiGuide"
              id="description.aGreatSkiGuide"
              label={aGreatSkiGuideLabel}
              maxLength={MAX_OTHER_LENGTH}
              className={css.field}
            />

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={inProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingDescriptionFormComponent.defaultProps = {
  fetchErrors: null,
};

EditListingDescriptionFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  submitted: bool.isRequired,
  inProgress: bool.isRequired,
  fetchErrors: shape({
    error: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
