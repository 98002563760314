import React, {useEffect} from 'react';
import { Page } from '../../components';
import { injectIntl } from 'react-intl';
import { getInformationFromCode } from './RefCodeMiddlePage.duck';
import { compose } from 'redux';
import {connect} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { routeConfiguration } from '../../index';
import { withRouter } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';

const RefCodeMiddlePageComponent = ({intl, params, getInformationFromCode, history}) => {
  const title = intl.formatMessage({id: 'RefCodeMiddlePage.title'});

  useEffect(() => {
    const {refCode} = params;
    const routes = routeConfiguration();
    getInformationFromCode(refCode)
      .then(res => {
        if(isEmpty(res)){
          history.push(createResourceLocatorString('LandingPage', routes, {}));
        } else {
          const { listingLink } = res;
          history.replace(listingLink)
        }
      })
  }, []);

  return (
    <Page
      title={title}
    >

    </Page>
  )
};

const mapDispatchToProps = ({
  getInformationFromCode: code => getInformationFromCode(code)
});

const RefCodeMiddlePage = compose(withRouter, connect(null, mapDispatchToProps), injectIntl)(RefCodeMiddlePageComponent);

export default RefCodeMiddlePage;
