import React from 'react';
import { FormattedMessage } from 'react-intl';
import { InlineTextButton, ReviewRating } from '../../components';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import config from '../../config';

import css from './ListingPage.css';

const calculateAvgRating = (reviews = []) => {
  const length = reviews.length ? reviews.length : 1;
  const totalRating = reviews.reduce((prev, cur) => prev += cur.attributes.rating, 0);
  const avg = Math.floor(totalRating / length);
  return avg;

};

const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    richTitle,
    category,
    hostLink,
    showContactUser,
    onContactUser,
    reviews
  } = props;

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingPage.perNight'
    : isDaily
      ? 'ListingPage.perDay'
      : 'ListingPage.perUnit';

  return (
    <div className={css.sectionHeading}>
      <div className={css.desktopPriceContainer}>
        <div className={css.desktopPriceValue} title={priceTitle}>
          {formattedPrice}
        </div>
        <div className={css.desktopPerUnit}>
          <FormattedMessage id={unitTranslationKey} />
        </div>
      </div>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
          <ReviewRating rating={calculateAvgRating(reviews)} />
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
