import moment from 'moment';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { getCurrentDateStringInDesiredTimezone } from '../../util/dates';
import { DEFAULT_TIME_ZONE } from '../../util/types';

const { UUID } = sdkTypes;

const rawDays = config.locale === 'en'
  ?
  [
    { short: 'sun', long: 'Sunday' },
    { short: 'mon', long: 'Monday' },
    { short: 'tue', long: 'Tuesday' },
    { short: 'wed', long: 'Wednesday' },
    { short: 'thu', long: 'Thursday' },
    { short: 'fri', long: 'Friday' },
    { short: 'sat', long: 'Saturday' },
  ]
  :
  [
    { short: 'sun', long: 'Dimanche' },
    { short: 'mon', long: 'Lundi' },
    { short: 'tue', long: 'Mardi' },
    { short: 'wed', long: 'Mercredi' },
    { short: 'thu', long: 'Jeudi' },
    { short: 'fri', long: 'Vendredi' },
    { short: 'sat', long: 'Samedi' },
  ];

export const DAYS = rawDays.map((d, i) => ({
  ...d,
  dayIdx: i,
  isOpen: false,
  isChecked: false,
  slots: [],
}));

const dateToSlotTime = (d, tz = DEFAULT_TIME_ZONE) => {
  const hourOption = {
    hour: 'numeric',
    timeZone: tz,
    hourCycle: 'h24',
    hour12: false
  };
  const minuteOption = {
    minute: 'numeric',
    timeZone: tz,
    hourCycle: 'h24',
    hour12: false
  };
  const hourFormater = new Intl.DateTimeFormat('default', hourOption);
  const minuteFormater = new Intl.DateTimeFormat('default', minuteOption);

  return parseInt(minuteFormater.format(d), 10) + parseInt(hourFormater.format(d), 10) * 60;
};

export const formatLabel = l => (l < 10 ? `0${l}` : `${l}`);
export const formatTime = t => `${formatLabel(Math.floor(t / 60))}:${formatLabel(t % 60)}`;

export const flexScheduleToRedux = ({ scheduleSlots }) => {
  const slotsMap = scheduleSlots
    .map(x => ({
      ...x,
      dayIdx: x.attributes.start.getDay(),
      start: x.attributes.start.getHours() * 60 + x.attributes.start.getMinutes(),
      end: x.attributes.end.getHours() * 60 + x.attributes.end.getMinutes(),
    }))
    .reduce((acc, curr) => {
      if (!acc[curr.dayIdx]) acc[curr.dayIdx] = [];
      curr.attributes.seats = 1;
      acc[curr.dayIdx].push(curr);
      return acc;
    }, {});

  return DAYS.map((d, idx) => ({
    ...d,
    isOpen: false,
    isChecked: idx in slotsMap,
    slots: slotsMap[idx] || [],
  }));
};

export const flexDefaulScheduleToRedux = (entries) => {
  const availableDayTimes = [...entries];

  return DAYS.map(day => {
    const availableTimeInDay = availableDayTimes.filter(time => {
      return time.dayOfWeek === day.short;
    })
    const slots = availableTimeInDay.map(time => {

      const startTime = time.startTime.split(':');
      const startHour = parseInt(startTime[0], 10);
      const startMinute = parseInt(startTime[1], 10);
      const start = startHour * 60 + startMinute;

      const endTime = time.endTime.split(':');
      const endHour = parseInt(endTime[0], 10);
      const endMinute = parseInt(endTime[1], 10);
      const end = endHour * 60 + endMinute;
      return {
        dayIdx: day.dayIdx,
        type: 'timeSlot',
        start: start,
        end: end
      };
    })
    return {
      ...day,
      isChecked: slots.length > 0,
      slots: slots
    }
  });
}

export const getListingId = ({ sdk }) =>
  sdk.ownListings
    .query({})
    .then(res => {
      if (!res.data || !res.data.data || res.data.data.length === 0) {
        return Promise.reject(new Error('No listings found'));
      } else {
        const mountainListing = res.data.data[0];
        if (!mountainListing)
          return Promise.reject(new Error('Cannot find trainer listing'));
        return mountainListing.id.uuid;
      }
    }
    );


const createDefaultSchedule = () => {
  return [
    {
      dayOfWeek: "sun",
      endTime: "23:30",
      seats: 1,
      startTime: "00:00"
    },
    {
      dayOfWeek: "mon",
      endTime: "23:30",
      seats: 1,
      startTime: "00:00"
    },
    {
      dayOfWeek: "tue",
      endTime: "23:30",
      seats: 1,
      startTime: "00:00"
    },
    {
      dayOfWeek: "wed",
      endTime: "23:30",
      seats: 1,
      startTime: "00:00"
    },
    {
      dayOfWeek: "thu",
      endTime: "23:30",
      seats: 1,
      startTime: "00:00"
    },
    {
      dayOfWeek: "fri",
      endTime: "23:30",
      seats: 1,
      startTime: "00:00"
    },
    {
      dayOfWeek: "sat",
      endTime: "23:30",
      seats: 1,
      startTime: "00:00"
    },
  ];
}


export const getDefaultSchedule = ({ sdk, listingId, timezone }) => {

  return sdk.ownListings
    .show({ id: new UUID(listingId) })
    .then(res => {
      const havePlan = res.data.data.attributes.availabilityPlan ? true : false;
      const type = havePlan ? res.data.data.attributes.availabilityPlan.type : null;
      if (type === "availability-plan/day" || !havePlan) {
        return sdk.ownListings.update({
          id: listingId,
          availabilityPlan: {
            type: 'availability-plan/time',
            timezone: timezone,
            entries: createDefaultSchedule(),
          },
        })
          .then(res => {
            if (res.status > 199 && res.status < 300) {
              sdk.ownListings
                .show({ id: new UUID(listingId) })
                .then(res => {
                  return flexDefaulScheduleToRedux(res.data.data.attributes.availabilityPlan.entries);
                })
            }
          })
      }
      const defaultAvailability = res.data.data.attributes.availabilityPlan.entries;
      return flexDefaulScheduleToRedux(defaultAvailability);
    });
};

export const getAvailabilityExceptions = ({ sdk, listingId, selectedDate, timezone }) => {
  const start = getCurrentDateStringInDesiredTimezone({
    date: moment(selectedDate)
      .startOf('day')
      .toDate(),
    timezone
  });
  const end = getCurrentDateStringInDesiredTimezone({
    date: moment(selectedDate)
      .endOf('day')
      .toDate(),
    timezone
  });

  return sdk.availabilityExceptions
    .query({
      listingId: new UUID(listingId),
      start: start,
      end: end,
    })
    .then(res => res.data.data)
    .then(availabilityException => {
      return sdk.timeslots
        .query({
          listingId: new UUID(listingId),
          start,
          end,
        })
        .then(res => {
          const timezoneAvailableTime = res.data.data;
          return { timezoneAvailableTime, availabilityException };
        })
    })
    .then(({ timezoneAvailableTime, availabilityException }) => {
      const availability = [...timezoneAvailableTime, ...availabilityException];
      return availability;
    });
}

export const sortTimeSlot = (days) => {
  return days.map(day => {
    return {
      ...day,
      slots: day.slots
        .sort((a, b) => a.start - b.start)
        .sort((a, b) => a.type > b.type ? 1 : -1)
    }
  });
}

export const getFormattedCustomSlotsForADay = ({ availability, timezone, selectedDate }) => {
  const slots = availability.map(x => {
    return {
      ...x,
      dayIdx: selectedDate.getDay(),
      start: dateToSlotTime(x.attributes.start),
      end: dateToSlotTime(x.attributes.end),
      id: x.id.uuid,
      isFromCustom: true,
      isFromSchedule: x.type === 'timeSlot' ? true : false,
      isAvailability: x.type === 'availabilityException' ? true : false
    };
  });

  return Array(7)
    .fill(0)
    .map((_, idx) => ({ ...DAYS[idx], isOpen: true, isChecked: true }))
    .map((x, idx) => ({ ...x, slots: slots.filter(y => y.dayIdx === idx) }));
};

export const reduxScheduleToFlex = ({ scheduleSlots }) =>
  scheduleSlots
    .map((s, idx) =>
      s.slots.map(x => ({
        dayOfWeek: DAYS[idx].short,
        seats: s.isChecked ? 1 : 0,
        startTime: formatTime(x.start),
        endTime: formatTime(x.end),
      }))
    )
    .flat();

export const sdkUpdateSchedule = ({ sdk, listingId, scheduleSlots, timezone }) =>
  sdk.ownListings.update({
    id: new UUID(listingId),
    availabilityPlan: {
      type: 'availability-plan/time',
      timezone: timezone,
      entries: reduxScheduleToFlex({ scheduleSlots }),
    },
    publicData: {
      updatedAvalability: true,
    }
  });


export const sdkCreateSlotException = ({ sdk, listingId, start, end, isUnavailabilityMode }) =>
  sdk.availabilityExceptions
    .create(
      {
        listingId: new UUID(listingId),
        start,
        end,
        seats: isUnavailabilityMode ? 0 : 1,
      },
      {
        expand: true,
      }
    )
    .then(res => res.data.data);

export const sdkDeleteCustom = ({ sdk, exceptionId }) =>
  sdk.availabilityExceptions
    .delete(
      {
        id: new UUID(exceptionId),
      },
      {
        expand: false,
      }
    )
    .then(res => res.data.data);

export const formatCustomSlotTime = ({ selectedDate, t }) =>
  moment(selectedDate)
    .startOf('day')
    .add(Math.floor(t / 60), 'hours')
    .add(t % 60, 'minutes')
    .toDate();
