import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, Button, FieldCheckbox } from '../../components';

import css from './EditListingAgreementForm.css';
import { ensureOwnListing } from '../../util/data';

export const EditListingAgreementFormComponent = ({ listing, ...rest }) => {
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const initialValues = {
    agreeTermsAndRequirements: publicData.agreeTermsAndRequirements,
  };

  const isUserAgree =
    publicData.agreeTermsAndRequirements && publicData.agreeTermsAndRequirements.length > 0;

  return (
    <FinalForm
      {...rest}
      initialValues={initialValues}
      render={fieldRenderProps => {
        const {
          className,
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          submitted,
          inProgress,
          fetchErrors,
        } = fieldRenderProps;

        const { error } = fetchErrors || {};
        const errorMessage = error ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingAgreementForm.updateFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = submitted && pristine;
        const submitDisabled = invalid || disabled || inProgress || isUserAgree;

        const label = intl.formatMessage({ id: 'EditListingAgreementForm.agreeTermsLabel' });
        const requiredMessage = intl.formatMessage({
          id: 'EditListingAgreementForm.requiredMessage',
        });
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.agreement}>
              <p className={css.paragraph}>
                MountainGuide is a marketplace that connects skiers and snowboarders with local ski
                experts who create customized ski itineraries to help them maximize their enjoyment
                on the mountain.
              </p>
              <p className={css.paragraph}>
                Skiers book 30-minute calls with MountainGuide experts to discuss when they will be
                skiing, their skill level, familiarity with the mountain and the types of runs and
                terrain they’d like to explore. Based on that information and other factors such as
                the weather forecast and current snow conditions, experts then fill-out the
                MountainGuide itinerary form with a day's worth of suggested runs, lifts, and notes
                and emails it to the skiers within 1-hour after their call.
              </p>
              <p className={css.paragraph}>
                MountainGuide is also a great new way for local ski experts to monetize their
                mountain knowledge. MountainGuide experts earn $30 per booking. It takes less than
                1-hour to complete a call and send an itinerary form so you will earn at least $30
                per hour working remotely in your spare time.
              </p>
              <p className={css.paragraph}>
                In order to be a successful MountainGuide expert, your calls and itineraries need to
                deliver meaningful value to skiers. If skiers don’t enjoy their calls with you or
                don’t find your itineraries to be valuable, they’re not going to leave positive
                reviews and you won’t receive many bookings in the future.
              </p>
              <p className={css.paragraph}>
                Therefore, we ask anyone considering signing up as a MountainGuide expert to first
                read the requirements listed below. If you meet all the requirements and you’re
                passionate about providing a valuable service for skiers, we invite you to set-up
                your MountainGuide expert listing so skiers can find you on MountainGuide and start
                booking calls with you.
              </p>
            </div>
            <div className={css.title}>Expert Requirements</div>
            <ul className={css.requirements}>
              <li className={classNames(css.requirement, css.one)}>
                You truly know your ski resort like the back of your hand. Greens, blues, blacks and
                double-blacks. Groomers, bumps, powder, trees, steep terrain, and everything in
                between. All of it. Skiers will be calling you for all kinds of recommendations and
                to provide them with a valuable service, you really need to know your mountain
                inside and out.
              </li>
              <br />
              <li className={classNames(css.requirement, css.two)}>
                You love working with all kinds of people and are genuinely excited about the
                prospect of sharing your mountain knowledge with skiers and/or snowboarders of all
                levels and providing a great service to help them explore the best parts of the
                mountain.
              </li>
              <br />
              <li className={classNames(css.requirement, css.three)}>
                You agree to keep your schedule of availability updated within your MountainGuide
                listing. Skiers don’t want to spend the time booking a call with you and then have
                it cancelled because you weren’t actually available at that time. Experts who cancel
                multiple bookings after they are confirmed may be removed from the MountainGuide
                marketplace.
              </li>
              <br />
              <li className={classNames(css.requirement, css.four)}>
                You understand and agree that in order to receive payouts for your bookings, you
                must email your completed itinerary forms to skiers within 1-hour of your 30-minute
                calls. If you do not send the itinerary within 1-hour, the booking will be cancelled
                and you will not receive your payment.
              </li>
              <br />
              <li className={classNames(css.requirement, css.five)}>
                You agree to adhere to the MountainGuide Terms of Service and all other expert
                requirements outlined on MountainGuide.io. Experts who violate any of our Terms of
                Service or any other expert requirements may be removed from the MountainGuide
                marketplace. MountainGuide reserves the right to update our Terms of Service and the
                requirements to sign-up as a MountainGuide expert at any time.
              </li>
              <br />
              <li className={classNames(css.requirement, css.six)}>
                You agree to help spread the word about the MountainGuide service to skiers and
                boarders you meet on the mountain and around town. The more people you tell about
                MountainGuide the faster word will spread and the more bookings you will receive.
              </li>
            </ul>

            <FieldCheckbox
              id="agreeTermsAndRequirements"
              name="agreeTermsAndRequirements"
              label={label}
              labelClassname={css.label}
              value="agree"
              validate={validators.required(requiredMessage)}
              disabled={isUserAgree}
            />

            {errorMessage}

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={inProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingAgreementFormComponent.defaultProps = {
  fetchErrors: null,
};

EditListingAgreementFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  submitted: bool.isRequired,
  inProgress: bool.isRequired,
  fetchErrors: shape({
    error: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingAgreementFormComponent);
