import { DEFAULT_TIME_ZONE } from '../../util/types';
import config from '../../config';
import { getCurrentDateStringInDesiredTimezone } from '../../util/dates';

const getAvailableTimeRangeFromDate = (date, timeSlots = []) => {
  return (timeSlots || []).filter(slot => {
    return sameDayTimeSlot(new Date(date), new Date(slot.attributes.start)) === 1 ? true : false;
  });
};

export const sameDay = (d1, d2) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

export const getTimeValue = selectedTime => {
  const slot = config.custom.times.find(item => item.label === selectedTime);
  return slot ? slot.key : 0;
};

export const sameDayTimeSlot = (localTime, timeslot, timezone = DEFAULT_TIME_ZONE) => {
  const convertedDay = getCurrentDateStringInDesiredTimezone({
    date: localTime,
    timezone: timezone,
  });
  const allFormat = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: timezone,
  };
  const formatter = new Intl.DateTimeFormat('default', allFormat);
  return formatter.format(convertedDay) === formatter.format(timeslot) ? 1 : -1;
};

export const sameDayTimezone = (localTime, timeslot, timezone = DEFAULT_TIME_ZONE) => {
  const allFormat = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: timezone,
  };
  const formatter = new Intl.DateTimeFormat('default', allFormat);
  return formatter.format(localTime) === formatter.format(timeslot) ? true : false;
};

const hourOption = {
  hour: 'numeric',
  timeZone: DEFAULT_TIME_ZONE,
  hour12: false,
  hourCycle: 'h24',
};
const minuteOption = {
  minute: 'numeric',
  timeZone: DEFAULT_TIME_ZONE,
  hour12: false,
  hourCycle: 'h24',
};
const hourFormater = new Intl.DateTimeFormat('default', hourOption);
const minuteFormater = new Intl.DateTimeFormat('default', minuteOption);

const getAvailableHoursPair = (begin, end) => {
  return {
    start: parseInt(hourFormater.format(new Date(begin)), 10),
    startMinutes: parseInt(minuteFormater.format(new Date(begin)), 10),
    end: parseInt(hourFormater.format(new Date(end)), 10),
    endMinutes: parseInt(minuteFormater.format(new Date(end)), 10),
  };
};

const getAvailableHours = (timeSlots, timezone = DEFAULT_TIME_ZONE) => {
  return timeSlots.map(slot => {
    return getAvailableHoursPair(slot.attributes.start, slot.attributes.end, timezone);
  });
};

const createTimeRange = timeSlots => {
  let selectTimeRanges = [];
  let i = 0;
  for (let slot of timeSlots) {
    let start = slot.start;
    let end = slot.end;
    let startMinutes = slot.startMinutes;
    let endMinutes = slot.endMinutes;
    let selectTimeRange = createSelectTimeRange(start, end, i, startMinutes, endMinutes);
    selectTimeRanges = selectTimeRanges.concat(selectTimeRange);
    i++;
  }
  return selectTimeRanges;
};

export const createTimeRangeSelection = (date, timeSlots, timezone = DEFAULT_TIME_ZONE) => {
  const activeTimeSlotsInDay = getAvailableTimeRangeFromDate(date, timeSlots);
  const availabeHoursFromTimeSlots = getAvailableHours(activeTimeSlotsInDay);
  const hourSlots = createTimeRange(availabeHoursFromTimeSlots);

  const currentTime = new Date();
  const isSameDay =
    activeTimeSlotsInDay[0] && activeTimeSlotsInDay[0].attributes
      ? sameDayTimezone(currentTime, activeTimeSlotsInDay[0].attributes.start)
      : false;

  if (isSameDay) {
    const hourFormat = {
      hour: 'numeric',
      timeZone: timezone,
      hourCycle: 'h24',
      hour12: false,
    };
    const minuteFormat = {
      minute: 'numeric',
      timeZone: timezone,
      hourCycle: 'h24',
      hour12: false,
    };

    const hourFormatter = new Intl.DateTimeFormat('default', hourFormat);
    const minuteFormatter = new Intl.DateTimeFormat('default', minuteFormat);

    const newCurrentTime = new Date(new Date().getTime() + 30 * 60 * 1000);
    const rawHourSlotToRemove = isSameDay
      ? createSelectTimeRange(
          0,
          parseInt(hourFormatter.format(newCurrentTime), 10),
          0,
          0,
          parseInt(minuteFormatter.format(newCurrentTime), 10) >= 30 ? 30 : 0
        )
      : [];

    const hourSlotToRemove = rawHourSlotToRemove.map(slot => {
      return slot.key;
    });

    const result = hourSlots.filter((slot, index, arr) => {
      return !hourSlotToRemove.includes(slot.key);
    });

    return result.filter((item, index) => {
      return item.session === (result[index + 1] || {}).session;
    });
  }

  // explain: remove the last time slots of each session
  return hourSlots
    .filter((item, index) => {
      return item.session === (hourSlots[index + 1] || {}).session;
    })
    .filter((item, index, arr) => {
      return !item.session || item.session === (arr[index - 1] || {}).session;
    });
};

const recursionSingleStartTimeFilter = (ranges, times) => {
  const filtered = ranges.filter((time, i) => {
    const nextTime = ranges[i + 1];
    if (!nextTime) return false;
    const timeAfterNextTime = ranges[i + 2];
    if (!timeAfterNextTime) return false;
    const isSameSession = time.session === nextTime.session;
    if (!isSameSession) return false;
    const isDiscontinueTime = nextTime.sessionInternalIndex - time.sessionInternalIndex > 1;
    if (isDiscontinueTime) return false;
    const isSameSessionForTimeAfterNextTime = time.session === timeAfterNextTime.session;
    if (!isSameSessionForTimeAfterNextTime) return false;
    const doesNotHaveSelectableTime =
      timeAfterNextTime.sessionInternalIndex - nextTime.sessionInternalIndex != 1;
    if (doesNotHaveSelectableTime) return false;
    return true;
  });
  if (times === 0) return filtered;
  else return recursionSingleStartTimeFilter(filtered, times - 1);
};

export const filterSingleStartTime = timeRanges => {
  return recursionSingleStartTimeFilter(timeRanges, 0);
};

export const filterDiscontinueSelectionTime = (
  beginIndex,
  selectionTimes,
  selectedSlotsInSession
) => {
  let i = beginIndex + 2;
  let timeWithoutSelectedTimeslots = [...selectionTimes];
  for (let i = 0; i < selectedSlotsInSession.length; i++) {
    if (selectedSlotsInSession[i].startIndex === beginIndex) {
      return [];
    }
    timeWithoutSelectedTimeslots = timeWithoutSelectedTimeslots.filter(s => {
      const ignoreSelectedSlot = beginIndex >= selectedSlotsInSession[i].endIndex;
      return ignoreSelectedSlot
        ? true
        : s.sessionInternalIndex <= selectedSlotsInSession[i].startIndex ||
            s.sessionInternalIndex < selectedSlotsInSession[i].endIndex;
    });
  }
  const timeWithoutDiscontinueSelectionTime = timeWithoutSelectedTimeslots.filter(time => {
    let result = false;
    if (time.sessionInternalIndex === i) {
      result = true;
    } else {
      result = false;
    }
    i = i + 2;
    return result;
  });

  return timeWithoutDiscontinueSelectionTime;
};

const createSelectTimeRange = (begin, end, session, startMinutes = 0, endMinutes = 0) => {
  let ranges = [];
  let j = 0;
  let stopCondition = endMinutes === 0 ? end + 0.5 : end + 1;
  let beginIndex = startMinutes === 0 ? begin : begin + 0.5;
  for (let i = beginIndex; i < stopCondition; i = i + 0.5) {
    let hour = i;
    let isHalfTime = i % 1 === 0.5;
    let prefix = hour >= 12 ? 'pm' : 'am';
    if (isHalfTime) {
      hour = hour - 0.5;
    }
    hour = hour > 12 ? hour - 12 : hour;
    let value = `${(hour < 10 ? '0' : '') + hour}:${!isHalfTime ? '00' : '30'} ${prefix}`;
    let range = {
      key: value,
      label: value,
      hour: hour,
      minutes: isHalfTime ? 30 : 0,
      session: session,
      sessionInternalIndex: j,
    };
    ranges.push(range);
    j++;
  }
  return ranges;
};
