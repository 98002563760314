import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PropertyGroup, PropertyGroupWithoutCheck } from '../../components';

import css from './ListingPage.css';
import config from '../../config';

const SectionFeaturesMaybe = props => {
  const { options, publicData } = props;
  if (!publicData) {
    return null;
  }

  const selectedMountains = publicData && publicData.whereAreYouAnExpert ? publicData.whereAreYouAnExpert : [];
  const skiTypes = publicData && publicData.skiType ? publicData.skiType : [];
  const skiLevel = publicData && publicData.skiLevel ? publicData.skiLevel : [];
  const runDifficulty = publicData && publicData.runDifficulty ? publicData.runDifficulty : [];
  const skiSpecialties = publicData && publicData.skiSpecialties ? publicData.skiSpecialties : [];
  const skiKnowledgable = publicData && publicData.skiKnowledgable ? publicData.skiKnowledgable : [];
  return (
    <React.Fragment>
      <div className={css.sectionFeatures}>
        <h2 className={css.featuresTitle}>
          <FormattedMessage id='ListingPage.mountains' />
        </h2>
        <PropertyGroupWithoutCheck
          id="ListingPage.mountains"
          options={config.custom.whereAreYouExpert}
          selectedOptions={Array.isArray(selectedMountains) ? selectedMountains : [selectedMountains]}
          twoColumns={true}
        />
      </div>
      <div className={css.sectionFeatures}>
        <h2 className={css.featuresTitle}>
          <FormattedMessage id="ListingPage.skiTypes" />
        </h2>
        <PropertyGroup
          id="ListingPage.skiTypes"
          options={config.custom.skiType}
          selectedOptions={Array.isArray(skiTypes) ? skiTypes : [skiTypes]}
          twoColumns={true}
        />
      </div>
      <div className={css.sectionFeatures}>
        <h2 className={css.featuresTitle}>
          <FormattedMessage id="ListingPage.skillLevel" />
        </h2>
        <PropertyGroup
          id="ListingPage.skiLevel"
          options={config.custom.skiLevel}
          selectedOptions={Array.isArray(skiLevel) ? skiLevel : [skiLevel]}
          twoColumns={true}
        />
      </div>
      <div className={css.sectionFeatures}>
        <h2 className={css.featuresTitle}>
          <FormattedMessage id="ListingPage.runDifficulty" />
        </h2>
        <PropertyGroup
          id="ListingPage.runDifficulty"
          options={config.custom.runDifficulty}
          selectedOptions={Array.isArray(runDifficulty) ? runDifficulty : [runDifficulty]}
          twoColumns={true}
        />
      </div>
      <div className={css.sectionFeatures}>
        <h2 className={css.featuresTitle}>
          <FormattedMessage id="ListingPage.terrain" />
        </h2>
        <PropertyGroup
          id="ListingPage.terrain"
          options={config.custom.skiSpecialties}
          selectedOptions={Array.isArray(skiSpecialties) ? skiSpecialties : [skiSpecialties]}
          twoColumns={true}
        />
      </div>
      <div className={css.sectionFeatures}>
        <h2 className={css.featuresTitle}>
          <FormattedMessage id="ListingPage.knowled" />
        </h2>
        <PropertyGroup
          id="ListingPage.knowled"
          options={config.custom.knowledgable}
          selectedOptions={Array.isArray(skiKnowledgable) ? skiKnowledgable : [skiKnowledgable]}
          twoColumns={true}
        />
      </div>
    </React.Fragment>

  );
};

export default SectionFeaturesMaybe;
