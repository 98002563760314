/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../components';

import css from './TopbarMobileMenu.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    userPortfolioId,
    portfolioSlug
  } = props;

  const user = ensureCurrentUser(currentUser);

  if (!isAuthenticated) {
    return (
      <div className={css.root}>
        <div className={classNames(css.content, css.linkNotAuth)}>
          <NamedLink name='LoginPage' className={css.navigationLink}>
            <FormattedMessage id='TopbarMobileMenu.loginLink' />
          </NamedLink>
          <NamedLink name='SignupPage' className={css.navigationLink}>
            <FormattedMessage id='TopbarMobileMenu.singupLink' />
          </NamedLink>
          <NamedLink name='NewListingPage' className={css.navigationLink}>
            <FormattedMessage id='TopbarMobileMenu.createListingLink' />
          </NamedLink>
          <NamedLink name='AboutPage' className={css.navigationLink}>
            <FormattedMessage id='TopbarMobileMenu.aboutUsLink' />
          </NamedLink>
          <NamedLink name='FAQ' className={css.navigationLink}>
            <FormattedMessage id='TopbarMobileMenu.faqLink' />
          </NamedLink>
          <NamedLink name='ContactUs' className={css.navigationLink}>
            <FormattedMessage id='TopbarMobileMenu.contactLink' />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        {
          !userPortfolioId ? (
            <NamedLink className={css.navigationLink} name="NewListingPage">
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.createListing" />
              </span>
            </NamedLink>
          ) : (
              <NamedLink
                className={css.navigationLink}
                name={currentUserHasListings ? "ListingPage" : "EditListingPage"}
                params={{ id: userPortfolioId, slug: portfolioSlug, type: 'draft', tab: 'description' }}
              >
                <span className={css.navigationLink}>
                  <FormattedMessage id="TopbarDesktop.goToPortfolio" />
                </span>
              </NamedLink>
            )
        }
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <NamedLink name='AboutPage' className={css.navigationLink}>
          <FormattedMessage id='TopbarMobileMenu.aboutUsLink' />
        </NamedLink>
        <NamedLink name='FAQ' className={css.navigationLink}>
          <FormattedMessage id='TopbarMobileMenu.faqLink' />
        </NamedLink>
        <NamedLink name='ContactUs' className={css.navigationLink}>
          <FormattedMessage id='TopbarMobileMenu.contactLink' />
        </NamedLink>
        <InlineTextButton className={css.navigationLink} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>


      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
