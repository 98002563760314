import React from 'react';
import { FormattedMessage } from 'react-intl';
import { richText } from '../../util/richText';

import css from './ListingPage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { description, publicData, title } = props;
  const { others } = publicData || {};
  const { aGreatSkiGuide, aboutBeingSkiGuide } = others || {};
  return (
    <React.Fragment>
      {
        title ? (
          <div className={css.sectionDescription}>
            <h2 className={css.descriptionTitle}>
              <FormattedMessage id="ListingPage.title" />
            </h2>
            <p className={css.description}>
              {title}
            </p>
          </div>
        ) : null
      }
      {
        description ? (
          <div className={css.sectionDescription}>
            <h2 className={css.descriptionTitle}>
              <FormattedMessage id="ListingPage.descriptionTitle" />
            </h2>
            <p className={css.description}>
              {richText(description, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                longWordClass: css.longWord,
              })}
            </p>
          </div>
        ) : null
      }
      {
        aboutBeingSkiGuide ? (
          <div className={css.sectionDescription}>
            <h2 className={css.descriptionTitle}>
              <FormattedMessage id="ListingPage.aboutBeingSkiGuide" />
            </h2>
            <p className={css.description}>
              {richText(aboutBeingSkiGuide, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                longWordClass: css.longWord,
              })}
            </p>
          </div>
        ) : null
      }
      {
        aGreatSkiGuide ? (
          <div className={css.sectionDescription}>
            <h2 className={css.descriptionTitle}>
              <FormattedMessage id="ListingPage.aGreatSkiGuide" />
            </h2>
            <p className={css.description}>
              {richText(aGreatSkiGuide, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                longWordClass: css.longWord,
              })}
            </p>
          </div>
        ) : null
      }
    </React.Fragment >
  );
};

export default SectionDescriptionMaybe;
