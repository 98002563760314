import React, { Component } from 'react';
import { string, bool, arrayOf } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';
import { required, bookingDatesRequired, composeValidators } from '../../util/validators';
import {
  isDayMomentInsideRange,
  resetToStartOfDay,
  dateIsAfter,
  getStartHours,
  isInRange,
  timestampToDate,
  getTimeZoneNames,
} from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Form,
  PrimaryButton,
  FieldDateRangeInput,
  FieldDateInput,
  FieldSelect,
} from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import { createTimeRangeSelection, getTimeValue } from './util';

import css from './BookingDatesForm.css';
import isEmpty from 'lodash/isEmpty';

const getAvailableStartTimes = (
  intl,
  timeZone,
  bookingStart,
  timeSlotsOnSelectedDate,
) => {
  if (timeSlotsOnSelectedDate.length === 0 || !timeSlotsOnSelectedDate[0] || !bookingStart) {
    return [];
  }
  const bookingStartDate = resetToStartOfDay(bookingStart, timeZone);

  const allHours = timeSlotsOnSelectedDate.reduce((availableHours, t) => {
    const startDate = t.attributes.start;
    const endDate = t.attributes.end;
    const nextDate = resetToStartOfDay(bookingStartDate, timeZone, 1);

    // If the start date is after timeslot start, use the start date.
    // Otherwise use the timeslot start time.
    const startLimit = dateIsAfter(bookingStartDate, startDate) ? bookingStartDate : startDate;

    // If date next to selected start date is inside timeslot use the next date to get the hours of full day.
    // Otherwise use the end of the timeslot.
    const endLimit = dateIsAfter(endDate, nextDate) ? nextDate : endDate;

    const hours = getStartHours(intl, timeZone, startLimit, endLimit);
    return availableHours.concat(hours);
  }, []);
  return allHours;
};

const getTimeSlots = (timeSlots, date, timeZone) => {
  return Array.isArray(timeSlots)
    ? timeSlots.filter(t => isInRange(date, t.attributes.start, t.attributes.end, 'day', timeZone))
    : [];
};

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { focusedInput: null, mounted: false };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.availabeDateTimeSlots = null;
    this.endTimeSlots = config.custom.times;
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.selectedTimeSlots = [];
    this.selectedDate = [];
    this.selectedStartTime = [];
    this.form = null;
    this.availableTimeZones = getTimeZoneNames();
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      initialValues,
      timeZone,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        initialValues={this.state.initialValues ? this.state.initialValues : initialValues}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            form,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitPrice,
            unitType,
            values,
            timeSlots,
            fetchTimeSlotsError,
          } = fieldRenderProps;

          const { date, time } = values || {};

          const dateLabel = intl.formatMessage({
            id: 'BookingDatesForm.dateLabel',
          });

          const timeLabel = intl.formatMessage({
            id: 'BookingDatesForm.timeLabel',
          });

          const requiredMessageDate = intl.formatMessage({
            id: 'BookingDatesForm.requiredMessageDate',
          });

          const requiredMessageTime = intl.formatMessage({
            id: 'BookingDatesForm.requiredMessageTime',
          });

          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.timeSlotsError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.

          const bookingData = time
            ? {
                unitType,
                unitPrice,
                startDate: timestampToDate(time),
                endDate: timestampToDate(time + 30 * 1000 * 1000),

                // NOTE: If unitType is `line-item/units`, a new picker
                // for the quantity should be added to the form.
                quantity: 1,
              }
            : null;
          const bookingInfo = bookingData ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} timeZone={timeZone}/>
            </div>
          ) : null;

          const now = moment();
          const today = now.startOf('day');
          const datePlaceholderText = today.format('MM/DD/YYYY');

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const isDayBlocked = timeSlots
            ? day =>
                !timeSlots.find(timeSlot =>
                  isDayMomentInsideRange(
                    day,
                    timeSlot.attributes.start,
                    timeSlot.attributes.end,
                    timeZone
                  )
                )
            : () => false;

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              {timeSlotsError}
              <FormSpy
                onChange={formState => {
                  // If no date is selected, reset the time selector
                  if (formState.values.date && formState.values.date.date) {
                    const selectedSlots = getTimeSlots(
                      timeSlots,
                      formState.values.date.date,
                      timeZone
                    );
                    this.availabeDateTimeSlots = getAvailableStartTimes(
                      intl,
                      timeZone,
                      formState.values.date.date,
                      selectedSlots
                    );

                    this.selectedDate = formState.values.date;
                  }
                }}
              />
              <div className={css.fields}>
                <FieldDateInput
                  name="date"
                  id="date"
                  label={dateLabel}
                  timeSlots={timeSlots}
                  placeholderText={datePlaceholderText}
                  className={css.field}
                  validate={required(requiredMessageDate)}
                  displayFormat={'MM/DD/YYYY'}
                  isDayBlocked={isDayBlocked}
                />
                <FieldSelect
                  className={css.field}
                  id="time"
                  name="time"
                  label={timeLabel}
                  validate={required(requiredMessageTime)}
                >
                  <option key="placeholder" value="" className={css.hideOption}>
                    {intl.formatMessage({
                      id: 'BookingDatesForm.pickTime',
                    })}
                  </option>
                  {this.availabeDateTimeSlots
                    ? this.availabeDateTimeSlots.map(time => (
                        <option key={time.timestamp} value={time.timestamp}>
                          {time.timeOfDay}
                        </option>
                      ))
                    : ''}
                </FieldSelect>
              </div>
              {bookingInfo}
              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit">
                  <FormattedMessage id="BookingDatesForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
