import React, { Fragment } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import css from './AccordionItem.css';
import { FormattedMessageHTML } from '../../components';

import faq8 from '../../assets/FAQ/FAQ_Itinerary_SS_062020.jpg';
import faq81 from '../../assets/FAQ/Phone_Portrait_1.jpg';
import faq82 from '../../assets/FAQ/Phone_Landscape_1.jpg';
import icloud1 from '../../assets/FAQ/iCloud_1.jpg';
import icloud2 from '../../assets/FAQ/iCloud_2.jpg';
import icloud3 from '../../assets/FAQ/iCloud_3.jpg';
import icloud4 from '../../assets/FAQ/iCloud_4.jpg';

import dropbox2 from '../../assets/FAQ/Dropbox_2.jpg';
import dropbox3 from '../../assets/FAQ/Dropbox_3.jpg';
import dropbox4 from '../../assets/FAQ/Dropbox_4.jpg';
import dropbox5 from '../../assets/FAQ/Dropbox_5.jpg';

import calendar1 from '../../assets/FAQ/Calendar_weekday_view_1.jpg';
import calendar2 from '../../assets/FAQ/Calendar_weekday_view_2.jpg';
import calendar3 from '../../assets/FAQ/Calendar_blackout_date_SS.jpg';
import closeListing from '../../assets/FAQ/Close_listing_SS.jpg';
import openListing from '../../assets/FAQ/Open_listing_SS.jpg';

class AccordionItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAnswer: false,
    };
  }

  handleClickQuestion = () => this.setState(prevState => ({ showAnswer: !prevState.showAnswer }));

  componentDidMount() {
    const { selectedHash, hash } = this.props;
    if (selectedHash && selectedHash.includes(hash)) {
      this.setState({ showAnswer: true });
    }
  }

  getContent = () => {
    const { index } = this.props;
    if (index === 8) {
      return (
        <Fragment>
          <span className={css.answerContent}>
            <img className={css.fullWidthImage} src={faq8} />
          </span>
          <span className={css.answerContent}>
            Itineraries can also be viewed on mobile devices. Please review our FAQ about how to
            save your itinerary locally on your phone so you can view it on the mountain even
            without cell service.
          </span>
          <span className={css.answerContent}>
            <img className={classNames(css.halfWidthImage)} src={faq81} />
          </span>
          <span className={css.answerContent}>
            <img className={css.fullWidthImage} src={faq82} />
          </span>
        </Fragment>
      );
    }
    if (index === 10) {
      return (
        <Fragment>
          <span className={css.answerContent}>
            1. Open your .pdf itinerary attachment and tap the share icon in the upper right.
          </span>
          <span className={css.answerContent}>
            <img className={classNames(css.fullWidthImage, css.mobileImage)} src={icloud1} />
          </span>
          <span className={css.answerContent}>2. Tap “Save to Files”.</span>
          <span className={css.answerContent}>
            <img className={classNames(css.fullWidthImage, css.mobileImage)} src={icloud2} />
          </span>
          <span className={css.answerContent}>
            3. You can choose to save your itinerary to “iCloud Drive” or “On My iPhone”. In this
            example we have chosen “On My iPhone”. Next tap “Save” in the upper right corner.
          </span>
          <span className={css.answerContent}>
            <img className={classNames(css.fullWidthImage, css.mobileImage)} src={icloud3} />
          </span>
          <span className={css.answerContent}>
            4. Open the “Files” app on your iPhone. You should be able to open your itinerary on
            your iPhone even without cell service. <br /><br />
            Tip: try turning off your iPhone’s Wi-Fi and Cellular Data (in Settings) and verify
            you’re still able to open and view your itinerary. If so, you should be good to go on
            the slopes!
          </span>
          <span className={css.answerContent}>
            <img className={classNames(css.fullWidthImage, css.mobileImage)} src={icloud4} />
          </span>
          <span className={css.answerContent}>
            How to download your .pdf itinerary for offline viewing on your phone via Dropbox.
            Similar steps can be taken with other cloud services like Google Drive or OneDrive.
          </span>
          <span className={css.answerContent}>
            1. Open your .pdf itinerary attachment and tap the share icon in the upper right.
          </span>
          <span className={css.answerContent}>
            <img className={classNames(css.fullWidthImage, css.mobileImage)} src={icloud1} />
          </span>
          <span className={css.answerContent}>
            2. Tap “Save to Dropbox” and then select the Dropbox folder where you’d like to save
            your itinerary.
          </span>
          <span className={css.answerContent}>
            <img className={classNames(css.fullWidthImage, css.mobileImage)} src={dropbox2} />
          </span>
          <span className={css.answerContent}>
            3. Open Dropbox and tap on the 3 dots to the right of your .pdf itinerary.
          </span>
          <span className={css.answerContent}>
            <img className={classNames(css.fullWidthImage, css.mobileImage)} src={dropbox3} />
          </span>
          <span className={css.answerContent}>4. Tap “Make Available Offline”.</span>
          <span className={css.answerContent}>
            <img className={classNames(css.fullWidthImage, css.mobileImage)} src={dropbox4} />
          </span>
          <span className={css.answerContent}>
            5. The green downward arrow icon indicates you should be able to open and view your
            itinerary even without cell service.
            <br /><br />
            Tip: try turning off your phone’s Wi-Fi and Cellular Data (in Settings) and verify
            you’re still able to open and view your itinerary. If so, you should be good to go on
            the slopes!
          </span>
          <span className={css.answerContent}>
            <img className={classNames(css.fullWidthImage, css.mobileImage)} src={dropbox5} />
          </span>
        </Fragment>
      );
    }
    if (index === 32) {
      return (
        <Fragment>
          <span className={css.answerContent}>
            The weekday section is where you customize your availability for each day of the week. 
            You can add multiple time slots of availability (shown for Wednesdays and Saturdays below) or no availability 
            (shown for Mondays and Thursdays below). Your schedule in the weekday section repeats each week until you update it, 
            which you can do at any time.
          </span>
          <span className={css.answerContent}>
            You can also use Availability Exceptions to override your weekly schedule by adding Available or 
            Not Available time slots for any single day or date range you choose.
          </span>
          <span className={css.answerContent}>
            <img className={classNames(css.fullWidthImage, css.mobileImage)} src={calendar1} />
          </span>
          {/* <span className={css.answerContent}>
            <img className={classNames(css.fullWidthImage, css.mobileImage)} src={calendar2} />
          </span> */}
          {/* <span className={css.answerContent}>
            <img className={classNames(css.fullWidthImage, css.mobileImage)} src={calendar3} />
          </span> */}
          <span className={css.answerContent}>
            You can also temporarily remove your listing from appearing in the search results at any time. 
            To do this go to “Your listing” page and click “Close listing” under the 3 dots in the upper right corner. 
            Your listing will be closed until you click “Open listing” on the same page. 
          </span>
          <span className={css.answerContent}>
            <img className={classNames(css.fullWidthImage, css.mobileImage)} src={closeListing} />
          </span>
          <span className={css.answerContent}>
            <img className={classNames(css.fullWidthImage, css.mobileImage)} src={openListing} />
          </span>
        </Fragment>
      );
    }
    if (index === 33) {
      return (
        <Fragment>
          <span className={css.answerContent}>
            <img className={css.fullWidthImage} src={faq8} />
          </span>
        </Fragment>
      );
    }
    return null;
  };

  render() {
    const { rootClassName, className, question, answer, intl, hash } = this.props;
    const { showAnswer } = this.state;
    const classes = classNames(rootClassName || css.root, className);

    const contentMaybe = this.getContent();

    return (
      <div className={classes} id={hash}>
        <button
          className={classNames(css.question, { [css.question_open]: showAnswer })}
          onClick={this.handleClickQuestion}
        >
          {intl.formatMessage({ id: question })}
        </button>
        <div className={classNames(css.answer, { [css.isOpen]: showAnswer })}>
          <span className={css.answerContent}>
            <FormattedMessageHTML intl={intl} id={answer} />
          </span>
          {contentMaybe}
        </div>
      </div>
    );
  }
}

AccordionItem.defaultProps = {
  rootClassName: null,
  className: null,
};

AccordionItem.propTypes = {
  rootClassName: string,
  className: string,
};

export default injectIntl(AccordionItem);
