/*
 * Marketplace specific configuration.
 */

export const amenities = [
  {
    key: 'towels',
    label: 'Towels',
  },
  {
    key: 'bathroom',
    label: 'Bathroom',
  },
  {
    key: 'swimming_pool',
    label: 'Swimming pool',
  },
  {
    key: 'own_drinks',
    label: 'Own drinks allowed',
  },
  {
    key: 'jacuzzi',
    label: 'Jacuzzi',
  },
  {
    key: 'audiovisual_entertainment',
    label: 'Audiovisual entertainment',
  },
  {
    key: 'barbeque',
    label: 'Barbeque',
  },
  {
    key: 'own_food_allowed',
    label: 'Own food allowed',
  },
];

export const categories = [
  { key: 'smoke', label: 'Smoke' },
  { key: 'electric', label: 'Electric' },
  { key: 'wood', label: 'Wood' },
  { key: 'other', label: 'Other' },
];

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 5,
};

// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
  active: true,
};

export const editListingTabLabels = {
  agreement: 'EditListingWizard.tabLabelAgreement',
  location: 'EditListingWizard.tabLabelLocation',
  description: 'EditListingWizard.tabLabelDescription',
  specialties: 'EditListingWizard.tabLabelSpecialties',
  availability: 'EditListingWizard.tabLabelAvailability',
  photos: 'EditListingWizard.tabLabelPhotos',
};

export const whereAreYouExpert = [
  { key: 'afton-alps-mn', label: 'Afton Alps, MN' },
  { key: 'alta', label: 'Alta, UT' },
  { key: 'aspen', label: 'Aspen Mountain, CO' },
  { key: 'beaver-co', label: 'Beaver Creek, CO' },
  { key: 'Big-Sky', label: 'Big Sky, MT' },
  { key: 'breckenridge-co', label: 'Breckenridge, CO' },
  { key: 'Brighton', label: 'Brighton, UT' },
  { key: 'Canyons-Resort', label: 'Canyons Resort, UT' },
  { key: 'copper-co', label: 'Copper Mountain, CO' },
  { key: 'CrestedButte', label: 'Crested Butte, CO' },
  { key: 'Crystal-Mountain', label: 'Crystal Mountain, WA' },
  { key: 'Deer-Valley', label: 'Deer Valley, UT' },
  { key: 'Heavenly', label: 'Heavenly, CA' },
  { key: 'Jackson-Hole', label: 'Jackson Hole, WY' },
  { key: 'Keystone', label: 'Keystone, CO' },
  { key: 'Killington', label: 'Killington, VT' },
  { key: 'Kirkwood', label: 'Kirkwood, CA' },
  { key: 'Mammoth-Mountain', label: 'Mammoth Mountain, CA' },
  { key: 'Mount-Bachelor', label: 'Mount Bachelor, OR' },
  { key: 'Northstar', label: 'Northstar, CA' },
  { key: 'Okemo', label: 'Okemo, VT' },
  { key: 'Park-City', label: 'Park City, UT' },
  { key: 'Purgatory', label: 'Purgatory, CO' },
  { key: 'Snowbird', label: 'Snowbird, UT' },
  { key: 'Snowmass', label: 'Snowmass, CO' },
  { key: 'Snow-Summit', label: 'Snow Summit, CA' },
  { key: 'Squaw-Valley', label: 'Squaw Valley, CA' },
  { key: 'Steamboat', label: 'Steamboat, CO' },
  { key: 'Stevens-Pass', label: 'Stevens Pass, WA' },
  { key: 'Stowe', label: 'Stowe, VT' },
  { key: 'Stratton-Mountain', label: 'Stratton Mountain, VT' },
  { key: 'Sugarbush', label: 'Sugarbush, VT' },
  { key: 'Sugarloaf', label: 'Sugarloaf, ME' },
  { key: 'Sun-Valley', label: 'Sun Valley, ID' },
  { key: 'Taos', label: 'Taos, NM' },
  { key: 'Telluride', label: 'Telluride, CO' },
  { key: 'The-Summit', label: 'The Summit, WA' },
  { key: 'vail-co', label: 'Vail, CO' },
  { key: 'Whitefish', label: 'Whitefish, MT' },
  { key: 'Winter-Park', label: 'Winter Park, CO' },
];

export const skiType = [
  { key: 'ski', labelId: 'EditListingSpecialties.types.ski', label: 'Ski' },
  { key: 'snowboard', labelId: 'EditListingSpecialties.types.snowboard', label: 'Snowboard' },
];

export const skiLevel = [
  { key: 'beginner', labelId: 'EditListingSpecialties.level.beginner', label: 'Beginner' },
  {
    key: 'intermediate',
    labelId: 'EditListingSpecialties.level.intermediate',
    label: 'Intermediate',
  },
  { key: 'advanced', labelId: 'EditListingSpecialties.level.advanced', label: 'Advanced' },
  { key: 'expert', labelId: 'EditListingSpecialties.level.expert', label: 'Expert' },
];

export const skiSpecialties = [
  { key: 'groomer', label: 'Groomers', labelId: 'EditListingSpecialties.specialties.groomer' },
  { key: 'powder', label: 'Powder', labelId: 'EditListingSpecialties.specialties.powder' },
  { key: 'moguls', label: 'Moguls', labelId: 'EditListingSpecialties.specialties.moguls' },
  {
    key: 'trees-glades',
    label: 'Tree / Glades',
    labelId: 'EditListingSpecialties.specialties.treesGlades',
  },
  {
    key: 'steep-terrain',
    label: 'Steep terrain',
    labelId: 'EditListingSpecialties.specialties.steepTerrain',
  },
  {
    key: 'free-style',
    label: 'Freestyle',
    labelId: 'EditListingSpecialties.specialties.freeStyle',
  },
];

export const knowledgable = [
  {
    key: 'mountain-dining',
    label: '',
    labelId: 'EditListingSpecialties.knowledgable.mountainDining',
  },
  { key: 'town-dining', label: '', labelId: 'EditListingSpecialties.knowledgable.townDining' },
  {
    key: 'ski-snowboard-rentals',
    label: '',
    labelId: 'EditListingSpecialties.knowledgable.rentals',
  },
  { key: 'hotels', label: '', labelId: 'EditListingSpecialties.knowledgable.hotels' },
  { key: 'nightlife', label: '', labelId: 'EditListingSpecialties.knowledgable.nightlife' },
  { key: 'local-history', label: '', labelId: 'EditListingSpecialties.knowledgable.localHistory' },
];

export const runDifficulty = [
  { key: 'green', label: '', labelId: 'EditListingSpecialties.runDifficulty.green' },
  { key: 'black', label: '', labelId: 'EditListingSpecialties.runDifficulty.black' },
  { key: 'blue', label: '', labelId: 'EditListingSpecialties.runDifficulty.blue' },
  { key: 'd-black', label: '', labelId: 'EditListingSpecialties.runDifficulty.doubleBlack' },
];

export const times = [
  { key: 0, label: '00:00 am' },
  { key: 30, label: '00:30 am' },
  { key: 60, label: '01:00 am' },
  { key: 90, label: '01:30 am' },
  { key: 120, label: '02:00 am' },
  { key: 150, label: '02:30 am' },
  { key: 180, label: '03:00 am' },
  { key: 210, label: '03:30 am' },
  { key: 240, label: '04:00 am' },
  { key: 270, label: '04:30 am' },
  { key: 300, label: '05:00 am' },
  { key: 330, label: '05:30 am' },
  { key: 360, label: '06:00 am' },
  { key: 390, label: '06:30 am' },
  { key: 420, label: '07:00 am' },
  { key: 450, label: '07:30 am' },
  { key: 480, label: '08:00 am' },
  { key: 510, label: '08:30 am' },
  { key: 540, label: '09:00 am' },
  { key: 570, label: '09:30 am' },
  { key: 600, label: '10:00 am' },
  { key: 630, label: '10:30 am' },
  { key: 660, label: '11:00 am' },
  { key: 690, label: '11:30 am' },
  { key: 720, label: '12:00 pm' },
  { key: 750, label: '12:30 pm' },
  { key: 780, label: '01:00 pm' },
  { key: 810, label: '01:30 pm' },
  { key: 840, label: '02:00 pm' },
  { key: 870, label: '02:30 pm' },
  { key: 900, label: '03:00 pm' },
  { key: 930, label: '03:30 pm' },
  { key: 960, label: '04:00 pm' },
  { key: 990, label: '04:30 pm' },
  { key: 1020, label: '05:00 pm' },
  { key: 1050, label: '05:30 pm' },
  { key: 1080, label: '06:00 pm' },
  { key: 1110, label: '06:30 pm' },
  { key: 1140, label: '07:00 pm' },
  { key: 1170, label: '07:30 pm' },
  { key: 1200, label: '08:00 pm' },
  { key: 1230, label: '08:30 pm' },
  { key: 1260, label: '09:00 pm' },
  { key: 1290, label: '09:30 pm' },
  { key: 1320, label: '10:00 pm' },
  { key: 1350, label: '10:30 pm' },
  { key: 1380, label: '11:00 pm' },
  { key: 1410, label: '11:30 pm' },
];

export const timeSlots = Array.apply(null, { length: 48 })
  .map(Function.call, Number)
  .map((x, index) => {
    let hour = parseInt(x / 2);
    let prefix = hour >= 12 ? 'pm' : 'am';
    hour = hour > 12 ? hour - 12 : hour;
    let min = (x % 2) * 30;
    let value = `${(hour < 10 ? '0' : '') + hour}:${(min < 10 ? '0' : '') + min} ${prefix}`;
    return {
      key: value,
      label: value,
      hour: prefix === 'am' ? hour : hour < 12 ? hour + 12 : hour,
      minutes: min,
      session: 0,
      sessionInternalIndex: index,
    };
  });

export const defaultLocation = {
  'beaver-co': { lat: '39.604225', lng: '-106.516518' },
  'copper-co': { lat: '39.5066533', lng: '-106.1422442' },
  'afton-alps-mn': { lat: '44.85781288402109', lng: '-92.78787057483022' },
  'alta': { lat: '40.58854483418521', lng: '-111.63855215825964' },
  'aspen': { lat: '39.1870222308091', lng: '-106.81817658561745' },
  'Big-Sky': { lat: '45.28662605872935', lng: '-111.40108724427567' },
  'breckenridge-co': { lat: '39.637642', lng: '-106.363204' },
  'Brighton': { lat: '40.59818272021893', lng: '-111.58317752106161' },
  'Canyons-Resort': { lat: '40.68280805494314', lng: '-111.55693531655686' },
  'CrestedButte': { lat: '38.86774188577046', lng: '-106.9808426789605' },
  'Crystal-Mountain': { lat: '46.935726523241144', lng: '-121.47466584665955' },
  'Deer-Valley': { lat: '40.63753466843175', lng: '-111.47830600180488' },
  'Heavenly': { lat: '38.935587377011935', lng: '-119.93987162210668' },
  'Jackson-Hole': { lat: '43.46268907339404', lng: '-110.79332055791149' },
  'Keystone': { lat: '39.60902570877492', lng: '-105.94369835157468' },
  'Killington': { lat: '43.62653584897474', lng: '-72.7967434324593' },
  'Kirkwood': { lat: '38.685907958842314', lng: '-120.06512223206352' },
  'Mammoth-Mountain': { lat: '37.631448119835035', lng: '-119.03254839848941' },
  'Mount-Bachelor': { lat: '43.993412682487005', lng: '-121.66120636889869' },
  'Northstar': { lat: '39.27473388772254', lng: '-120.12108267310585' },
  'Okemo': { lat: '43.40214278530019', lng: '-72.71700176934985' },
  'Park-City': { lat: '40.65362666908385', lng: '-111.5075150409143' },
  'Purgatory': { lat: '37.6310724661588', lng: '-107.81386937307282' },
  'Snowbird': { lat: '40.58159782656693', lng: '-111.65649240128788' },
  'Snowmass': { lat: '39.20714446551243', lng: '-106.95462276007802' },
  'Snow-Summit': { lat: '34.23646573409645', lng: '-116.88928779528864' },
  'Squaw-Valley': { lat: '39.197658403603896', lng: '-120.23580817479203' },
  'Steamboat': { lat: '40.45791550803514', lng: '-106.80449622186548' },
  'Stevens-Pass': { lat: '47.74495174597612', lng: '-121.08915147611974' },
  'Stowe': { lat: '44.53322246140777', lng: '-72.78226888759762' },
  'Stratton-Mountain': { lat: '43.11413094736222', lng: '-72.90824683974785' },
  'Sugarbush': { lat: '44.13621374692409', lng: '-72.89450299376917' },
  'Sugarloaf': { lat: '45.054033745834026', lng: '-70.30954416153124' },
  'Sun-Valley': { lat: '43.67207028593026', lng: '-114.36495563614301' },
  'Taos': { lat: '36.59564953294403', lng: '-105.44984411546196' },
  'Telluride': { lat: '37.93936213589711', lng: '-107.85619036854047' },
  'The-Summit': { lat: '47.41106350146774', lng: '-121.41136339619338' },
  'vail-co': { lat: '39.637642', lng: '-106.363204' },
  'Whitefish': { lat: '48.4808550575026', lng: '-114.35021639649197' },
  'Winter-Park': { lat: '39.887109016757535', lng: '-105.76251380462837' },
};

export const defaultBusinessMCC = '5655';
