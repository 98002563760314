import axios from "axios/index";

export const EMAIL_SUBSCRIPTION_REQUEST = 'app/EmailUpdatesPage/EMAIL_SUBSCRIPTION_REQUEST';
export const EMAIL_SUBSCRIPTION_SUCCESS = 'app/EmailUpdatesPage/EMAIL_SUBSCRIPTION_SUCCESS';
export const EMAIL_SUBSCRIPTION_ERROR = 'app/EmailUpdatesPage/EMAIL_SUBSCRIPTION_ERROR';

const initialState = {
  // email subscription
  isSubscribed: false,
  emailSubscriptionInProgress: false,
  emailSubscriptionError: null,
};

export default (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case EMAIL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isSubscribed: false,
        emailSubscriptionInProgress: true,
        emailSubscriptionError: null,
      };
    case EMAIL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        emailSubscriptionInProgress: false,
        isSubscribed: true
      };
    case EMAIL_SUBSCRIPTION_ERROR:
      return {
        ...state,
        isSubscribed: false,
        emailSubscriptionInProgress: false,
        emailSubscriptionError: payload
      };

    default:
      return initialState;
  }
};

export const emailSubscriptionRequest = () => ({ type: EMAIL_SUBSCRIPTION_REQUEST });
export const emailSubscriptionSuccess = result => ({ type: EMAIL_SUBSCRIPTION_SUCCESS, payload: result });
export const emailSubscriptionError = error => ({ type: EMAIL_SUBSCRIPTION_ERROR, payload: error, error: true });


const API_URL = typeof window !== 'undefined' && window.location.origin === 'https://mountainguide.io' ?  'https://mountainguide.io' : 'https://test.mountainguide.io';

export const setupEmailSubscription = ({ email, firstName, lastName, emailGroup }) => (dispatch) => {
  dispatch(emailSubscriptionRequest());

  const auth = {
    username: 'mountain',
    password: 'mountain1',
  };

  const params = {
    first: firstName,
    last: lastName,
    email,
    interests: emailGroup
  };

  // let proxy = "https://cors-anywhere.herokuapp.com/";

  return axios
    .post(`${API_URL}/api/mail-list`, params, { auth })
    .then(function (response) {
      dispatch(emailSubscriptionSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      if(e.response.status === 500){
        dispatch(emailSubscriptionError('Use another email address please'));
      } else {
        dispatch(emailSubscriptionError(e.response.data));
      }

    });
};




