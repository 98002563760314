import React, { useRef } from 'react';
import Slider from 'react-slick';
import review1Image from '../../assets/LandingPage/1_Jake_J_Vail.png';
import review2Image from '../../assets/LandingPage/2_Melissa_A_Beaver.png';
import review3Image from '../../assets/LandingPage/3_Alyssa_S_Breck.png';
import review4Image from '../../assets/LandingPage/4_Nikki_B_Copper.png';
import review5Image from '../../assets/LandingPage/5_Rachel_E_Vail.png';
import review6Image from '../../assets/LandingPage/6_Chris_W_Beaver.png';
import review7Image from '../../assets/LandingPage/7_Sammy_B_Copper.png';
import review8Image from '../../assets/LandingPage/8_Matt_B_Copper.png';
import { ReviewRating } from '../../components';
import css from './LandingPage.css';

const data = [
  {
    review: `"Jake is super knowledgeable about Vail and sent us a great customized itinerary for our recent powder ski day. When we spoke he asked us some questions to assess our skill level and favorite terrain and his itinerary was detailed and really well thought out.\n\nJake definitely helped us find some awesome powder and tree runs we never would have found on our own. We highly recommend connecting with Jake if you’re visiting Vail and don’t know the mountain very well."\n- Tommy R`,
    name: 'Tommy R',
    expert: 'Jake J',
    resort: 'Vail, CO',
    image: review1Image,
  },
  {
    review: `"Great itinerary from Melissa! She was fun to speak with, was attentive, and sent us exactly what we were looking for with a great set of recommended runs. She clearly knows Beaver Creek like an instructor. Our hotel concierge told us about MountainGuide…cool service for sure."\n- Derek A`,
    name: 'Derek A',
    expert: 'Melissa A',
    resort: 'Beaver Creek, CO',
    image: review2Image,
  },
  {
    review: `"I spoke with Alyssa for about 15 minutes and she sent us an incredibly detailed itinerary for our whole ski day in less than an hour. She REALLY knows Breckenridge and helped us find the best runs while avoiding the long weekend lift lines. She also picked a great lunch spot and pointed us in the right direction for some après-ski beers at the end of the day. Highly recommend Alyssa!"\n- Jeremy S`,
    name: 'Jeremy S',
    expert: 'Alyssa S',
    resort: 'Breckenridge, CO',
    image: review3Image,
  },
  {
    review: `"So glad we did a booking with Nikki! We loved the itinerary she sent us for Copper Mountain and she certainly helped us find the best powder stashes and some fun bump runs too. Nikki was fun to speak with and she’s a trained patroller so she knows the mountain better than anyone. You’ll be lucky to get some personalized advice and recommendations from Nikki."\n- Max R`,
    name: 'Max R',
    expert: 'Nikki B',
    resort: 'Copper Mountain, CO',
    image: review4Image,
  },
  {
    review: `"Rachel gave us a fantastic customized itinerary for Vail this week. She took the time to assess our skill level and the types of runs we wanted to explore and you could tell she’s a professionally trained instructor and knows Vail like a true local expert.\n\nShe picked out a great set of runs for us and knew where the snow would be the softest in the morning and afternoon. She also pointed us to some tree runs that were perfect for our skiing style. Awesome job, Rachel!"\n- Scott M`,
    name: 'Scott M',
    expert: 'Rachel E',
    resort: 'Vail, CO',
    image: review5Image,
  },
  {
    review: `"Really enjoyed working with Chris. I could absolutely tell he has skied over 1,000 days at Beaver Creek when I read the detailed notes he included in my itinerary. He even told me which side of certain runs would be best given our skill level and current snow conditions…and he was spot-on. If you’re visiting Beaver Creek and want an insider’s perspective on where to spend your time on the mountain, I highly recommend speaking with Chris!"\n- Gary T`,
    name: 'Gary T',
    expert: 'Chris W',
    resort: 'Beaver Creek, CO',
    image: review6Image,
  },
  {
    review: `"Sammy gave us a great itinerary for our ski day at Copper chasing the recent powder. She asked us all the right questions and you could tell she’s a professionally trained instructor who knows Copper really well.\n\nShe picked out a fun set of runs that were perfect for us and also planned a lunch break at a great midway spot with awesome views to avoid the lunch rush lines at the base. I had no idea this was even a thing. Kind of like a travel itinerary, but for your ski day. Two thumbs up."\n- Marc S`,
    name: 'Marc S',
    expert: 'Sammy B',
    resort: 'Copper Mountain, CO',
    image: review7Image,
  },
  {
    review: `"Matt sent me a great itinerary less than an hour after we spoke that was easy to download and follow on my phone and included some detailed notes about some of the runs that were super helpful.\n\nHe also included some recommendations for après-ski drinks and a few restaurant suggestions for dinner which worked out great. Matt’s a great guy and I appreciated being able to tap into all his local knowledge."\n- Steven T`,
    name: 'Steven T',
    expert: 'Matt B',
    resort: 'Copper Mountain, CO',
    image: review8Image,
  },
];

const Review = ({ review, name, expert, resort, image, ...rest }) => {
  return (
    <div className={css.review} {...rest}>
      <p className={css.reviewContent}>{review}</p>
      <div className={css.info}>
        <div className={css.stars}>
          <ReviewRating rating={5} />
        </div>
        <div className={css.avatar}>
          <img src={image} />
        </div>
        <div className={css.expert}>{expert}</div>
        <div className={css.resort}>{resort}</div>
      </div>
    </div>
  );
};

function SectionReviews() {
  const sliderRef = useRef();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          dotsClass: css.dots,
          appendDots: dots => (
            <div>
              <div
                className={css.customButton}
                onClick={() => {
                  if (sliderRef.current) sliderRef.current.slickPrev();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  width="30px"
                  height="30px"
                >
                  <g transform="matrix(-1 0 0 1 512 0)">
                    <g>
                      <g>
                        <path
                          d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.616,256-256S397.385,0,256,0z M346.899,280.959    l-85.594,85.594c-13.783,13.784-36.132,13.784-49.917,0c-13.784-13.784-13.784-36.133,0-49.917L272.023,256l-60.635-60.635    c-13.784-13.784-13.784-36.133,0-49.917s36.134-13.784,49.917,0l85.594,85.594C360.683,244.825,360.683,267.175,346.899,280.959z"
                          data-original="#000000"
                          class="active-path"
                          data-old_color="#000000"
                          fill="#00A2FF"
                        />
                      </g>
                    </g>
                  </g>{' '}
                </svg>
              </div>
              {dots}
              <div
                className={css.customButton}
                onClick={() => {
                  if (sliderRef.current) sliderRef.current.slickNext();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  width="30px"
                  height="30px"
                >
                  <g>
                    <g>
                      <g>
                        <path
                          d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.616,256-256S397.385,0,256,0z M346.899,280.959    l-85.594,85.594c-13.783,13.784-36.132,13.784-49.917,0c-13.784-13.784-13.784-36.133,0-49.917L272.023,256l-60.635-60.635    c-13.784-13.784-13.784-36.133,0-49.917s36.134-13.784,49.917,0l85.594,85.594C360.683,244.825,360.683,267.175,346.899,280.959z"
                          data-original="#000000"
                          class="active-path"
                          data-old_color="#000000"
                          fill="#00A2FF"
                        />
                      </g>
                    </g>
                  </g>{' '}
                </svg>
              </div>
            </div>
          ),
        },
      },
    ],
  };
  return (
    <div>
      <div className={css.sectionReviewTitle}>Customer reviews</div>
      <Slider {...settings} ref={sliderRef}>
        {data.map((r, index) => (
          <div className={css.reviewWrapper} key={`review_${index}`}>
            <Review {...r} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SectionReviews;
