import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FieldCheckbox, Form, FieldTextInput, Button } from '../../components';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import css from './EmailUpdatesForm.css';
import { required, emailFormatValid, composeValidators, requiredCheckBox } from '../../util/validators';


class EmailUpdatesFormComponent extends Component {
  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            intl,
            values,
            invalid,
            disabled,
            inProgress,
            isSubscribed,
            emailSubscriptionInProgress,
            emailSubscriptionError,

          } = fieldRenderProps;
          const classes = classNames(rootClassName || css.root, className);

          const firstNameLabel = intl.formatMessage({ id: "EmailUpdatesForm.firstNameLabel" });
          const lastNameLabel = intl.formatMessage({ id: "EmailUpdatesForm.lastNameLabel" });
          const emailLabel = intl.formatMessage({ id: "EmailUpdatesForm.emailLabel" });

          const requiredMessage = intl.formatMessage({ id: 'EmailUpdatesForm.requiredMessage' });
          const invalidEmail = intl.formatMessage({ id: 'EmailUpdatesForm.invalidEmail' });

          const buttonClasses = emailSubscriptionInProgress ? classNames(css.submitButton, css.sendingButton) : css.submitButton;
          
          const submitDisabled = invalid || disabled || inProgress;

          return (
            <Form
              className={classes}
              onSubmit={(e) => {
                e.preventDefault();
                this.props.onSubmit(values);
              }}
            >
              <div className={css.name}>
                <FieldTextInput
                  id="firstName"
                  name="firstName"
                  type='text'
                  label={firstNameLabel}
                  className={css.firstName} validate={required(requiredMessage)}/>
                <FieldTextInput
                  id="lastName"
                  name="lastName"
                  type='text'
                  label={lastNameLabel}
                  className={css.lastName}
                  validate={required(requiredMessage)}/>
              </div>
              <div className={css.inputWrapper}>
                <FieldTextInput
                  id="email"
                  name="email"
                  type='email'
                  label={emailLabel}
                  validate={composeValidators(required(requiredMessage), emailFormatValid(invalidEmail))}/>
                <p className={css.emailTip}>
                  <FormattedMessage id="EmailUpdatesForm.emailTip"/>
                </p>
              </div>

              <div className={css.emailGroupCheckboxes}>

                {/* value of checkboxes === id of the Mail Chimp group */}
                <FieldCheckbox
                  id="skiers"
                  className={css.emailGroupCheckboxItem}
                  name="emailGroup"
                  label="Skiers"
                  value="ba212c1ba6"
                  validate={requiredCheckBox(requiredMessage)}
                />
                <FieldCheckbox
                  id="snowboarders"
                  className={css.emailGroupCheckboxItem}
                  name="emailGroup"
                  label="Snowboarders"
                  value="03dd9d5864"
                  validate={requiredCheckBox(requiredMessage)}
                />
                <FieldCheckbox
                  id="referralPartners"
                  className={css.emailGroupCheckboxItem}
                  name="emailGroup"
                  label="Referral Partners"
                  value="d3279bb569"
                  validate={requiredCheckBox(requiredMessage)}
                />
                <FieldCheckbox
                  id="mountainGuidePartners"
                  className={css.emailGroupCheckboxItem}
                  name="emailGroup"
                  label="MountainGuide Experts"
                  value="ef643b4b25"
                  validate={requiredCheckBox(requiredMessage)}
                />
              </div>

              <div className={css.submit}>
                {isSubscribed ? (
                  <Button className={classNames(buttonClasses, css.buttonDisabled)} >
                    <FormattedMessage id="EmailUpdatesForm.success"/>
                  </Button>
                ) : 
                (
                  <Button className={buttonClasses} disabled={submitDisabled}>
                    {
                      emailSubscriptionInProgress ? (
                        <FormattedMessage id="EmailUpdatesForm.loading"/>
                      ) : (
                        <FormattedMessage id="EmailUpdatesForm.submit"/>
                      )
                    }
                  </Button>
                )}
                {emailSubscriptionError && <p className={css.errorMessage}>{emailSubscriptionError}</p>}
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EmailUpdatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string, bool } = PropTypes;

EmailUpdatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  isSubscribed: bool,
  emailSubscriptionError: bool,
  emailSubscriptionInProgress: bool,
};

const EmailUpdatesForm = compose(injectIntl)(EmailUpdatesFormComponent);

EmailUpdatesForm.displayName = 'EmailUpdatesForm';

export default EmailUpdatesForm;
