import React, { Component } from 'react';
import { string, func } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'redux'
import { LayoutSingleColumn, LayoutWrapperTopbar, LayoutWrapperFooter, LayoutWrapperMain, Footer } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { StaticPage } from '..';
import { EmailUpdatesForm } from '../../forms';
import { setupEmailSubscription } from './EmailUpdatesPage.duck';
import { connect } from 'react-redux';

import css from './EmailUpdatesPage.css';

class EmailUpdatesPageComponent extends Component {
  render() {
    const {
      rootClassName,
      className,
      onSetupEmailSubscription,
      isSubscribed,
      emailSubscriptionInProgress,
      emailSubscriptionError,
      intl
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const title = intl.formatMessage({ id: 'EmailUpdatesPage.title' });

    return (
      <StaticPage title={title}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={classes} >
            <div className={css.content}>
              <h1 className={css.heading}>
                <FormattedMessage id="EmailUpdatesPage.heading" />
              </h1>
              <EmailUpdatesForm
                onSubmit={values => onSetupEmailSubscription(values)}
                isSubscribed={isSubscribed}
                emailSubscriptionInProgress={emailSubscriptionInProgress}
                emailSubscriptionError={emailSubscriptionError}
              />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </StaticPage>
    );
  }
}

EmailUpdatesPageComponent.defaultProps = {
  rootClassName: null,
  className: null
};

EmailUpdatesPageComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSetupEmailSubscription: func.isRequired,
};

const mapStateToProps = state => {
  const { isSubscribed, emailSubscriptionInProgress, emailSubscriptionError } = state.EmailUpdatesPage;
  return {
    isSubscribed,
    emailSubscriptionInProgress,
    emailSubscriptionError,
  };
};

const mapDispatchToProps = dispatch => ({
  onSetupEmailSubscription: values => dispatch(setupEmailSubscription(values)),
});

export default compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(EmailUpdatesPageComponent);
