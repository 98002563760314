import React, { Component, forwardRef, useImperativeHandle, useState } from 'react';
import {
  DayPickerSingleDateController,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from 'react-dates';
import classNames from 'classnames';
import moment from 'moment';

import config from '../../config';
import css from './DateController.css';

import { IconArrowHead } from '../../components';

export const HORIZONTAL_ORIENTATION = 'horizontal';
export const ANCHOR_LEFT = 'left';

// IconArrowHead component might not be defined if exposed directly to the file.
// This component is called before IconArrowHead component in components/index.js
const PrevIcon = props => (
  <IconArrowHead {...props} direction="left" rootClassName={css.arrowIcon} />
);
const NextIcon = props => (
  <IconArrowHead {...props} direction="right" rootClassName={css.arrowIcon} />
);

const defaultProps = {
  orientation: HORIZONTAL_ORIENTATION,
  verticalHeight: undefined,
  withPortal: false,
  isRTL: false,
  initialVisibleMonth: null,
  firstDayOfWeek: config.i18n.firstDayOfWeek,
  numberOfMonths: 1,
  daySize: 38,
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  hideKeyboardShortcutsPanel: true,

  // navigation related props
  navPrev: <PrevIcon />,
  navNext: <NextIcon />,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  transitionDuration: 200, // milliseconds between next month changes etc.

  renderCalendarDay: undefined, // If undefined, renders react-dates/lib/components/CalendarDay
  // day presentation and interaction related props
  renderDayContents: day => {
    return <span className="renderedDay">{day.format('D')}</span>;
  },
  minimumNights: config.bookingUnitType === 'line-item/night' ? 1 : 0,
  enableOutsideDays: false,
  isDayBlocked: () => false,

  // outside range -><- today ... today+available days -1 -><- outside range
  isOutsideRange: day => {
    const endOfRange = config.dayCountAvailableForBooking - 1;
    return (
      !isInclusivelyAfterDay(day, moment()) ||
      !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
    );
  },
  isDayHighlighted: () => {},

  // Internationalization props
  // Multilocale support can be achieved with displayFormat like moment.localeData.longDateFormat('L')
  // https://momentjs.com/
  // displayFormat: 'ddd, MMM D',
  monthFormat: 'MMMM YYYY',
  weekDayFormat: 'dd',
  phrases: {}, // Add overwrites to default phrases used by react-dates
};

const DateController = (
  {
    rootClassName,
    className,
    name,
    value,
    onChange,
    onFocus,
    meta,
    children,
    onBlur,
    render,
    customOnChange,
  },
  ref
) => {
  const [date, setDate] = useState(value ? moment(value.date) : null);
  const [focused, setFocused] = useState(false);
  useImperativeHandle(
    ref,
    () => ({
      onReset: start => {
        if (start) {
          setDate(moment(start));
        } else {
          setDate(null);
        }
      },
    }),
    []
  );

  const classes = classNames(rootClassName || css.inputRoot, className);

  const handleDateChange = val => {
    setDate(val);
    onChange({
      date: val,
    });
    if (typeof customOnChange === 'function') customOnChange(val);
  };

  const handleFocus = () => {
    onFocus();
    setFocused(true);
  };

  const handleBlur = () => {
    onBlur();
    setFocused(false);
  };

  return (
    <div className={classes}>
      <DayPickerSingleDateController
        {...defaultProps}
        date={date}
        onDateChange={handleDateChange}
        onFocusChange={handleFocus}
        focused={focused}
        onBlur={handleBlur}
      />
    </div>
  );
};

DateController.defaultProps = {
  rootClassName: null,
  className: null,
};

export default forwardRef(DateController);
