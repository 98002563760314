import AWS from 'aws-sdk';

const credential = new AWS.Config(
  {
    accessKeyId: `${process.env.REACT_APP_AWS_API_ACCESS_ID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_API_ACCESS_KEY}`,
    region: `${process.env.REACT_APP_AWS_API_REGION}`
  }
);

AWS.config.update(credential);

const createEmailParams = (receiver, subject, content) => {
  let toAddresses = Array.isArray(receiver) ? receiver : [receiver];
  let body = {
    Html: {
      Charset: "UTF-8",
      Data: content
    }
  };
  return {
    Destination: {
      ToAddresses: toAddresses
    },
    Message: {
      Body: body,
      Subject: {
        Charset: 'UTF-8',
        Data: subject
      }
    },
    Source: process.env.REACT_APP_CONTACT_RECEIVE_EMAIL,
  };
};

const sendEmail = (params) => {
  return new AWS.SES({apiVersion: '2010-12-01'}).sendEmail(params).promise();
};

export const sendContactEmail = ({ email, firstName, lastName, message, phone, subject }) => {
  const content = `
    <p><strong>Name:</strong> ${firstName} ${lastName}</p>
    <p><strong>Email:</strong> ${email}</p>
    <p><strong>Phone:</strong> ${phone}</p>
    <p><strong>Subject:</strong> ${subject}</p>
    <p><strong>message:</strong> ${message}</p>
  `;

  const params = createEmailParams([process.env.REACT_APP_CONTACT_RECEIVE_EMAIL, 'tatyana.chernyaeva@roobykon.com'], `Got a new message from ${email}`, content);

  return sendEmail(params);
};
