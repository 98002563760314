import React, { Component } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import css from './FAQPage.css';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  AccordionItem,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { StaticPage } from '..';
import { withRouter } from 'react-router-dom';

class FAQPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skierQuestion: Array.from({ length: 22 }, (v, k) => k + 1).map(number => ({
        question: `FAQ.question${number}`,
        answer: `FAQ.answer${number}`,
        index: number,
        hash: `question${number}`,
      })),
      expertQuestion: Array.from({ length: 21 }, (v, k) => k + 23).map(number => ({
        question: `FAQ.question${number}`,
        answer: `FAQ.answer${number}`,
        index: number,
        hash: `question${number}`,
      })),
    };
  }

  render() {
    const { rootClassName, className, intl, scrollingDisabled, location } = this.props;
    const { hash: selectedHash } = location;

    const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle: 'FAQ' });
    const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
    const classes = classNames(rootClassName || css.root, className);

    return (
      <StaticPage
        className={classes}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.pageContent}>
              <h1>
                <FormattedMessage id="FAQ.title" />
              </h1>
              <div>
                <h2>Skiers & Snowboarders</h2>
                {this.state.skierQuestion.map((item, index) => (
                  <AccordionItem key={index} {...item} selectedHash={selectedHash} />
                ))}
                <h2>MountainGuide Experts</h2>
                {this.state.expertQuestion.map((item, index) => (
                  <AccordionItem key={index} {...item} selectedHash={selectedHash} />
                ))}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </StaticPage>
    );
  }
}

FAQPageComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

FAQPageComponent.propTypes = {
  rootClassName: string,
  className: string,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const FAQPage = compose(injectIntl, connect(mapStateToProps), withRouter)(FAQPageComponent);

export default FAQPage;
