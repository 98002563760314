import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldRadioGroup } from '../../components';
import { requiredFieldArrayCheckbox } from '../../util/validators';

import css from './EditListingLocationForm.css';
import config from '../../config';
import { ensureListing } from '../../util/data';

export const EditListingLocationFormComponent = ({ listing, ...props }) => {
  const ensuredListing = ensureListing(listing);
  const { publicData } = ensuredListing.attributes;
  return (
    <FinalForm
      {...props}
      initialValues={{ whereAreYouAnExpert: publicData.whereAreYouAnExpert }}
      render={fieldRenderProps => {
        const {
          className,
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          inProgress,
          fetchErrors,
          resortsList,
          duplicateResorterror,
        } = fieldRenderProps;

        const { error } = fetchErrors || {};
        const errorMessage = error ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingLocationForm.updateFailed" />
          </p>
        ) : null;

        const errorDuplicateMessage = duplicateResorterror ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingLocationForm.errorDuplicateMessage" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = updated && pristine;
        const submitInProgress = inProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const label = intl.formatMessage({ id: 'EditListingLocationForm.labelLine2' });
        const requiredMessage = intl.formatMessage({
          id: 'EditListingLocationForm.requiredMessage',
        });
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}

            <FieldRadioGroup
              id="whereAreYouAnExpert"
              name="whereAreYouAnExpert"
              label={label}
              options={config.custom.whereAreYouExpert}
              validate={requiredFieldArrayCheckbox(requiredMessage)}
              twoColumns={true}
              list={resortsList}
            />

            {errorDuplicateMessage}
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);
