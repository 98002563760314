import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './EditListingPanel.css';

const EditListingPanel = props => {
  const {
    rootClassName,
    className,
    panelTitle,
    children
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {children}
    </div>
  );
}

EditListingPanel.defaultProps = {
  rootClassName: null,
  className: null
}

EditListingPanel.propTypes = {
  rootClassName: string,
  className: string
}

export default EditListingPanel;