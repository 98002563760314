import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import {
  DAYS,
  formatCustomSlotTime,
  getFormattedCustomSlotsForADay,
  getListingId,
  getAvailabilityExceptions,
  sdkCreateSlotException,
  sdkDeleteCustom,
  getDefaultSchedule,
  sdkUpdateSchedule,
  sortTimeSlot, reduxScheduleToFlex,
} from './AvailabilitiesContainer.helpers';
import { DEFAULT_TIME_ZONE } from '../../util/types'
import { getCurrentDateStringInDesiredTimezone } from '../../util/dates';
import { requestShowListing } from '../EditListingPage/EditListingPage.duck';

const { UUID } = sdkTypes;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// Actions

// UI
export const UI_CHANGE_SELECTED_DATE = 'app/AvailabilitiesPage/UI_CHANGE_SELECTED_DATE';
export const UI_TOGGLE_COLLAPSE_DATE = 'app/AvailabilitiesPage/UI_TOGGLE_COLLAPSE_DATE';
export const UI_TOGGLE_CHECK_DATE = 'app/AvailabilitiesPage/UI_TOGGLE_CHECK_DATE';
export const UI_ADD_SLOT = 'app/AvailabilitiesPage/UI_ADD_SLOT';
export const UI_ADD_ERROR = 'app/AvailabilitiesPage/UI_ADD_ERROR';
export const UI_DELETE_SLOT = 'app/AvailabilitiesPage/UI_DELETE_SLOT';
export const UI_HANDLE_SLOT_CHANGE = 'app/AvailabilitiesPage/UI_HANDLE_SLOT_CHANGE';
export const UI_TOGGLE_SCHEDULE_MODE = 'app/AvailabilitiesPage/UI_TOGGLE_SCHEDULE_MODE';
export const UI_SHOW_SNACKBACK = 'app/AvailabilitiesPage/UI_SHOW_SNACKBACK';
export const UI_HIDE_SNACKBACK = 'app/AvailabilitiesPage/UI_HIDE_SNACKBACK';

// SCHEDULE
export const FETCH_SCHEDULE_REQUEST = 'app/AvailabilitiesPage/FETCH_SCHEDULE_REQUEST';
export const FETCH_SCHEDULE_SUCCESS = 'app/AvailabilitiesPage/FETCH_SCHEDULE_SUCCESS';
export const FETCH_SCHEDULE_ERROR = 'app/AvailabilitiesPage/FETCH_SCHEDULE_ERROR';

export const UPDATE_SCHEDULE_REQUEST = 'app/AvailabilitiesPage/UPDATE_SCHEDULE_REQUEST';
export const UPDATE_SCHEDULE_SUCCESS = 'app/AvailabilitiesPage/UPDATE_SCHEDULE_SUCCESS';
export const UPDATE_SCHEDULE_ERROR = 'app/AvailabilitiesPage/UPDATE_SCHEDULE_ERROR';

// CUSTOM
export const FETCH_CUSTOM_REQUEST = 'app/AvailabilitiesPage/FETCH_CUSTOM_REQUEST';
export const FETCH_CUSTOM_SUCCESS = 'app/AvailabilitiesPage/FETCH_CUSTOM_SUCCESS';
export const FETCH_CUSTOM_ERROR = 'app/AvailabilitiesPage/FETCH_CUSTOM_ERROR';

export const UPDATE_CUSTOM_REQUEST = 'app/AvailabilitiesPage/UPDATE_CUSTOM_REQUEST';
export const UPDATE_CUSTOM_SUCCESS = 'app/AvailabilitiesPage/UPDATE_CUSTOM_SUCCESS';
export const UPDATE_CUSTOM_ERROR = 'app/AvailabilitiesPage/UPDATE_CUSTOM_ERROR';

export const FETCH_BOOKED_TRANSACTION_REQUEST = 'app/AvailabilitiesPage/FETCH_BOOKED_TRANSACTION_REQUEST';
export const FETCH_BOOKED_TRANSACTION_SUCCESS = 'app/AvailabilitiesPage/FETCH_BOOKED_TRANSACTION_SUCCESS';
export const FETCH_BOOKED_TRANSACTION_ERROR = 'app/AvailabilitiesPage/FETCH_BOOKED_TRANSACTION_ERROR';

export const FETCH_BOOKED_IN_MONTH_TRANSACTION_REQUEST = 'app/AvailabilitiesPage/FETCH_BOOKED_IN_MONTH_TRANSACTION_REQUEST';
export const FETCH_BOOKED_IN_MONTH_TRANSACTION_SUCCESS = 'app/AvailabilitiesPage/FETCH_BOOKED_IN_MONTH_TRANSACTION_SUCCESS';
export const FETCH_BOOKED_IN_MONTH_TRANSACTION_ERROR = 'app/AvailabilitiesPage/FETCH_BOOKED_IN_MONTH_TRANSACTION_ERROR';


// Initial state
const initialState = {
  scheduleSlots: [...DAYS],
  customSlots: [...DAYS],
  selectedDate: null,
  isScheduleMode: true,
  listingId: null,
  isLoading: false,
  error: null,
  updateDefaultScheduleError: null,
  updateDefaultScheduleSuccess: false,
  updateCustomSlotError: null,
  showSnackbar: false,
  fetchDayTransactionsInProgress: false,
  fetchDayTransactionsError: null,
  transactionRefs: [],
  fetchMonthTransactionsInProgress: false,
  fetchMonthTransactionsError: null,
  transactionMonthRefs: [],
  timezone: DEFAULT_TIME_ZONE
};

// Reducer

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

export default function reducer(state = initialState, action = {}) {
  const { type, payload = {} } = action;

  const { isStart, dayIdx, slotIdx, value, isBlocked } = payload || {};
  const key = state.isScheduleMode ? 'scheduleSlots' : 'customSlots';
  const days = [...state[key]];

  switch (type) {
    case UI_CHANGE_SELECTED_DATE:
      const { selectedDate } = payload;
      return { ...state, selectedDate };
    case UI_TOGGLE_SCHEDULE_MODE:
      return { ...state, isScheduleMode: !state.isScheduleMode };
    case UI_TOGGLE_COLLAPSE_DATE:
      days[dayIdx].isOpen = !days[dayIdx].isOpen;
      return { ...state, ...{ [key]: days } };
    case UI_TOGGLE_CHECK_DATE:
      if (state.isScheduleMode) {
        days[dayIdx].isChecked = !days[dayIdx].isChecked;
      }
      if (state.isScheduleMode && days[dayIdx].isChecked === false) {
        days[dayIdx].slots = [];
      }
      return { ...state, ...{ [key]: days } };
    case UI_ADD_SLOT:
      const newSlot = { start: 0, end: 0, isBlocked: typeof isBlocked === 'undefined' ? false : isBlocked };
      days[dayIdx].slots.push(newSlot);
      days[dayIdx].isChecked = true;
      return { ...state, ...{ [key]: days } };
    case UI_ADD_ERROR:
      return { ...state, error: payload };
    case UI_DELETE_SLOT:
      if (state.isScheduleMode) {
        days[dayIdx].slots.splice(slotIdx, 1);
        const daySlots = days[dayIdx].slots;
        const deletedSlot = daySlots.splice(slotIdx, 1);
        if (deletedSlot.length < 1) {
          days[dayIdx].isChecked = false;
        }
      } else {
        days[dayIdx].slots[slotIdx].isDeleted = days[dayIdx].slots[slotIdx].isDeleted ? false : true;
      }
      return { ...state, ...{ [key]: days } };
    case UI_HANDLE_SLOT_CHANGE:
      const availableTimeSlots = days[dayIdx].slots.filter(slot => {
        return slot.type === 'availabilityException';
      });
      const newSlots = days[dayIdx].slots.filter((slot, i) => {
        return typeof slot.type === 'undefined' && i !== slotIdx;
      });
      let error = null;
      if (!isStart) {
        for (let slot of availableTimeSlots) {
          if (slot.start <= value && slot.end >= value) {
            error = {
              messageId: 'MaterialTimeRange.overlapsEndTime'
            }
            return { ...state, error: error };
          }
        }
        for (let slot of newSlots) {
          if (slot.start <= value && slot.end >= value) {
            error = {
              messageId: 'MaterialTimeRange.newRangeOverlapsEndTime'
            }
            return { ...state, error: error };
          }
        }
        if (days[dayIdx].slots[slotIdx]['start'] && value <= days[dayIdx].slots[slotIdx]['start']) {
          days[dayIdx].slots[slotIdx]['end'] = days[dayIdx].slots[slotIdx]['start'] + 60;
        } else {
          days[dayIdx].slots[slotIdx][isStart ? 'start' : 'end'] = value;
        }
      } else {
        for (let slot of availableTimeSlots) {
          if (slot.start <= value && slot.end >= value) {
            error = {
              messageId: 'MaterialTimeRange.overlapsStartTime'
            }
            return { ...state, error: error };
          }
        }
        for (let slot of newSlots) {
          if (slot.start <= value && slot.end >= value) {
            error = {
              messageId: 'MaterialTimeRange.newRangeOverlapsStartTime'
            }
            return { ...state, error: error };
          }
        }
        if (days[dayIdx].slots[slotIdx]['end'] && value >= days[dayIdx].slots[slotIdx]['end']) {
          days[dayIdx].slots[slotIdx]['start'] = days[dayIdx].slots[slotIdx]['end'] - 60;
        } else {
          days[dayIdx].slots[slotIdx][isStart ? 'start' : 'end'] = value;
        }
      }
      return { ...state, ...{ [key]: days }, error: null };
    case UI_SHOW_SNACKBACK:
      return { ...state, showSnackbar: true };
    case UI_HIDE_SNACKBACK:
      return { ...state, showSnackbar: false };

    case FETCH_SCHEDULE_REQUEST:
      return { ...state, isLoading: true };
    case FETCH_SCHEDULE_ERROR:
      return { ...state, isLoading: false, error: payload };
    case FETCH_SCHEDULE_SUCCESS:
      return { ...state, isLoading: false, ...payload };
    case UPDATE_SCHEDULE_REQUEST:
      return { ...state, isLoading: true, updateDefaultScheduleError: null, updateDefaultScheduleSuccess: false };
    case UPDATE_SCHEDULE_ERROR:
      return { ...state, isLoading: false, updateDefaultScheduleError: payload, updateDefaultScheduleSuccess: false };
    case UPDATE_SCHEDULE_SUCCESS:
      return { ...state, isLoading: false, updateDefaultScheduleError: null, updateDefaultScheduleSuccess: true };

    case FETCH_CUSTOM_REQUEST:
      return { ...state, isLoading: true };
    case FETCH_CUSTOM_ERROR:
      return { ...state, isLoading: false, error: payload };
    case FETCH_CUSTOM_SUCCESS:
      return { ...state, isLoading: false, ...payload };
    case UPDATE_CUSTOM_REQUEST:
      return { ...state, isLoading: true, updateCustomSlotError: null };
    case UPDATE_CUSTOM_ERROR:
      return { ...state, isLoading: false, error: payload };
    case UPDATE_CUSTOM_SUCCESS:
      return { ...state, isLoading: false, updateCustomSlotError: null };

    case FETCH_BOOKED_TRANSACTION_REQUEST:
      return {
        ...state,
        fetchDayTransactionsInProgress: true,
      };
    case FETCH_BOOKED_TRANSACTION_SUCCESS:
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchDayTransactionsInProgress: false,
        transactionRefs: entityRefs(transactions)
      };
    case FETCH_BOOKED_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchDayTransactionsInProgress: false,
        fetchDayTransactionsError: payload
      };

    case FETCH_BOOKED_IN_MONTH_TRANSACTION_REQUEST:
      return {
        ...state,
        fetchMonthTransactionsInProgress: true,
      };
    case FETCH_BOOKED_IN_MONTH_TRANSACTION_SUCCESS:
      const transactionsMonth = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchMonthTransactionsInProgress: false,
        transactionMonthRefs: entityRefs(transactionsMonth)
      };
    case FETCH_BOOKED_IN_MONTH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchMonthTransactionsInProgress: false,
        fetchMonthTransactionsError: payload
      };

    default:
      return state;
  }
}

// Actions creators

// UI
export const uiChangeSelectedDate = payload => ({
  type: UI_CHANGE_SELECTED_DATE,
  payload,
});

export const uiToggleCollapseDate = payload => ({
  type: UI_TOGGLE_COLLAPSE_DATE,
  payload,
});

export const uiToggleCheckDate = payload => ({
  type: UI_TOGGLE_CHECK_DATE,
  payload,
});

export const uiAddSlot = payload => ({
  type: UI_ADD_SLOT,
  payload,
});

export const uiAddError = payload => ({
  type: UI_ADD_ERROR,
  payload,
});


export const uiDeleteSlot = payload => ({
  type: UI_DELETE_SLOT,
  payload,
});

export const uiHandleSlotChange = payload => ({
  type: UI_HANDLE_SLOT_CHANGE,
  payload,
});

export const uiToggleScheduleMode = payload => ({
  type: UI_TOGGLE_SCHEDULE_MODE,
  payload,
});

export const uiShowSnackbar = () => ({
  type: UI_SHOW_SNACKBACK,
});

export const uiHideSnackbar = () => ({
  type: UI_HIDE_SNACKBACK,
});

// FETCH Schedule
export const fetchScheduleSlotsRequest = () => ({
  type: FETCH_SCHEDULE_REQUEST,
  error: false,
  payload: {},
});

export const fetchScheduleSlotsError = error => ({
  type: FETCH_SCHEDULE_ERROR,
  error: true,
  payload: error,
});

export const fetchScheduleSlotsSuccess = ({ scheduleSlots, listingId, customSlots }) => ({
  type: FETCH_SCHEDULE_SUCCESS,
  error: false,
  payload: { scheduleSlots, listingId, customSlots },
});

// UPDATE Schedule
export const updateScheduleSlotsRequest = () => ({
  type: UPDATE_SCHEDULE_REQUEST,
  error: false,
  payload: {},
});

export const updateScheduleSlotsError = error => ({
  type: UPDATE_SCHEDULE_ERROR,
  error: true,
  payload: error,
});

export const updateScheduleSlotsSuccess = () => ({
  type: UPDATE_SCHEDULE_SUCCESS,
  error: false,
});

// FETCH Custom
export const fetchCustomSlotsRequest = () => ({
  type: FETCH_CUSTOM_REQUEST,
  error: false,
  payload: {},
});

export const fetchCustomSlotsError = error => ({
  type: FETCH_CUSTOM_ERROR,
  error: true,
  payload: error,
});

export const fetchCustomSlotsSuccess = ({ scheduleSlots, listingId, customSlots }) => ({
  type: FETCH_CUSTOM_SUCCESS,
  error: false,
  payload: { scheduleSlots, listingId, customSlots },
});

// UPDATE Custom
export const updateCustomSlotsRequest = () => ({
  type: UPDATE_CUSTOM_REQUEST,
  error: false,
  payload: {},
});

export const updateCustomSlotsError = error => ({
  type: UPDATE_CUSTOM_ERROR,
  error: true,
  payload: error,
});

export const updateCustomSlotsSuccess = () => ({
  type: UPDATE_CUSTOM_SUCCESS,
  error: false,
});

const fetchSessionsRequest = () => ({ type: FETCH_BOOKED_TRANSACTION_REQUEST });

const fetchSessionsSuccess = response => ({
  type: FETCH_BOOKED_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchSessionsError = e => ({
  type: FETCH_BOOKED_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

const fetchMonthSessionsRequest = () => ({ type: FETCH_BOOKED_IN_MONTH_TRANSACTION_REQUEST });

const fetchMonthSessionsSuccess = response => ({
  type: FETCH_BOOKED_IN_MONTH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchMonthSessionsError = e => ({
  type: FETCH_BOOKED_IN_MONTH_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

// Helpers

// Thunks
export const changeSelectedDateThunk = ({ selectedDate }) => dispatch => {
  dispatch(uiChangeSelectedDate({ selectedDate }));
  return dispatch(loadData());
};

export const loadData = () => (dispatch, getState, sdk) => {
  const selectedDate = getState().AvailabilitiesContainer.selectedDate ? getState().AvailabilitiesContainer.selectedDate : new Date();
  const timezone = DEFAULT_TIME_ZONE;
  dispatch(fetchScheduleSlotsRequest());
  return getListingId({ sdk })
    .then(listingId =>
      Promise.all([listingId, getDefaultSchedule({ sdk, listingId, timezone })])
    )
    .then(([listingId, defaultSchedule]) =>
      Promise.all([
        getAvailabilityExceptions({
          sdk,
          listingId,
          selectedDate,
          timezone
        }),
        listingId,
        defaultSchedule
      ])
    )
    .then(([availability, listingId, defaultSchedule]) => ({
      listingId,
      customSlots: getFormattedCustomSlotsForADay({ availability, timezone, selectedDate }),
      scheduleSlots: defaultSchedule
    }))
    .then(({ customSlots, scheduleSlots, listingId }) => ({
      listingId,
      customSlots: sortTimeSlot(customSlots),
      scheduleSlots: sortTimeSlot(scheduleSlots)
    }))
    .then(({ customSlots, scheduleSlots, listingId }) => {
      dispatch(fetchScheduleSlotsSuccess({ customSlots, scheduleSlots, listingId }))
    })
    .catch(err => {
      dispatch(fetchScheduleSlotsError(err))
    });
};

export const updateScheduleSlots = _ => (dispatch, getState, sdk) => {
  dispatch(updateScheduleSlotsRequest());
  const listingId = getState().AvailabilitiesContainer.listingId;
  const scheduleSlots = getState().AvailabilitiesContainer.scheduleSlots;
  const timezone = getState().AvailabilitiesContainer.timezone ? getState().AvailabilitiesContainer.timezone : DEFAULT_TIME_ZONE;
  return sdkUpdateSchedule({ sdk, listingId, scheduleSlots, timezone })
    .then(_ => dispatch(updateScheduleSlotsSuccess()))
    .then(_ => dispatch(loadData()))
    .then(_ => dispatch(uiShowSnackbar()))
    .then(() => {
      const payload = {
        id: new UUID(listingId),
        include: ['author', 'images'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      };
      dispatch(requestShowListing(payload))
    })
    .catch(err => {
      const errorObj = err.status === 400 ? {
        code: err.status,
        messageId: "AvailabilitiesPage.invalidTimeSlotDefaultSchedule"
      } : {
          code: err.status,
          messageId: "AvailabilitiesPage.unexpectedError"
        };
      dispatch(updateScheduleSlotsError(errorObj));
    });
};

export const updateCustomSlots = _ => (dispatch, getState, sdk) => {
  dispatch(updateCustomSlotsRequest());
  const timezone = getState().AvailabilitiesContainer.timezone ? getState().AvailabilitiesContainer.timezone : DEFAULT_TIME_ZONE;
  const selectedDate = getState().AvailabilitiesContainer.selectedDate ? getState().AvailabilitiesContainer.selectedDate : new Date();
  const listingId = getState().AvailabilitiesContainer.listingId;
  const slots = getState().AvailabilitiesContainer.customSlots[selectedDate.getDay()].slots;

  const formattedSlots = slots.map(s => ({
    ...s,
    start: getCurrentDateStringInDesiredTimezone({
      date: formatCustomSlotTime({ selectedDate, t: s.start }),
      timezone
    }),
    end: getCurrentDateStringInDesiredTimezone({
      date: formatCustomSlotTime({ selectedDate, t: s.end }),
      timezone
    }),
  }));


  const deletedFromSchedule = formattedSlots.filter(x => x.isFromSchedule && x.isDeleted);
  const deletedFromCustom = formattedSlots.filter(x => x.isFromCustom && x.isDeleted);
  const created = formattedSlots.filter(
    x => !x.isDeleted && !x.id && !x.isFromCustom && !x.isFromSchedule
  );

  // Processing
  return Promise.all(
    created.map(x =>
      sdkCreateSlotException({
        sdk,
        listingId,
        start: x.start,
        end: x.end,
        isUnavailabilityMode: x.isBlocked ? true : false,
      })
    )
  )
    .then(() =>
      Promise.all(
        deletedFromSchedule.map(x =>
          sdkCreateSlotException({
            sdk,
            listingId,
            start: x.start,
            end: x.end,
            isUnavailabilityMode: true,
          })
        )
      )
    )
    .then(() =>
      Promise.all(deletedFromCustom.map(x => sdkDeleteCustom({ sdk, exceptionId: x.id })))
    )
    .then(() => dispatch(loadData()))
    .then(() => dispatch(updateCustomSlotsSuccess()))
    .then(_ => dispatch(uiShowSnackbar()))
    .catch(err => {
      const errorObj = err.status === 400 ? {
        code: err.status,
        messageId: createMessageIdForUpdateCustomSlot(err.data.errors[0].details)
      } : {
          code: err.status,
          messageId: "AvailabilitiesPage.unexpectedError"
        };
      dispatch(updateCustomSlotsError(errorObj));
    });
};

const createMessageIdForUpdateCustomSlot = (details) => {
  switch (details) {
    case 'Availability exception range overlaps with existing availability exceptions.':
      return 'AvailabilitiesPage.overlaps';
    default:
      return 'AvailabilitiesPage.invalidTimeSlotCustomSlot';
  }
}

const BIG_TRANSACTION_PER_PAGE = 100;

export const fetchBookedTransactions = {}

// export const fetchBookedTransactions = (tab, rawStartDate = null, rawEndDate = null, isMonth, timezone = DEFAULT_TIME_ZONE) =>
//   (dispatch, getState, sdk) => {

//     const convertedStartDate = getCurrentDateStringInDesiredTimezone({
//       date: new Date(rawStartDate),
//       timezone
//     });
//     const convertedEndDate = getCurrentDateStringInDesiredTimezone({
//       date: new Date(rawEndDate),
//       timezone
//     });

//     // const isSameDay = sameDay(convertedStartDate, convertedEndDate);
//     // const startDate = isSameDay ?
//     //   getCurrentDateStringInDesiredTimezone({
//     //     date: new Date((new Date(rawStartDate)).setHours(0, 0, 0, 0)),
//     //     timezone
//     //   }) :
//     //   convertedStartDate;
//     // const endDate = isSameDay ?
//     //   getCurrentDateStringInDesiredTimezone({
//     //     date: new Date((new Date(rawEndDate)).setHours(23, 59, 59, 999)),
//     //     timezone
//     //   }) : convertedEndDate;
//     const transition_list = [TRANSITION_REQUEST, TRANSITION_REQUEST_AFTER_ENQUIRY, TRANSITION_REQUEST_CUSTOM_PRICING,
//       TRANSITION_REQUEST_NEW_TRAINER, TRANSITION_REQUEST_NEW_TRAINER_CUSTOM_PRICING,
//       TRANSITION_ACCEPT, TRANSITION_BOOKING_LESS_48_HOURS, TRANSITION_AUTO_CONFIRM_DELIVERY, TRANSITION_COMPLETE,
//       TRANSITION_AUTO_COMPLETE];

//     const onlyFilterValues = {
//       orders: 'order',
//       sales: 'sale',
//     };

//     const onlyFilter = onlyFilterValues[tab];
//     if (!onlyFilter) {
//       return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
//     }

//     isMonth ? dispatch(fetchMonthSessionsRequest()) : dispatch(fetchSessionsRequest());

//     const startDateStamp = startDate ? (new Date(startDate)).getTime() : (new Date((new Date()).getTime() - 31 * 24 * 60 * 60 * 1000)).getTime();
//     const endDateStamp = endDate ? (new Date(endDate)).getTime() : (new Date()).getTime();

//     // const { page = 1 } = parse(search);
//     const page = 1;

//     const apiQueryParams = {
//       only: onlyFilter,
//       include: ['provider', 'provider.profileImage', 'customer', 'customer.profileImage', 'booking', 'listing'],
//       'fields.image': ['variants.square-small', 'variants.square-small2x'],
//       page,
//       per_page: BIG_TRANSACTION_PER_PAGE,
//       lastTransitions: transition_list
//     };

//     return sdk.transactions
//       .query(apiQueryParams)
//       .then(response => {
//         const totalPages = response.data.meta.totalPages;
//         if (totalPages <= 1) {
//           const listingInTimeRangeData = response.data.data.filter((listing) => {
//             let timeTransitionStamp = (new Date(listing.attributes.protectedData.storedBookingDates.storedEndDateTimestamp)).getTime();
//             return timeTransitionStamp < endDateStamp && timeTransitionStamp > startDateStamp;
//           })
//           const newResponse = {
//             ...response, data: {
//               ...response.data,
//               data: listingInTimeRangeData
//             }
//           };
//           dispatch(addMarketplaceEntities(newResponse));
//           isMonth ? dispatch(fetchMonthSessionsSuccess(newResponse)) : dispatch(fetchSessionsSuccess(newResponse));
//           return newResponse;
//         } else {
//           const newPerPage = response.data.meta.totalItems;
//           const allTransactionQuery = {
//             only: onlyFilter,
//             include: ['provider', 'provider.profileImage', 'customer', 'customer.profileImage', 'booking'],
//             'fields.image': ['variants.square-small', 'variants.square-small2x'],
//             page,
//             per_page: newPerPage,
//             lastTransitions: transition_list
//           };
//           return sdk.transactions
//             .query(allTransactionQuery)
//             .then(response => {
//               const listingInTimeRangeData = response.data.data.filter((listing) => {
//                 let timeTransitionStamp = (new Date(listing.attributes.lastTransitionedAt)).getTime();
//                 return timeTransitionStamp < endDateStamp && timeTransitionStamp > startDateStamp;
//               })
//               const newResponse = {
//                 ...response, data: {
//                   ...response.data,
//                   data: listingInTimeRangeData
//                 }
//               };
//               dispatch(addMarketplaceEntities(newResponse));
//               isMonth ? dispatch(fetchMonthSessionsSuccess(newResponse)) : dispatch(fetchSessionsSuccess(newResponse));
//               return newResponse;
//             });
//         }
//       })
//       .catch(e => {
//         isMonth ? dispatch(fetchMonthSessionsError(storableError(e))) : dispatch(fetchSessionsError(storableError(e)));
//         throw e;
//       });

//   }
