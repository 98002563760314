import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Form, LocationAutocompleteInput, SearchDropDown, NamedLink } from '../../components';
import config from '../../config';

import css from './TopbarSearchForm.css';

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.searchInput = null;
  }

  onChange(location) {
    if (location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      this.props.onSubmit({ location });
      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  }

  render() {
    const { isMobile, adminData } = this.props;

    const resortsAreUsed =
      adminData && adminData.attributes.profile.publicData.resortsAreUsed
        ? adminData.attributes.profile.publicData.resortsAreUsed
        : null;
    const defaultLocation = config.defaultLocationSearches;
    const filterDefaultLocation = resortsAreUsed
      ? defaultLocation.filter(item => {
          return Object.keys(resortsAreUsed).includes(item.id);
        })
      : [];

    filterDefaultLocation.sort((a, b) => {
      if (filterDefaultLocation && filterDefaultLocation.length > 0) {
        return a.id.toLocaleUpperCase() > b.id.toLocaleUpperCase()
          ? 1
          : b.id.toLocaleUpperCase() > a.id.toLocaleUpperCase()
          ? -1
          : 0;
      }
    });

    if (!isMobile) {
      return (
        <FinalForm
          {...this.props}
          render={formRenderProps => {
            const {
              rootClassName,
              className,
              desktopInputRoot,
              intl,
              isMobile,
              onGetAdminProfile,
            } = formRenderProps;

            const classes = classNames(rootClassName, className);
            const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

            // Allow form submit only when the place has changed
            const preventFormSubmit = e => e.preventDefault();

            return (
              <Form className={classes} onSubmit={preventFormSubmit}>
                <Field
                  name="location"
                  format={null}
                  render={({ input, meta }) => {
                    const { onChange, ...restInput } = input;

                    // Merge the standard onChange function with custom behaviur. A better solution would
                    // be to use the FormSpy component from Final Form and pass this.onChange to the
                    // onChange prop but that breaks due to insufficient subscription handling.
                    // See: https://github.com/final-form/react-final-form/issues/159
                    const searchOnChange = value => {
                      onChange(value.selectedPlace.address);
                      this.onChange(value);
                    };

                    const searchInput = {
                      ...restInput,
                      onChange: searchOnChange,
                      onGetAdminProfile,
                    };
                    return (
                      <SearchDropDown
                        name="search"
                        className={desktopInputRootClass}
                        inputClassName={css.desktopInput}
                        iconClassName={css.desktopIcon}
                        predictionsClassName={css.desktopPredictions}
                        predictionsAttributionClassName={css.mobilePredictionsAttribution}
                        onChange={searchOnChange}
                        input={searchInput}
                        placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
                        defaultLocation={filterDefaultLocation}
                      />
                    );
                  }}
                />
              </Form>
            );
          }}
        />
      );
    } else {
      return (
        <div className={css.links}>
          <div className={css.title}>
            <FormattedMessage id="TopbarSearchForm.placeholder" />
          </div>
          {filterDefaultLocation.map(item => {
            return (
              <NamedLink
                key={item.id}
                name="SearchPage"
                className={css.link}
                to={{
                  search: item.search,
                }}
              >
                {item.predictionPlace.address}
              </NamedLink>
            );
          })}
        </div>
      );
    }
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
