import React from 'react';
import PropTypes, { object } from 'prop-types';
import classNames from 'classnames';
import css from './FormattedMessageHTML.css';
const FormattedMessageHTML = ({
                                rootClassName,
                                className,
                                intl,
                                id,
                                values,
                              }) => {
  const classes = classNames(rootClassName || css.root, className);
  const innerHtml = intl.formatMessage({ id: id }, {...values});
  return (
    <span
      className={classes}
      dangerouslySetInnerHTML={{__html: innerHtml}}></span>
  )
};
FormattedMessageHTML.defaultProps = {
  className: null,
};
FormattedMessageHTML.propTypes = {
  className: PropTypes.string,
  intl: object.isRequired,
  id: PropTypes.string.isRequired,
  values: PropTypes.object
};
export default FormattedMessageHTML;
