import { sendContactEmail } from '../../util/ses';

const SEND_MESSAGE_REQUEST = 'app/ContactUsPage/SEND_MESSAGE_REQUEST';
const SEND_MESSAGE_SUCCESS = 'app/ContactUsPage/SEND_MESSAGE_SUCCESS';
const SEND_MESSAGE_ERROR = 'app/ContactUsPage/SEND_MESSAGE_ERROR';
const RESET_EMAIL_SEND = 'app/ContactUsPage/RESET_EMAIL_SEND';

const initialState = {
  sendEmailInProgress: false,
  sendEmailSuccess: false,
  sendEmailError: null,
};

export default (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SEND_MESSAGE_REQUEST:
      return { ...state, sendEmailError: null, sendEmailInProgress: true, sendEmailSuccess: false };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendEmailInProgress: false, sendEmailSuccess: true };
    case SEND_MESSAGE_ERROR:
      return { ...state, sendEmailInProgress: false, sendEmailError: payload };
    case RESET_EMAIL_SEND:
      return {
        ...state,
        sendEmailInProgress: false,
        sendEmailError: null,
        sendEmailSuccess: false,
      };
    default:
      return initialState;
  }
};

const sendEmailRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendEmailSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendEmailError = error => ({ type: SEND_MESSAGE_ERROR, payload: error });
export const resetEmailSend = () => ({ type: RESET_EMAIL_SEND });

export const sendEmail = ({ email, firstName, lastName, message, phone, subject }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(sendEmailRequest());
  sendContactEmail({ email, firstName, lastName, message, phone, subject })
    .then(response => {
      dispatch(sendEmailSuccess());
    })
    .catch(error => {
      dispatch(sendEmailError(error));
    });
};
