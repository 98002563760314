/* eslint-disable no-case-declarations */
import React from 'react';
import { string, arrayOf, bool, func, number } from 'prop-types';
import { injectIntl, intlShape, FormattedMessage, FormattedDate } from 'react-intl';
import dropWhile from 'lodash/dropWhile';
import classNames from 'classnames';
import {
  Avatar,
  ExternalLink,
  InlineTextButton,
  ReviewRating,
  UserDisplayName,
} from '../../components';
import { formatDate, getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { ensureTransaction, ensureUser, ensureListing } from '../../util/data';
import {
  TRANSITION_CANCEL,
  TRANSITION_CUSTOMER_CANCEL_AFTER_REMINDED,
  TRANSITION_CANCEL_AFTER_BOOKING_REMINDED,
  TRANSITION_CANCEL_AFTER_MARKED_DATE,
  TRANSITION_CANCEL_BEFORE_MARKED_DATE,
  TRANSITION_CANCEL_NO_REFUND,
  TRANSITION_CUSTOMER_CANCEL,
  TRANSITION_COMPLETE,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_REQUEST,
  TRANSITION_REQUEST_AFTER_ENQUIRY,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  transitionIsReviewed,
  txIsDelivered,
  txIsInFirstReviewBy,
  txIsReviewed,
  isCustomerReview,
  isProviderReview,
  txRoleIsProvider,
  txRoleIsCustomer,
  getUserTxRole,
  isRelevantPastTransition,
  txIsCanceled,
  txRoleIsSystem,
  TRANSITION_MARK_BOOKING_NEED_REMINDER,
  TRANSITION_SEND_ITINERARY,
  txHasBeenDelivered,
  TRANSITION_DID_NOT_ANSWER_CALL,
  TRANSITION_DID_NOT_RECEIVE_CALL,
  txIsBookingRemined,
  txHasBeenSendItinerary,
} from '../../util/transaction';
import { propTypes, DEFAULT_TIME_ZONE } from '../../util/types';
import * as log from '../../util/log';
import moment from 'moment';
import css from './ActivityFeed.css';
import NamedLink from '../NamedLink/NamedLink';
import config from '../../config';
import pdfIcon from '../../assets/pdfIcon.png';

const Message = props => {
  const { message, intl } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.message}>
      <Avatar className={css.avatar} user={message.sender} />
      <div>
        <p className={css.messageContent}>{message.attributes.content}</p>
        <p className={css.messageDate}>
          {formatDate(intl, todayString, message.attributes.createdAt)}
        </p>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const OwnMessage = props => {
  const { message, intl } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.ownMessage}>
      <div className={css.ownMessageContentWrapper}>
        <p className={css.ownMessageContent}>{message.attributes.content}</p>
      </div>
      <p className={css.ownMessageDate}>
        {formatDate(intl, todayString, message.attributes.createdAt)}
      </p>
    </div>
  );
};

OwnMessage.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const Review = props => {
  const { content, rating } = props;
  return (
    <div>
      <p className={css.reviewContent}>{content}</p>
      <ReviewRating
        reviewStarClassName={css.reviewStar}
        className={css.reviewStars}
        rating={rating}
      />
    </div>
  );
};

Review.propTypes = {
  content: string.isRequired,
  rating: number.isRequired,
};

const hasUserLeftAReviewFirst = (userRole, transaction) => {
  // Because function txIsInFirstReviewBy uses isCustomer to check in which state the reviews are
  // we should also use isCustomer insted of isProvider
  const isCustomer = txRoleIsCustomer(userRole);
  return txIsInFirstReviewBy(transaction, isCustomer);
};

const formatBookingStartTime = (booking = {}) => {
  const { start } = booking.attributes || {};
  const dateFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',

    timeZone: getDefaultTimeZoneOnBrowser() || DEFAULT_TIME_ZONE,
  };
  const timeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    timeZone: getDefaultTimeZoneOnBrowser() || DEFAULT_TIME_ZONE,
  };
  if (start) {
    return {
      date: <FormattedDate value={start} {...dateFormatOptions} />,
      time: <FormattedDate value={start} {...timeFormatOptions} />,
    };
  }
  return null;
};

const showItineryLink = ({ isOwnTransition, params, isSent }) => {
  return isOwnTransition && !isSent ? (
    <div>
      <NamedLink className={css.link} name="SendItineraryPage" params={{ ...params }}>
        <FormattedMessage id="ActivityFeed.gotoItineraryPage" />
      </NamedLink>
      <br />
    </div>
  ) : (
    <div>
      <NamedLink className={css.link} name="ItineraryPage" params={{ ...params }}>
        <FormattedMessage id="ActivityFeed.gotoItineraryPage" />
      </NamedLink>
      <br />
    </div>
  );
};

const makeItineraryLink = ({ transaction, isOwnTransition, isSent }) => {
  if (!transaction) return null;
  const { id, provider } = transaction;
  const { displayName } = provider.attributes.profile;

  return showItineryLink({
    isOwnTransition,
    params: { id: id.uuid, name: displayName.replace(' ', '') },
    isSent,
  });
};

const makeCancelLink = ({ transaction, onCancelOrder }) => {
  if (
    !transaction ||
    !transaction.booking ||
    txHasBeenSendItinerary(transaction) ||
    txIsCanceled(transaction)
  ) {
    return null;
  }
  // const isShowCancel = hours >= 24;
  const cancelLink = (
    <a onClick={onCancelOrder}>
      <FormattedMessage id="ActivityFeed.clickHere" />
    </a>
  );
  return (
    <div>
      <FormattedMessage id="ActivityFeed.cancelText" values={{ cancelLink }} />
    </div>
  );
};

const makeDidNotAnswerLink = ({ transaction, onTransit }) => {
  if (!transaction || !transaction.booking || !txIsBookingRemined(transaction)) {
    return null;
  }
  const transitLink = (
    <a onClick={() => onTransit(TRANSITION_DID_NOT_ANSWER_CALL)}>
      <FormattedMessage id="ActivityFeed.clickHere" />
    </a>
  );
  return <FormattedMessage id="ActivityFeed.didNotAnswerText" values={{ transitLink }} />;
};

const makeDidNotCallLink = ({ transaction, onTransit }) => {
  if (!transaction || !transaction.booking || !txIsBookingRemined(transaction)) {
    return null;
  }

  const { id, provider } = transaction;
  const { displayName } = provider.attributes.profile;

  const transitLink = (
    <a onClick={() => onTransit(TRANSITION_DID_NOT_RECEIVE_CALL)}>
      <FormattedMessage id="ActivityFeed.clickHere" />
    </a>
  );
  return (
    <FormattedMessage
      id="ActivityFeed.didNotCallText"
      values={{ transitLink, providerName: displayName }}
    />
  );
};

const resolveTransitionMessage = (
  transaction,
  transition,
  listingTitle,
  ownRole,
  otherUsersName,
  intl,
  onOpenReviewModal,
  onCancelOrder,
  onTransit
) => {
  const isOwnTransition = transition.by === ownRole;
  const isProvider = txRoleIsProvider(ownRole);
  const currentTransition = transition.transition;
  const displayName = otherUsersName;
  const bookingTime = formatBookingStartTime(transaction.booking);
  const { protectedData = {} } = transaction.attributes;
  const { customerPhoneNumber } = protectedData;
  switch (currentTransition) {
    case TRANSITION_REQUEST:
    case TRANSITION_REQUEST_AFTER_ENQUIRY:
      return isOwnTransition ? (
        <span>
          <span>
            <FormattedMessage
              id="ActivityFeed.transitionRequest.yourBooking"
              values={{ displayName }}
            />
          </span>
          {bookingTime ? (
            <span className={css.boldText}>
              <br />
              <FormattedMessage
                id="ActivityFeed.transitionRequest.bookingTime"
                values={{ ...bookingTime }}
              />
            </span>
          ) : null}
          {customerPhoneNumber ? (
            <span>
              <br />
              <FormattedMessage
                id="ActivityFeed.transitionRequest.phoneNumberForCustomer"
                values={{
                  customerPhoneNumber: <b>{customerPhoneNumber}</b>,
                  displayName,
                  newLine: <br />,
                }}
              />
            </span>
          ) : null}
          {makeCancelLink({ transaction, onCancelOrder })}
        </span>
      ) : (
        <span>
          <FormattedMessage
            id="ActivityFeed.transitionRequest.yourBooking"
            values={{ displayName }}
          />
          {bookingTime ? (
            <span className={css.boldText}>
              <br />
              <FormattedMessage
                id="ActivityFeed.transitionRequest.bookingTime"
                values={{ ...bookingTime }}
              />
            </span>
          ) : null}
          {customerPhoneNumber ? (
            <span>
              <br />
              <FormattedMessage
                id="ActivityFeed.transitionRequest.phoneNumberForProvider"
                values={{
                  customerPhoneNumber: <b>{customerPhoneNumber}</b>,
                  displayName,
                  newline: <br />,
                }}
              />
            </span>
          ) : null}
        </span>
      );
    case TRANSITION_DECLINE:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionDecline" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionDecline" values={{ displayName }} />
      );
    case TRANSITION_EXPIRE:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage id="ActivityFeed.ownTransitionExpire" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionExpire" values={{ displayName }} />
      );
    case TRANSITION_CANCEL:
    case TRANSITION_CUSTOMER_CANCEL:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage id="ActivityFeed.transitionCancelBy" values={{ displayName }} />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionCancelRefund" values={{ displayName }} />
      );
    case TRANSITION_CUSTOMER_CANCEL_AFTER_REMINDED:
    case TRANSITION_CANCEL_NO_REFUND:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage
          id="ActivityFeed.transitionCancelNoRefundForProvider"
          values={{ displayName }}
        />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionCancelNoRefundForCustomer"
          values={{ displayName }}
        />
      );
    case TRANSITION_CANCEL_BEFORE_MARKED_DATE:
    case TRANSITION_CANCEL_AFTER_MARKED_DATE:
    case TRANSITION_CANCEL_AFTER_BOOKING_REMINDED:
      return <FormattedMessage id="ActivityFeed.transitionCancel" />;
    // case TRANSITION_COMPLETE:
    //   // Show the leave a review link if the state is delivered and if the current user is the first to leave a review
    //   const reviewPeriodJustStarted = txIsDelivered(transaction);
    //
    //   const reviewAsFirstLink = reviewPeriodJustStarted ? (
    //     <InlineTextButton onClick={onOpenReviewModal}>
    //       <FormattedMessage id="ActivityFeed.leaveAReview" values={{ displayName }} />
    //     </InlineTextButton>
    //   ) : null;
    //
    //   return (
    //     <FormattedMessage
    //       id="ActivityFeed.transitionComplete"
    //       values={{ reviewLink: reviewAsFirstLink }}
    //     />
    //   );
    case 'transition/operator-mark-booking-need-reminder':
    case TRANSITION_MARK_BOOKING_NEED_REMINDER:
      return isProvider ? (
        <React.Fragment>
          <FormattedMessage
            id="ActivityFeed.provider.waitingForTheCall"
            values={{ customerName: displayName }}
          />
          <br />
          <FormattedMessage id="ActivityFeed.provider.openItinerary" />
          {makeItineraryLink({
            transaction,
            isOwnTransition: true,
            isSent: txHasBeenDelivered(transaction),
          })}
          {makeDidNotAnswerLink({ transaction, onTransit })}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <FormattedMessage id="ActivityFeed.waitingForTheCall" values={{ displayName }} />
          {makeDidNotCallLink({ transaction, onTransit })}
        </React.Fragment>
      );
    case TRANSITION_SEND_ITINERARY: {
      const downloadLink = `${config.apiUrl}/itinerary/${transaction.id.uuid}/download`;

      return !isOwnTransition ? (
        <React.Fragment>
          <FormattedMessage id="ActivityFeed.openItinerary" />
          {makeItineraryLink({ transaction, isOwnTransition: false })}
          <p>
            We{' '}
            <b>
              <u>highly recommend</u>
            </b>{' '}
            downloading your .pdf itinerary locally to your phone so you can view it on the mountain
            even without cell service. Click{' '}
            <ExternalLink href={`${config.canonicalRootURL}/faq#question10`}>here</ExternalLink> to
            view our FAQ about how to do this via Apple’s “Files” (or iCloud) and Dropbox.
          </p>{' '}
          <p>
            Similar steps can be taken to download your itinerary locally to Android devices via
            other cloud services like Google Drive or OneDrive.
          </p>{' '}
          <div>
            We also recommend printing a hard copy of your itinerary as a backup, just in case.
          </div>
          <ExternalLink href={downloadLink}>
            <img src={pdfIcon} width={310} height={180} />
          </ExternalLink>
        </React.Fragment>
      ) : (
        <FormattedMessage id={'ActivityFeed.sentItineraryDetails'} />
      );
    }
    case TRANSITION_COMPLETE:
    case 'transition/complete-operator': {
      const reviewPeriodJustStarted = txIsDelivered(transaction);
      const reviewAsFirstLink =
        reviewPeriodJustStarted && txRoleIsCustomer(ownRole) ? (
          <InlineTextButton onClick={onOpenReviewModal}>
            <FormattedMessage id="ActivityFeed.leaveAReview" values={{ displayName }} />
          </InlineTextButton>
        ) : null;
      //   const reviewAsFirstLink = reviewPeriodJustStarted ? (
      //   <InlineTextButton onClick={onOpenReviewModal}>
      //     <FormattedMessage id="ActivityFeed.leaveAReview" values={{ displayName }} />
      //   </InlineTextButton>
      // ) : null;

      return (
        <FormattedMessage
          id="ActivityFeed.transitionComplete"
          values={{ reviewLink: reviewAsFirstLink }}
        />
      );
    }

    case TRANSITION_REVIEW_1_BY_PROVIDER:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />;
      } else {
        // show the leave a review link if current user is not the first
        // one to leave a review
        const reviewPeriodIsOver = txIsReviewed(transaction);
        const userHasLeftAReview = hasUserLeftAReviewFirst(ownRole, transaction);
        const reviewAsSecondLink = !(reviewPeriodIsOver || userHasLeftAReview) ? (
          <InlineTextButton onClick={onOpenReviewModal}>
            <FormattedMessage id="ActivityFeed.leaveAReviewSecond" values={{ displayName }} />
          </InlineTextButton>
        ) : null;
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: reviewAsSecondLink }}
          />
        );
      }
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_REVIEW_2_BY_CUSTOMER:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />;
      } else {
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: null }}
          />
        );
      }
    case TRANSITION_DID_NOT_ANSWER_CALL:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.customerDidNotAnswer" values={{ displayName }} />;
      } else {
        return <FormattedMessage id="ActivityFeed.youDidNotAnswer" values={{ displayName }} />;
      }
    case TRANSITION_DID_NOT_RECEIVE_CALL:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.providerDidNotCall" values={{ displayName }} />;
      } else {
        return <FormattedMessage id="ActivityFeed.youDidNotCall" values={{ displayName }} />;
      }

    default:
      log.error(new Error('Unknown transaction transition type'), 'unknown-transition-type', {
        transitionType: currentTransition,
      });
      return '';
  }
};

const reviewByAuthorId = (transaction, userId) => {
  return transaction.reviews.filter(r => r.author.id.uuid === userId.uuid)[0];
};

const Transition = props => {
  const {
    transition,
    transaction,
    currentUser,
    intl,
    onOpenReviewModal,
    onCancelOrder,
    onTransit,
  } = props;

  const currentTransaction = ensureTransaction(transaction);
  const customer = currentTransaction.customer;
  const provider = currentTransaction.provider;

  const deletedListing = intl.formatMessage({
    id: 'ActivityFeed.deletedListing',
  });
  const listingTitle = currentTransaction.listing.attributes.deleted
    ? deletedListing
    : currentTransaction.listing.attributes.title;
  const lastTransition = currentTransaction.attributes.lastTransition;

  const ownRole = getUserTxRole(currentUser.id, currentTransaction);

  const otherUsersName = txRoleIsProvider(ownRole) ? (
    <UserDisplayName user={customer} intl={intl} />
  ) : (
    <UserDisplayName user={provider} intl={intl} />
  );

  const transitionMessage = resolveTransitionMessage(
    transaction,
    transition,
    listingTitle,
    ownRole,
    otherUsersName,
    intl,
    onOpenReviewModal,
    onCancelOrder,
    onTransit
  );
  const currentTransition = transition.transition;

  let reviewComponent = null;

  if (transitionIsReviewed(lastTransition)) {
    if (isCustomerReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, customer.id);
      reviewComponent = (
        <Review content={review.attributes.content} rating={review.attributes.rating} />
      );
    } else if (isProviderReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, provider.id);
      reviewComponent = (
        <Review content={review.attributes.content} rating={review.attributes.rating} />
      );
    }
  }

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent}>{transitionMessage}</p>
        <p className={css.transitionDate}>{formatDate(intl, todayString, transition.createdAt)}</p>
        {reviewComponent}
      </div>
    </div>
  );
};

Transition.propTypes = {
  transition: propTypes.transition.isRequired,
  transaction: propTypes.transaction.isRequired,
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
  onOpenReviewModal: func.isRequired,
};

const EmptyTransition = () => {
  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent} />
        <p className={css.transitionDate} />
      </div>
    </div>
  );
};

const isMessage = item => item && item.type === 'message';

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item => (isMessage(item) ? item.attributes.createdAt : item.createdAt);
  return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, transitions, hideOldTransitions) => {
  const items = messages.concat(transitions).sort(compareItems);
  if (hideOldTransitions) {
    // Hide transitions that happened before the oldest message. Since
    // we have older items (messages) that we are not showing, seeing
    // old transitions would be confusing.
    return dropWhile(items, i => !isMessage(i));
  } else {
    return items;
  }
};

export const ActivityFeedComponent = props => {
  const {
    rootClassName,
    className,
    messages,
    transaction,
    currentUser,
    hasOlderMessages,
    onOpenReviewModal,
    onShowOlderMessages,
    fetchMessagesInProgress,
    intl,
    onCancelOrder,
    onTransit,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentTransaction = ensureTransaction(transaction);
  const transitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);
  const currentListing = ensureListing(currentTransaction.listing);

  const transitionsAvailable = !!(
    currentUser &&
    currentUser.id &&
    currentCustomer.id &&
    currentProvider.id &&
    currentListing.id
  );

  // combine messages and transaction transitions
  const items = organizedItems(messages, transitions, hasOlderMessages || fetchMessagesInProgress);

  const transitionComponent = transition => {
    if (transitionsAvailable) {
      return (
        <Transition
          transition={transition}
          transaction={transaction}
          currentUser={currentUser}
          intl={intl}
          onOpenReviewModal={onOpenReviewModal}
          onCancelOrder={onCancelOrder}
          onTransit={onTransit}
        />
      );
    } else {
      return <EmptyTransition />;
    }
  };

  const messageComponent = message => {
    const isOwnMessage =
      message.sender &&
      message.sender.id &&
      currentUser &&
      currentUser.id &&
      message.sender.id.uuid === currentUser.id.uuid;
    if (isOwnMessage) {
      return <OwnMessage message={message} intl={intl} />;
    }
    return <Message message={message} intl={intl} />;
  };

  const messageListItem = message => {
    return (
      <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={css.messageItem}>
        {messageComponent(message)}
      </li>
    );
  };

  const transitionListItem = transition => {
    if (isRelevantPastTransition(transition.transition)) {
      return (
        <li key={transition.transition} className={css.transitionItem}>
          {transitionComponent(transition)}
        </li>
      );
    } else {
      return null;
    }
  };
  return (
    <ul className={classes}>
      {hasOlderMessages ? (
        <li className={css.showOlderWrapper} key="show-older-messages">
          <InlineTextButton className={css.showOlderButton} onClick={onShowOlderMessages}>
            <FormattedMessage id="ActivityFeed.showOlderMessages" />
          </InlineTextButton>
        </li>
      ) : null}
      {items.map(item => {
        if (isMessage(item)) {
          return messageListItem(item);
        } else {
          return transitionListItem(item);
        }
      })}
    </ul>
  );
};

ActivityFeedComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

ActivityFeedComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction,
  messages: arrayOf(propTypes.message),
  hasOlderMessages: bool.isRequired,
  onOpenReviewModal: func.isRequired,
  onShowOlderMessages: func.isRequired,
  fetchMessagesInProgress: bool.isRequired,
  onCancelOrder: func.isRequired,
  onTransit: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ActivityFeed = injectIntl(ActivityFeedComponent);

export default ActivityFeed;
